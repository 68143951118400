import React,{ useState, useEffect } from "react";
import { Tab, Nav } from "react-bootstrap";
import { Link } from "react-router-dom";
// import TabImgone from "../assets/img/habbits-img-1.png";
// import TabImgtwo from "../assets/img/to-do-list.png";
// import TabImgthree from "../assets/img/soundcapes.png";
import Airtable from "airtable";



const TabSection = (props) => {
    const [tabs,set_tabs] = useState([]);
    let tabs_index = 0;

    async function get_tabs()
    {
        var base = new Airtable({apiKey: props.apiKey}).base(props.baseKey);
        let all_slider_images = [];
        await base('Tab Section').select({
            // Selecting the first 3 records in Grid view:
            view: "Grid view"
        }).all().then(records => {
            if(records.length > 0)
            {
                set_tabs(records);
            }

        }).catch(err => {
            console.error(err);
        });
    }

    useEffect(() => {
        if(props.apiKey !== undefined && props.baseKey !== undefined)
        {
            let tabs = get_tabs();
        }
    },[props]);

    return (
        <>
            {
                tabs !== undefined && tabs.length > 0
                ?
                    <div className="tab_section_wrap">
                        <Tab.Container id="left-tabs-example" defaultActiveKey={tabs[0].id}>
                            <div className="tab_sec_left">
                                <div className="tab_sec_left_content">
                                <div className="d-none d-lg-block">
                                    {
                                        tabs[0].fields.SubTitle !== undefined
                                        ?
                                        <div className="tab_tagline">{tabs[0].fields.SubTitle}</div>
                                        : null
                                    }

                                    <Tab.Content>
                                        {
                                            tabs.map((tabs_value) => {
                                                tabs_index = tabs_index + 1;
                                                return <Tab.Pane eventKey={tabs_value.id} key={`first_`+tabs_index}>
                                                            <h2>{tabs_value.fields.Title}</h2>
                                                            <p dangerouslySetInnerHTML={{__html: tabs_value.fields.Description}}></p>
                                                        </Tab.Pane>
                                            })
                                        }


                                    </Tab.Content>
                                </div>
                                <Nav className="flex-column tab-list">
                                    {
                                        tabs.map((tabs_value) => {
                                            tabs_index = tabs_index + 1;
                                            return <Nav.Item key={tabs_index}>
                                                        <Nav.Link eventKey={tabs_value.id} key={`second_`+tabs_index}>
                                                        {tabs_value.fields.ButtonTitle !== undefined ? <h4>{tabs_value.fields.ButtonTitle}</h4> : null }
                                                        {tabs_value.fields.ButtonText !== undefined ?  <p dangerouslySetInnerHTML={{__html:tabs_value.fields.ButtonText}}></p> : null }
                                                        </Nav.Link>
                                                    </Nav.Item>
                                        })
                                    }

                                </Nav>
                                </div>
                            </div>

                            <div className="tab_sec_right">
                                <div className="d-lg-none">
                                    {
                                        tabs[0].fields.SubTitle !== undefined
                                        ?
                                        <div className="tab_tagline">{tabs[0].fields.SubTitle}</div>
                                        : null
                                    }
                                </div>
                                <Tab.Content>
                                    {
                                        tabs.map((tabs_value) => {
                                            tabs_index = tabs_index + 1;
                                            let tab_image = tabs_value.fields.Image !== undefined && tabs_value.fields.Image.length > 0 ? tabs_value.fields.Image[0]['url'] : '';
                                            return  <Tab.Pane eventKey={tabs_value.id} key={`second_`+tabs_index}>
                                                        <div className="d-lg-none">
                                                            <h2>{tabs_value.fields.Title}</h2>
                                                            <p dangerouslySetInnerHTML={{__html: tabs_value.fields.Description}}></p>
                                                        </div>
                                                        <img src={tab_image} alt="habbits" />
                                                    </Tab.Pane>
                                        })
                                    }

                                </Tab.Content>
                            </div>
                        </Tab.Container>
                    </div>
                : null
            }

        </>
    )
}

export default TabSection;