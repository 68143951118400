import { React, useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { Col, Row, Button } from "react-bootstrap";
// import Logo from "../assets/img/logo.png";
import ShareImg from "../assets/img/share-icon.png";
// import CloseImg from "../assets/img/close.png";
// import FBIcon from "../assets/img/fb-ic.png";
// import MsgIcon from "../assets/img/fb_msg-ic.png";
// import TwitterIcon from "../assets/img/twitter-ic.png";
// import WhatsappIcon from "../assets/img/whatsapp-ic.png";
// import EmailIcon from "../assets/img/email-icon-sm.png";
// import LinkIcon from "../assets/img/link-ic.png";
import Airtable from "airtable";
import ShareModal from './ShareModal';
import ShareWithFundModal from './ShareWithFundModal';
import { useLocation } from 'react-router-dom'

const Footer = (props) => {
    const [showA, setShowA] = useState(false);
    const toggleShowA = () => setShowA(!showA);

    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const [copyright_text, set_copyright_text] = useState('');
    const [footer_links, set_footer_links] = useState([]);
    const [show_share_modal,set_show_share_modal] = useState(false);
    const [open_share_with_fund_popup,set_open_share_with_fund_popup] = useState(false);
    const set_open_share_with_fund_popup_delay = 100;
    const [share_with_fund_popup_data,set_share_with_fund_popup_data] = useState(false);
    
    let footer_key = 0;

    const location = useLocation();


    async function get_footer_data()
    {
        var base = new Airtable({apiKey: props.apiKey}).base(props.baseKey);
        let all_slider_images = [];
        await base('Footer').select({
            // Selecting the first 3 records in Grid view:
            view: "Grid view",
            filterByFormula:`(LOWER({Name}) = 'link')`
        }).all().then(records => {
            set_footer_links(records);
        }).catch(err => {
        });
    }

    async function get_fcopyright_text()
    {
        var base = new Airtable({apiKey: props.apiKey}).base(props.baseKey);
        let all_slider_images = [];
        await base('Footer').select({
            // Selecting the first 3 records in Grid view:
            view: "Grid view",
            filterByFormula:`(LOWER({Name}) = 'copyright_text')`
        }).all().then(records => {
            if(records.length > 0)
            {
                set_copyright_text(records[0].fields.Description);
            }
        }).catch(err => {
        });
        set_open_share_with_fund_popup(false);
    }

    useEffect(() => {
        get_footer_data();
        get_fcopyright_text();
    }, []);

    useEffect(() => {
        set_open_share_with_fund_popup(false);
    },[])

    useEffect(() => {
    }, [footer_links])

    
    return (
       
        <>
            {
                
                    <>
                        <footer className="footer">
                        <Row className="justify-content-center align-items-center">
                            <Col md={4}>
                                <div className="copyright_text text-center">
                                    <p>{copyright_text}</p>
                                </div>
                            </Col>
                            <Col md={4}>
                                <div className="footer_btn text-center">
                                    {/* <Button onClick={() => {
                                        set_show_share_modal(true);
                                    }} className="btn btn-warning"><img src={ShareImg} alt="Share" /> <span>Share</span></Button> */}
                                    <Button variant="warning"  onClick={() => {
                                        setTimeout(function(){
                                            set_open_share_with_fund_popup(true);
                                            set_share_with_fund_popup_data({});
                                        },set_open_share_with_fund_popup_delay);

                                    }}><img src={ShareImg} alt="Share" /> <span>Share</span></Button>
                                </div>
                            </Col>
                            {
                                footer_links !== undefined && footer_links.length > 0
                                ?
                                    <Col md={4}>
                                        <ul className="footer_links d-flex justify-content-center list-unstyled">
                                            {
                                                footer_links.map((footer_link) => {
                                                    footer_key = footer_key + 1;
                                                    let footer_link_name = footer_link.fields.Name !== undefined ? footer_link.fields.Name : '';
                                                    let footer_link_url = footer_link.fields.Link_URL !== undefined ? footer_link.fields.Link_URL : '';
                                                    if(footer_link_name == 'Link')
                                                    {
                                                        let footer_link_text = footer_link.fields.Link_text !== undefined ? footer_link.fields.Link_text : '';
                                                        return <li key={footer_key}><Link to={footer_link_url}>{footer_link_text}</Link></li>
                                                    }

                                                })
                                            }
                                        </ul>
                                    </Col>
                                : null
                            }

                        </Row>
                        </footer>
                    </>                
            }
            {
                open_share_with_fund_popup !== undefined && open_share_with_fund_popup === true
                ? 
                    <ShareWithFundModal open_share_with_fund_popup={open_share_with_fund_popup} set_open_share_with_fund_popup={set_open_share_with_fund_popup} share_with_fund_popup_data={share_with_fund_popup_data} />
                :
                    null
            }
            <ShareModal open_share_popup={show_share_modal} set_show_share_modal={set_show_share_modal} share_links={
                {
                    facebook : 'https://www.facebook.com/sharer/sharer.php?u=' + window.location.origin + '&title=Your Feed, fbShareWindow,height=450, width=550, top=' + (window.innerHeight / 2 - 275) + ', left=' + (window.innerWidth / 2 - 225) + ', toolbar=0, location=0, menubar=0, directories=0, scrollbars=0',
                    twitter : `https://twitter.com/intent/tweet?text=${window.location.origin}`,
                    whatsapp : `https://web.whatsapp.com/send?text=${window.location.origin}`,
                    email : `https://mail.google.com/mail/?view=cm&fs=1&tf=1&to=&su=&body=${window.location.origin}&ui=2&tf=1&pli=1`,
                }
            }/>
        </>
    )
}

export default Footer;