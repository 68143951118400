import React,{useEffect,useState, useRef} from "react";
import { NavLink, Link } from "react-router-dom";
import { Button, Tab, Nav } from "react-bootstrap";
import TabSection from "./components/Tab";
// import CTASlider from "./components/CTA";
import Airtable from "airtable";
import PageLoader from "./components/PageLoader";
import Newsletter from "./components/Newsletter";
import {Helmet} from "react-helmet";
import Slider from "react-slick";
import elonn from "./assets/img/elonn.png";
import SlImgone from "./assets/img/sl-img-one.png";
import SlImgtwo from "./assets/img/sl-img-two.png";
import SlImgthree from "./assets/img/sl-img-three.png";
import SlImgfour from "./assets/img/sl-img-four.png";
import SlImgfive from "./assets/img/sl-img-five.png";
import Dollersign from "./assets/img/doller-sign.png"
import StripeImg from "./assets/img/logo-stripe-secure-payments.png";
import BarImg from "./assets/img/bar.png";
import EmailShare from "./assets/img/email-share-ic.png";
import TwitterShare from "./assets/img/twitter-share-ic.png";
import StripePayments from "./assets/img/stripe-secure-payments.png";
import {
    LinkingIcon,
} from "./assets/icons";

const Apps = (props) => {
    const start_account_block = useRef(null);
    const after_submit_email_process_block = useRef(null);
    const [progress,set_progress] = useState(50);
    const [loading,set_loading] = useState(false);
    const Router = require("react-router");

    var settings = {
        infinite: true,
        slidesToShow: 9,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 100,
        cssEase: 'linear',
        speed: 12000,
        arrows: false,
        responsive: [
            {
                breakpoint: 1800,
                settings: {
                    slidesToShow: 8,
                }
            },
            {
                breakpoint: 1600,
                settings: {
                    slidesToShow: 7,
                }
            },
            {
                breakpoint: 1200,
                settings: {
                    slidesToShow: 6,
                }
            },
            {
                breakpoint: 992,
                settings: {
                    slidesToShow: 5,
                }
            },
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 4,
                }
            },
            {
                breakpoint: 425,
                settings: {
                    slidesToShow: 2,
                }
            }
        ]

    };
    var settingsTwo = {
        rtl: true,
        infinite: true,
        slidesToShow: 9,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 100,
        cssEase: 'linear',
        speed: 12000,
        arrows: false,
        responsive: [
            {
                breakpoint: 1800,
                settings: {
                    slidesToShow: 8,
                }
            },
            {
                breakpoint: 1600,
                settings: {
                    slidesToShow: 7,
                }
            },
            {
                breakpoint: 1200,
                settings: {
                    slidesToShow: 6,
                }
            },
            {
                breakpoint: 992,
                settings: {
                    slidesToShow: 5,
                }
            },
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 4,
                }
            },
            {
                breakpoint: 425,
                settings: {
                    slidesToShow: 2,
                }
            }
        ]
    }
    // console.log('apps_props',props);

    useEffect(() => {
        set_loading(true);
    },[]);


    useEffect(() => {
        let seo_default_Data = props.get_seo_data_of_page('Default');
        let seo_page_Data = props.get_seo_data_of_page('Apps');
        set_default_page_seo_data(seo_default_Data);
        set_page_seo_title(seo_page_Data);
    }, []);

    async function get_slider_images()
    {
        var base = new Airtable({apiKey: props.apiKey}).base(props.baseKey);
        let all_slider_images = [];
        await base('images').select({
            // Selecting the first 3 records in Grid view:
            view: "Grid view"
        }).eachPage(function page(records, fetchNextPage) {
            // This function (`page`) will get called for each page of records.

            records.forEach(function(record) {
                all_slider_images = [...all_slider_images,record.get('heroImage')];
                return all_slider_images;
            });
            if(all_slider_images.length > 0)
            {
                // console.log('all_slider_images',all_slider_images);
                setSliderImages(all_slider_images);
            }
            fetchNextPage();


        }, function done(err) {
            if (err) { console.error(err); return; }
        });
    }

    async function get_cta_data()
    {
        var base = new Airtable({apiKey: props.apiKey}).base(props.baseKey);
        let all_slider_images = [];
        await base('Apps').select({
            // Selecting the first 3 records in Grid view:
            view: "Grid view",
            filterByFormula:`(LOWER({Name}) = 'cta')`
        }).all().then(records => {
            // console.log('home_cta',records);
            if(records.length > 0)
            {
                set_cta_content(records[0].fields);
            }

        }).catch(err => {
            // console.error(err);
        });
    }

    async function get_form_section()
    {
        var base = new Airtable({apiKey: props.apiKey}).base(props.baseKey);
        let all_slider_images = [];
        await base('Apps').select({
            // Selecting the first 3 records in Grid view:
            view: "Grid view",
            filterByFormula:`(LOWER({Name}) = 'form_section')`
        }).all().then(records => {
            // console.log('home_form_Section',records);
            if(records.length > 0)
            {
                set_form_section(records[0].fields);
            }

        }).catch(err => {
            // console.error(err);
        });
    }

    async function get_form_fields()
    {
        var base = new Airtable({apiKey: props.apiKey}).base(props.baseKey);
        let all_slider_images = [];
        await base('Apps').select({
            // Selecting the first 3 records in Grid view:
            view: "Grid view",
            filterByFormula:`(LOWER({Name}) = 'form')`
        }).all().then(records => {
            // console.log('home_form_fields',records);
            if(records.length > 0)
            {
                set_form_fields(records[0].fields);
            }

        }).catch(err => {
            // console.error(err);
        });
    }

    const [sliderImages,setSliderImages] = useState([]);
    const [cta_content,set_cta_content] = useState([]);
    const [form_section,set_form_section] = useState([]);
    const [form_fields,set_form_fields] = useState([]);
    const [prices,set_prices] = useState([]);
    const [form_button_text,set_form_button_text] = useState('');
    const [page_loaded,set_page_loaded] = useState(false);
    const [page_seo_data,set_page_seo_title] = useState({title:'',desc:''});
    const [default_page_seo_data,set_default_page_seo_data] = useState({title:'YourFeed',desc:'YourFeed'});
    let price_key = 0;

    useEffect(() => {
        // console.log('page_loaded',page_loaded);
    }, [page_loaded])

    useEffect(() => {
        // console.log('page_loaded sliderImages',sliderImages.length);
        // console.log('page_loaded cta_content',Object.keys(cta_content).length);
        // console.log('page_loaded form_section',Object.keys(form_section).length);
        // console.log('page_loaded form_fields',Object.keys(form_fields).length);
        if( sliderImages !== undefined && sliderImages.length > 0
            && cta_content !== undefined && Object.keys(cta_content).length > 0
            && form_section !== undefined && Object.keys(form_section).length > 0
            && form_fields !== undefined && Object.keys(form_fields).length > 0
            && prices !== undefined && prices.length > 0
        )
        {
            set_page_loaded(true);
        }
    }, [sliderImages,cta_content,form_section,form_fields,prices])

    useEffect(() => {
        let slider_images = get_slider_images();
        let cta_data = get_cta_data();
        let form_section = get_form_section();
        let form_fields = get_form_fields();


    },[]);

    useEffect(() => {
        // console.log('apps cta sliders',sliderImages);
    }, [sliderImages]);

    useEffect(() => {
        // console.log('apps_cta_content',cta_content);
    }, [cta_content]);

    useEffect(() => {
        // console.log('form_section',form_section);
    }, [form_section]);

    useEffect(() => {
        // console.log('form_fields_prices',prices);
    }, [prices]);

    useEffect(() => {
        // console.log('form_fields',form_fields);
        let all_prices = prices;
        if(form_fields.PriceOne !== undefined)
        {
            all_prices = [...all_prices,form_fields.PriceOne];
        }
        if(form_fields.PriceTwo !== undefined)
        {
            all_prices = [...all_prices,form_fields.PriceTwo];
        }
        if(form_fields.PriceThree !== undefined)
        {
            all_prices = [...all_prices,form_fields.PriceThree];
        }
        if(form_fields.FormButtonText !== undefined)
        {
            set_form_button_text(form_fields.FormButtonText);
        }

        set_prices(all_prices);
    }, [form_fields]);

    let sliderImagesKey = 0;
    let slider_single_key = 0;

    return (
        <>
        <Helmet>
            <title>{page_seo_data.title != '' ? page_seo_data.title : default_page_seo_data.title}</title>
            <meta name="description" content={page_seo_data.desc != '' ? page_seo_data.desc : default_page_seo_data.desc} />
        </Helmet>
        <PageLoader page_loaded={page_loaded} />
        <div className="apps_page">
            <TabSection apiKey={props.apiKey} baseKey={props.baseKey} />

            {/* <hr size="1" className="custom_hr rtl" /> */}

            <div className="soon_wrap banner_wrap">
                        <div className="bg_slider_wrap">
                            {
                                sliderImages.map((banner_slider_images) => {
                                    // console.log('banner_slider_images',banner_slider_images);
                                    sliderImagesKey = sliderImagesKey + 1;
                                    let new_banner_images = [];
                                    let new_banner_single_images = [];
                                    let is_bg_slider_rtl = sliderImagesKey % 2 == 0 ? 'bg_slider_rtl' : '';
                                    let slider_settings = sliderImagesKey % 2 == 0 ? settingsTwo : settings;
                                    let minimum_images = 10;
                                    if (banner_slider_images.length < minimum_images) {
                                        let total_loops = Math.ceil(minimum_images / banner_slider_images.length)
                                        for (let k = 1; k <= total_loops; k++) {
                                            new_banner_images = [...new_banner_images, banner_slider_images];
                                        }
                                        if (new_banner_images.length > 0) {
                                            new_banner_images.forEach((new_banner_val) => {
                                                if (new_banner_val.length > 0) {
                                                    new_banner_val.forEach((inner_single_img) => {
                                                        new_banner_single_images.push(inner_single_img);
                                                    })
                                                }
                                            })
                                        }
                                    }

                                    return <Slider {...slider_settings} className={`slider${sliderImagesKey} bg_slider_row bg_slider ${is_bg_slider_rtl}`} key={sliderImagesKey}>

                                        {
                                            new_banner_single_images.map((final_single_img) => {

                                                slider_single_key = slider_single_key + 1;
                                                // console.log('final_single_img',final_single_img);
                                                if(final_single_img.thumbnails !== undefined && final_single_img.thumbnails.large !== undefined)
                                                {
                                                    return  final_single_img.url !== undefined ?
                                                        <div className="slide" key={slider_single_key}>
                                                            <img src={final_single_img.thumbnails.large.url} alt={final_single_img.alt} />
                                                        </div>
                                                    : "";
                                                }
                                            })
                                        }



                                    </Slider>
                                })
                            }
                        </div>{/* banner slider end*/}
                        <div className="container">
                            {
                                props !== undefined && props.apiKey !== undefined && props.baseKey !== undefined
                                ?
                                    <Newsletter apiKey={props.apiKey} baseKey={props.baseKey} />
                                :
                                    null
                            }
                        </div>
                        {/*banner content end*/}
                    </div>{/* banner_wrap */}
        </div>
        </>
    )
}

export default Apps;