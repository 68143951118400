import React from "react";

const SVG = ({
  style = {},
  fill = "#FEDF30",
  stroke="none",
  width = "19",
  height = "19",
  className = "",
  viewBox = "0 0 19 19"
}) => (
  <svg
    width={width}
    style={style}
    height={height}
    viewBox={viewBox}
    xmlns="http://www.w3.org/2000/svg"
    className={`svg-icon ${className || ""}`}
    xmlnsXlink="http://www.w3.org/1999/xlink"
  >
    <path fill={fill} stroke={stroke} d="M5.72083 15.9485C4.83838 16.831 3.40765 16.831 2.52519 15.9485C1.64274 15.0661 1.64274 13.6354 2.52529 12.7528L6.60284 8.67847C7.48466 7.79775 8.91318 7.79775 9.78769 8.67139C10.1598 9.02159 10.7402 9.02159 11.1047 8.67873C11.2634 8.51992 11.3596 8.30928 11.3756 8.08816C11.3926 7.82051 11.2939 7.55844 11.1045 7.36864C10.8951 7.15792 10.6632 6.97148 10.412 6.81172C9.74758 6.38627 8.97513 6.16016 8.18547 6.16016C7.09077 6.15813 6.04054 6.59307 5.26749 7.36882L1.20611 11.4334C0.434414 12.2066 0.000691828 13.2541 8.54546e-07 14.3465C-0.00145021 16.626 1.84527 18.4751 4.12577 18.4766C5.21857 18.4804 6.26764 18.0476 7.04011 17.2742L10.3536 13.9607L10.2819 13.9607C9.54206 13.9633 8.80869 13.8222 8.12132 13.5449L5.72083 15.9485Z" />
    <path fill={fill} stroke={stroke} d="M13.1998 11.1111L17.2645 7.04636C18.8766 5.43442 18.8766 2.82093 17.2646 1.20895C15.6527 -0.403004 13.0392 -0.403004 11.4271 1.20907L8.11692 4.51608L8.18594 4.51608C8.9251 4.51473 9.65736 4.65695 10.3428 4.9351L12.7369 2.54421C13.6193 1.66176 15.0501 1.66176 15.9325 2.54421C16.815 3.42667 16.815 4.8574 15.9324 5.73998L12.9512 8.71711L12.9112 8.76117L11.8645 9.80152C10.9826 10.6822 9.55412 10.6822 8.67961 9.80861C8.3075 9.4584 7.72707 9.4584 7.3627 9.80112C7.203 9.96115 7.10671 10.1736 7.09174 10.3982C7.07466 10.6658 7.17336 10.9279 7.36296 11.118C7.70395 11.4604 8.10291 11.7397 8.54459 11.9444C8.5842 11.9626 8.5842 11.9626 8.62969 11.9818C8.64557 11.9884 8.64557 11.9884 8.66215 11.9952C8.69639 12.0093 8.69639 12.0094 8.73345 12.025C8.7707 12.0403 8.7707 12.0403 8.81683 12.0571C8.83298 12.0629 8.83298 12.0629 8.85002 12.069C8.88578 12.0818 8.88578 12.0818 8.92484 12.0964C8.9772 12.1164 9.03645 12.1357 9.09679 12.1529L9.27862 12.2023C9.39035 12.2302 9.50757 12.2543 9.63221 12.2756C9.77777 12.2972 9.92433 12.311 10.0576 12.3166H10.2811L10.4764 12.2946C10.5013 12.2929 10.5013 12.2929 10.5474 12.2874C10.6189 12.2788 10.6625 12.2751 10.7186 12.2751L10.778 12.2787L10.9843 12.2491L11.0802 12.2311L11.3089 12.1904C12.0243 12.0064 12.6773 11.6337 13.1998 11.1111Z" />
  </svg>
);

export default SVG;