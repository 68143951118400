import React from "react";
import { Spinner } from "react-bootstrap";



const PageLoader = (props) => {
    // console.log('loader_props',props);
    return (
        <>
            <div className={props.page_loaded === false ? 'loader_wrap' : 'loader_wrap d-none'}>
                <div className="loader">
                    <Spinner animation="grow" variant="light" role="status">
                        <span className="visually-hidden">Loading...</span>
                    </Spinner>
                </div>
            </div>

        </>
    )
}

export default PageLoader;