import React,{useState,useEffect} from "react";
import { Container, Row, Col } from "react-bootstrap";
// import Charlie from "../assets/img/charlie-munger.png";
// import Warren from "../assets/img/warren-buffett.png";
// import Jeff from "../assets/img/jeff-bezos.png";
import QuoteImg from "../assets/img/quote-img.png";
import Slider from "react-slick";
import { Link } from "react-router-dom";
import Airtable from "airtable";

const CardScroll = (props) => {
    const handleWindowSizeChange = () => {
        setWidth(window.innerWidth);
    }


    // console.log('CardScroll_props',props);
    var settings = {
        infinite: true,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: false,
        autoplaySpeed: 3000,
        speed: 700,
        arrows: false,
        dots:true
    };
    var CardSlider = {
        vertical: true,
        verticalSwiping: true,
        infinite: true,
        slidesToShow: 5,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 0,
        cssEase: 'linear',
        speed: 10000,
        arrows: false,
        responsive: [
            {
                breakpoint: 767,
                settings: {
                    slidesToShow: 3,
                }
            },
        ]
    };

    const [quotes,setQuotes] = useState([]);
    const [authors,setAuthors] = useState([]);
    const [width, setWidth] = useState(window.innerWidth);
    let card_scroll_block_key = 0;

    useEffect(() => {
        // console.log('width',width);
    },[width])

    useEffect(() => {
        window.addEventListener('resize', handleWindowSizeChange);
        return () => {
            window.removeEventListener('resize', handleWindowSizeChange);
        }
    }, []);

    async function get_author_details(authord_id,quotes_fields)
    {
        if(authord_id != '' && quotes !== undefined && quotes_fields !== undefined)
        {
            var base = new Airtable({apiKey: props.apiKey}).base(props.baseKey);
            let all_slider_images = [];
            await base('persons').find(authord_id, function(err, record) {
                if (err) { console.error(err); return; }
                let final_quotes = {quotes_fields:quotes_fields,author_details:record.fields};
                let quotes2 = [];
                quotes2 = [...quotes2,final_quotes];
                let new_quotes = quotes;
                new_quotes.push(final_quotes);
                // console.log('new_quotes',new_quotes);
                setQuotes([new_quotes])
                return record;
            });
        }
    }

    async function get_quotes()
    {
        var base = new Airtable({apiKey: props.apiKey}).base(props.baseKey);
        let all_slider_images = [];
        await base('quotes').select({
            // Selecting the first 3 records in Grid view:
            view: "Grid view",
            filterByFormula:`({isHomepage} = '1')`
        }).all().then(records => {
            // console.log(records);
            if(records.length > 0)
            {
                records.forEach((val,key) => {
                    let quotes_fields = val.fields;
                    let author_id = quotes_fields.author[0];
                    let isHomepage = quotes_fields.isHomepage !== undefined && quotes_fields.isHomepage === true ? true : false;
                    if(isHomepage === true && author_id != '')
                    {
                        // console.log('quotes_fields',quotes_fields);
                        let author_details = get_author_details(author_id,quotes_fields);
                    }

                })
            }
        }).catch(err => {
            // console.error(err);
        });
    }

    useEffect(() => {
        // console.log('quotes',quotes);
    },[quotes]);

    useEffect(() => {
        get_author_details();
        let slider_images = get_quotes();
    },[]);

    return (
        <>
        <div className="card_scroll_wrapper">
            <Container className="position-relative">
                <Row>
                    <Col lg={6} xs={12}>
                        <div className="d-lg-none insight_slider_wrap_content">
                        { props.testimonial_content.Title !== undefined ? <h2>{props.testimonial_content.Title}</h2> : null }
                            { props.testimonial_content.Description !== undefined ? <p dangerouslySetInnerHTML={{__html: props.testimonial_content.Description}}></p> : null }
                        </div>
                        <div className="card_scroll_sec">
                            <div className="card_scroll_overlay"></div>
                            <Slider {...CardSlider} className="card_scroll">
                            {
                                quotes[0] !== undefined && quotes[0].length > 0 ?

                                quotes[0].map((val,key) => {
                                    //card_scroll_block_key = card_scroll_block_key + 1;
                                    let author_name = val.author_details.name;
                                    let author_role = val.author_details.role;
                                    let author_photo = val.author_details.photo[0].thumbnails.large.url;
                                    let quote = val.quotes_fields.text;
                                    let popular_person_photo_extension = author_photo.split('.').reverse()[0];

                                   // if( (width > 768 && card_scroll_block_key >= 0 ) || (width <= 768 && card_scroll_block_key <= 3) )
                                   // {
                                        return <div className="card_scroll_block" key={card_scroll_block_key}>
                                                    <div className="card_scroll_block_top">
                                                        <div className="card_scroll_block_img flex-shrink-0">
                                                            {
                                                                popular_person_photo_extension == 'mp4'
                                                                ?
                                                                    <>
                                                                        <video width="100%" height="100%" className="" src={author_photo}></video>
                                                                    </>

                                                                :
                                                                    <img src={author_photo} alt={author_name} />
                                                            }
                                                        </div>
                                                        <div className="card_scroll_block_author">
                                                            <Link to={`/discover/people/${author_name.toLowerCase().split(' ').join('-')}`}><h4>{author_name}</h4></Link>
                                                            <div className="fs-sm">{author_role}</div>
                                                        </div>
                                                    </div>
                                                    <div className="card_scroll_block_content">
                                                        {quote}
                                                    </div>
                                                </div>
                                    //}
                                })

                                : null
                            }


                            </Slider>
                        </div>
                    </Col>
                    <Col lg={6} xs={12}>
                        <div className="insight_slider_wrap">
                            <div className="d-none d-lg-block insight_slider_wrap_content">
                            { props.testimonial_content.Title !== undefined ? <h2>{props.testimonial_content.Title}</h2> : null }
                            { props.testimonial_content.Description !== undefined ? <p dangerouslySetInnerHTML={{__html: props.testimonial_content.Description}}></p> : null }
                            </div>
                            {
                                props.insights_slider !== undefined && props.insights_slider.length > 0
                                ?
                                    <Slider {...settings} className="insight_slider">
                                        {
                                            props.insights_slider.map((testimonial_val) => {
                                                card_scroll_block_key = card_scroll_block_key+1;
                                                // console.log('testimonial_val_icon',testimonial_val.fields.Icon);
                                                return <div className="slide" key={card_scroll_block_key}>
                                                            <div className="insight_slider_block">
                                                                <div className="insight_slider_top">
                                                                    <div className="quote_icon">
                                                                        <img src={ ( testimonial_val.fields.Icon !== undefined && testimonial_val.fields.Icon[0] !== undefined && testimonial_val.fields.Icon[0].url !== undefined ) ? testimonial_val.fields.Icon[0].url :  QuoteImg} alt="Quote" />
                                                                    </div>
                                                                    {testimonial_val.fields.Tag !== undefined ? <div className="insight_slider_tag">{testimonial_val.fields.Tag}</div> : null }
                                                                </div>
                                                                <div className="insight_slider_title">
                                                                {testimonial_val.fields.Title !== undefined ? <h5>{testimonial_val.fields.Title}</h5> : null }
                                                                </div>
                                                                <div className="insight_slider_content">
                                                                {testimonial_val.fields.Description !== undefined ? <p dangerouslySetInnerHTML={{__html: testimonial_val.fields.Description}}></p> : null }
                                                                </div>
                                                            </div>
                                                        </div>
                                            })
                                        }


                                    </Slider>
                                : null
                            }

                        </div>
                    </Col>
                </Row>
            </Container>
        </div>
        </>
    )
}

export default CardScroll;