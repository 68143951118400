import React ,{useEffect,useState,useRef
} from "react";
import Airtable from "airtable";
import Alert from 'react-bootstrap/Alert';
import Spinner from 'react-bootstrap/Spinner';
import BarImg from "../assets/img/bar.png";
import { Button, Modal, Tab, Nav, ListGroup } from "react-bootstrap";
import { NavLink, Link } from "react-router-dom";
import TwitterShare from "../assets/img/twitter-share-ic.png";
import EmailShare from "../assets/img/email-share-ic.png";
import {LinkingIcon} from "../assets/icons";
import StripePayments from "../assets/img/stripe-secure-payments.png";
import infoicon from "../assets/img/infoicon.png";
import GPayIcon from "../assets/img/g-pay-ic.svg";
import VbyVisaIcon from "../assets/img/verifie-bt-visa-ic.svg";
import VisaIcon from "../assets/img/visa-ic.svg";
import MasterCardIcon from "../assets/img/mastercard-ic.svg";
import ApplePayIcon from "../assets/img/apple-pay-ic.svg";

const Newsletter = (props) => {

    const start_account_block = useRef(null);
    const after_submit_email_process_block = useRef(null);
    const [progress,set_progress] = useState(50);
    const [loading,set_loading] = useState(false);
    const Router = require("react-router");
    const email_address = useRef(null);
    const [signup_status,set_signup_status] = useState('');
    const [form_in_progress,set_form_in_progress] = useState(false);
    const [formError, setFormError] = useState(false);
    const [ref,set_ref] = useState('');
    const [copy_link_text,set_copy_link_text] = useState('Copy link')

    useEffect(() => {
        after_submit_email_process_block.current.style.display = "none";
    },[]);

    function ValidateEmail(mail)
    {
        console.log('ValidateEmail');
        setFormError(false);
        if(mail === '' || mail === undefined || mail === null)
        {
            console.log('false');
            setFormError(true);
            set_form_in_progress(false);


        }
        else
        {
            console.log('true');
            setFormError(false);
            const is_email_exists = email_exists(mail);

        }
    }


    function handle_newsletter(e)
    {
        set_form_in_progress(true);
        e.preventDefault();
        const email = email_address.current.value;
        console.log('email',email);
        setFormError(false);
        if(email == '')
        {
            setFormError(true);
            set_form_in_progress(false);
        }
        ValidateEmail(email);
        // start_account_block.current.style.display = "none";
        // after_submit_email_process_block.current.style.display = "block";
        // set_progress(100);
    }

    async function sign_up(mail)
    {
        var base = new Airtable({apiKey: props.apiKey }).base(props.baseKey);

        await base('Signup').create([
            {
                fields: {
                            Email: `${mail}`,
                            ref : 'http://yourfeed.ai/?ref='+Math.random().toString(36).slice(5)
                        },
            },
        ]).then(function(createdRecord) {
            if(createdRecord !== undefined && createdRecord[0] !== undefined)
            {
                console.log('createdRecord',createdRecord[0].fields.ref);
                set_ref(createdRecord[0].fields.ref);
            }
            email_address.current.value = '';
            start_account_block.current.style.display = "none";
            after_submit_email_process_block.current.style.display = "block";
            set_progress(100);
            set_signup_status('Success. Your email address is added to the signup queue.');
            set_form_in_progress(false);
        });
    }


    async function email_exists(mail)
    {
        var base = new Airtable({apiKey: props.apiKey }).base(props.baseKey);

        // await base('Signup').find('recUsMpKYprD46nAi').then(function(foundRecord) {
        //     console.log('foundRecord',foundRecord);
        // });

        await base('Signup').select({
            // Selecting the first 3 records in Grid view:
            view: "Grid view",
            filterByFormula:`(LOWER({Email}) = '${mail}')`
        }).all().then(records => {
            console.log('records',records);
            if(records.length === 0)
            {
                sign_up(mail);

            }
            else
            {
                start_account_block.current.style.display = "none";
                after_submit_email_process_block.current.style.display = "block";
                set_progress(100);
                set_signup_status('Your email address is already added to the signup queue.');
                set_form_in_progress(false);
                if(records[0] !== undefined && records[0].fields !== undefined && records[0].fields.ref !== undefined)
                {
                    set_ref(records[0].fields.ref);
                }
            }

        }).catch(err => {
            console.error('err',err);
        });
    }

    function copy(text) {
        var input = document.createElement('input');
        input.setAttribute('value', text);
        document.body.appendChild(input);
        input.select();
        var result = document.execCommand('copy');
        document.body.removeChild(input);
        return result;
    }

    useEffect(() => {
    },[props]);

    return (
        <>
            <div className="soon_content">
                {
                    props !== undefined && props.mental_footer_box !== undefined && props.mental_footer_box === true
                    ?
                        <div className="mental_footer_box">
                            <div className="mental_footer_box_inner">
                                <div className="d-flex align-items-center info-box">
                                    <img src={infoicon} alt="Info Icon" />
                                    <p className="mb-0">We are still working on this section of the site. Meanwhile, you can sign up for <strong>early access</strong> or become yourfeed.ai "<strong>founding member</strong>".</p>
                                </div>
                                <div className="mental_footer_btns_wrap text-center">
                                    <Link to="/pricing" className="btn form_btn">Become a founding member</Link>
                                </div>
                                <div className="payment-cards text-center">
                                    <ListGroup as="ul" horizontal className="fund_payment_type">
                                        <ListGroup.Item as="li" className="bg-transparent"><img src={GPayIcon} alt="GPayIcon" /></ListGroup.Item>
                                        <ListGroup.Item as="li" className="bg-transparent"><img src={VbyVisaIcon} alt="VbyVisaIcon" /></ListGroup.Item>
                                        <ListGroup.Item as="li" className="bg-transparent"><img src={VisaIcon} alt="VisaIcon" /></ListGroup.Item>
                                        <ListGroup.Item as="li" className="bg-transparent"><img src={MasterCardIcon} alt="MasterCardIcon" /></ListGroup.Item>
                                        <ListGroup.Item as="li" className="bg-transparent"><img src={ApplePayIcon} alt="ApplePayIcon" /></ListGroup.Item>
                                    </ListGroup>
                                    <p>because your support is so valuable</p>
                                </div>
                            </div>
                        </div>
                    :
                        null
                }

                <div className="soon_progress">
                    <div className="progress">
                        <div className="progress-bar progress-bar-striped progress-bar-animated" role="progressbar" aria-valuenow="25" aria-valuemin="0" aria-valuemax="100" style={{width: progress + '%'}}></div>
                    </div>
                </div>
                <div className="start_account_block" ref={start_account_block}>
                    <div className="text-center">
                        <h1 className="soon_title mx-auto">START CREATING YOUR ACCOUNT</h1>
                        <div className="soon_bg_text">
                            <h4>WANT IN ON THE FUTURE EARLY?</h4>
                        </div>
                        <div className="soon_banner_form">
                            <form method="post" onSubmit={(e) => handle_newsletter(e) }>
                                <label>Sign up to be notified when you receive access to register</label>
                                <div className="custom-group">
                                    <input ref={email_address} autoComplete="off" type="email" name="email" placeholder="Enter your email address" />
                                    <button className="btn submit_btn" type="submit"><span>
                                        {
                                            form_in_progress === true
                                            ?
                                                <Spinner style={{'marginRight':'5px'}} as="span" animation="grow" size="sm" role="status" aria-hidden="true"/>
                                            : null
                                        }
                                            GET EARLY ACCESS</span>
                                    </button>
                                </div>
                                {
                                    formError === true
                                    ?
                                        <Alert variant="danger" onClose={() => setFormError(false)} dismissible>
                                            <><strong>Email address is required.</strong></>
                                        </Alert>
                                    :   null
                                }


                            </form>
                        </div>
                    </div>
                    <div className="soon_banner_text text-center">
                        <img src={BarImg} alt="Dash" />
                        <p><strong>Psst!</strong> You can get priority access by referring your friends. The more friends that join, the sooner you'll get access. You can access your unique referral link after you sign up.</p>
                    </div>
                </div>
                <div className="after_submit_email_process_block" ref={after_submit_email_process_block}>
                    <Tab.Container id="topics-tabs" defaultActiveKey="priority-access">
                        <Nav className="after_submit_email_tabs justify-content-between">
                            <Nav.Item>
                                <Nav.Link eventKey="priority-access">Priority access</Nav.Link>
                            </Nav.Item>
                            <Nav.Item>
                                <Nav.Link eventKey="become-founding-member">Become a founding member</Nav.Link>
                            </Nav.Item>
                        </Nav>
                        <Tab.Content>
                                <Tab.Pane eventKey="priority-access">
                                    <div className="succes_email_sms">{signup_status}</div>
                                    <div className="referring_friends_text text-center">
                                        <img src={BarImg} alt="Dash" />
                                        <p>Get priority access by referring your friends. The more friends that join, the sooner you'll get access.</p>
                                        {
                                            ref !== undefined && ref!== null && ref !== ''
                                            ?
                                                <>
                                                    <div className="referring_friends_sharing">
                                                        <a target="_blank" href={"https://twitter.com/intent/tweet?text="+ref}><img src={TwitterShare} alt="TwitterShare" /></a>
                                                        <a target="_blank" href={`https://mail.google.com/mail/?view=cm&fs=1&tf=1&to=&su=&body=${ref}&ui=2&tf=1&pli=1'`}><img src={EmailShare} alt="EmailShare" /></a>
                                                    </div>
                                                    <span>Or share your unique link:</span>
                                                    <div className="ref_link_text">{ref}</div>
                                                    <Button onClick={() => {
                                                                copy(ref)
                                                                set_copy_link_text('Copied')
                                                            }} className="link_copy_link">{copy_link_text} <LinkingIcon fill="#fff" /></Button>
                                                </>
                                            : null
                                        }
                                    </div>
                                </Tab.Pane>
                                <Tab.Pane eventKey="become-founding-member">
                                    <div className="become_founding_member_block">
                                        <h2>Funding yourfeed.ai can be cheaper than a sandwich.</h2>
                                        <p>As a token of our appreciation, early supporters receive a "<span className="text-secondary">founding member badge</span>" and a chance to participate in future bitcoin giveaways. Your limited-edition "<span className="text-secondary">founding member badge</span>" will be shown under your profile. </p>
                                        <Link to="/pricing" className="btn btn-info">Tell me more</Link>
                                        <div className="small">because your help makes the difference</div>
                                        <div className="stripe_logo">
                                            <img src={StripePayments} alt="Stripe" />
                                        </div>
                                    </div>
                                </Tab.Pane>
                            </Tab.Content>
                    </Tab.Container>
                </div>
            </div>
        </>
    )
}

export default Newsletter;