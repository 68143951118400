import React from "react";

const SVG = ({
  style = {},
  fill = "#fff",
  stroke="none",
  width = "16",
  height = "24",
  className = "",
  viewBox = "0 0 16 24"
}) => (
  <svg
    width={width}
    style={style}
    height={height}
    viewBox={viewBox}
    xmlns="http://www.w3.org/2000/svg"
    className={`svg-icon ${className || ""}`}
    xmlnsXlink="http://www.w3.org/1999/xlink"
  >
    <path fill={fill} stroke={stroke} d="M0.679688 21.18L9.83969 12L0.679688 2.82L3.49969 0L15.4997 12L3.49969 24L0.679688 21.18Z" />
  </svg>
);

export default SVG;