import React, { useEffect } from "react";
import { NavLink, Link } from "react-router-dom";
import TabSection from "../components/Tab";
import Logo from "../assets/img/logo.png";
import Logosm from "../assets/img/logo-sm.png";
import BackImg from "../assets/img/arrow-back.png";
import BarImg from "../assets/img/bar.png";
import Slider from "react-slick";
import elonn from "../assets/img/elonn.png";
import SlImgone from "../assets/img/sl-img-one.png";
import SlImgtwo from "../assets/img/sl-img-two.png";
import SlImgthree from "../assets/img/sl-img-three.png";
import SlImgfour from "../assets/img/sl-img-four.png";
import SlImgfive from "../assets/img/sl-img-five.png";
import Handwriting from "../assets/img/Handwriting.png";
import EmailShare from "../assets/img/email-share-ic.png";
import TwitterShare from "../assets/img/twitter-share-ic.png";
import StripePayments from "../assets/img/stripe-secure-payments.png";
import { useState,useRef } from "react";
import { Button, Modal, Tab, Nav } from "react-bootstrap";
import {
    LinkingIcon,
} from "../assets/icons";
import Airtable from "airtable";
import Alert from 'react-bootstrap/Alert';
import Spinner from 'react-bootstrap/Spinner';
import Newsletter from "./Newsletter";
import { useHistory } from "react-router-dom";

const ComingSoon = (props) => {

    const start_account_block = useRef(null);
    const after_submit_email_process_block = useRef(null);
    const [progress,set_progress] = useState(50);
    const [loading,set_loading] = useState(false);
    const email_address = useRef(null);
    var settings = {
        infinite: true,
        slidesToShow: 9,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 100,
        cssEase: 'linear',
        speed: 12000,
        arrows: false,
        responsive: [
            {
                breakpoint: 1800,
                settings: {
                    slidesToShow: 8,
                }
            },
            {
                breakpoint: 1600,
                settings: {
                    slidesToShow: 7,
                }
            },
            {
                breakpoint: 1200,
                settings: {
                    slidesToShow: 6,
                }
            },
            {
                breakpoint: 992,
                settings: {
                    slidesToShow: 5,
                }
            },
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 4,
                }
            },
            {
                breakpoint: 425,
                settings: {
                    slidesToShow: 2,
                }
            }
        ]

    };
    var settingsTwo = {
        rtl: true,
        infinite: true,
        slidesToShow: 9,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 100,
        cssEase: 'linear',
        speed: 12000,
        arrows: false,
        responsive: [
            {
                breakpoint: 1800,
                settings: {
                    slidesToShow: 8,
                }
            },
            {
                breakpoint: 1600,
                settings: {
                    slidesToShow: 7,
                }
            },
            {
                breakpoint: 1200,
                settings: {
                    slidesToShow: 6,
                }
            },
            {
                breakpoint: 992,
                settings: {
                    slidesToShow: 5,
                }
            },
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 4,
                }
            },
            {
                breakpoint: 425,
                settings: {
                    slidesToShow: 2,
                }
            }
        ]
    }

    const [show, setShow] = useState(true);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const history = useHistory();

    useEffect(() => {
        // after_submit_email_process_block.current.style.display = "none";
        set_loading(true);
        console.log('history',history);
    },[]);

    return (
        <>
        {/* <Button variant="primary" onClick={handleShow}>
             Launch demo modal
        </Button> */}


                <div className="soon_page">
                    <div className="soon_page_header d-flex align-items-center justify-content-between">
                        {
                            history !== undefined && history.location !== undefined && history.location.key !== undefined
                            ?
                                <button type="button" className="btn back_btn" onClick={() => {history.goBack()}}>
                                    <img src={BackImg} alt="Back to Home" />
                                </button>
                            :   <Link to="/" type="button" className="btn back_btn"><img src={BackImg} alt="Back to Home" /></Link>
                        }
                        <NavLink className="navbar-brand" exact to="/">
                            <img src={Logo} alt="YourFeed.ai" />
                        </NavLink>
                    </div>
                    <div className="soon_wrap banner_wrap">
                        <div className="bg_slider_wrap">
                            <Slider {...settings} className="bg_slider_row bg_slider">
                                <div className="slide">
                                    <img src={elonn} alt="Elon Musk" />
                                </div>
                                <div className="slide">
                                    <img src={SlImgone} alt="Elon Musk" />
                                </div>
                                <div className="slide">
                                    <img src={SlImgtwo} alt="Elon Musk" />
                                </div>
                                <div className="slide">
                                    <img src={SlImgthree} alt="Elon Musk" />
                                </div>
                                <div className="slide">
                                    <img src={SlImgfour} alt="Elon Musk" />
                                </div>
                                <div className="slide">
                                    <img src={SlImgfive} alt="Elon Musk" />
                                </div>
                                <div className="slide">
                                    <img src={elonn} alt="Elon Musk" />
                                </div>
                                <div className="slide">
                                    <img src={SlImgone} alt="Elon Musk" />
                                </div>
                                <div className="slide">
                                    <img src={SlImgtwo} alt="Elon Musk" />
                                </div>
                                <div className="slide">
                                    <img src={SlImgthree} alt="Elon Musk" />
                                </div>
                            </Slider>
                            <Slider {...settingsTwo} dir="rtl" className="bg_slider_row bg_slider_rtl">
                                <div className="slide">
                                    <img src={SlImgone} alt="Elon Musk" />
                                </div>
                                <div className="slide">
                                    <img src={SlImgtwo} alt="Elon Musk" />
                                </div>
                                <div className="slide">
                                    <img src={SlImgthree} alt="Elon Musk" />
                                </div>
                                <div className="slide">
                                    <img src={SlImgfour} alt="Elon Musk" />
                                </div>
                                <div className="slide">
                                    <img src={SlImgfive} alt="Elon Musk" />
                                </div>
                                <div className="slide">
                                    <img src={elonn} alt="Elon Musk" />
                                </div>
                                <div className="slide">
                                    <img src={SlImgone} alt="Elon Musk" />
                                </div>
                                <div className="slide">
                                    <img src={SlImgtwo} alt="Elon Musk" />
                                </div>
                                <div className="slide">
                                    <img src={SlImgthree} alt="Elon Musk" />
                                </div>
                                <div className="slide">
                                    <img src={SlImgfour} alt="Elon Musk" />
                                </div>
                                <div className="slide">
                                    <img src={SlImgfive} alt="Elon Musk" />
                                </div>
                            </Slider>
                            <Slider {...settings} className="bg_slider_row bg_slider">
                            <div className="slide">
                                    <img src={elonn} alt="Elon Musk" />
                                </div>
                                <div className="slide">
                                    <img src={SlImgone} alt="Elon Musk" />
                                </div>
                                <div className="slide">
                                    <img src={SlImgtwo} alt="Elon Musk" />
                                </div>
                                <div className="slide">
                                    <img src={SlImgthree} alt="Elon Musk" />
                                </div>
                                <div className="slide">
                                    <img src={SlImgfour} alt="Elon Musk" />
                                </div>
                                <div className="slide">
                                    <img src={SlImgfive} alt="Elon Musk" />
                                </div>
                                <div className="slide">
                                    <img src={elonn} alt="Elon Musk" />
                                </div>
                                <div className="slide">
                                    <img src={SlImgone} alt="Elon Musk" />
                                </div>
                                <div className="slide">
                                    <img src={SlImgtwo} alt="Elon Musk" />
                                </div>
                                <div className="slide">
                                    <img src={SlImgthree} alt="Elon Musk" />
                                </div>
                            </Slider>
                            <Slider {...settingsTwo} dir="rtl" className="bg_slider_row bg_slider_rtl">
                            <div className="slide">
                                    <img src={SlImgone} alt="Elon Musk" />
                                </div>
                                <div className="slide">
                                    <img src={SlImgtwo} alt="Elon Musk" />
                                </div>
                                <div className="slide">
                                    <img src={SlImgthree} alt="Elon Musk" />
                                </div>
                                <div className="slide">
                                    <img src={SlImgfour} alt="Elon Musk" />
                                </div>
                                <div className="slide">
                                    <img src={SlImgfive} alt="Elon Musk" />
                                </div>
                                <div className="slide">
                                    <img src={elonn} alt="Elon Musk" />
                                </div>
                                <div className="slide">
                                    <img src={SlImgone} alt="Elon Musk" />
                                </div>
                                <div className="slide">
                                    <img src={SlImgtwo} alt="Elon Musk" />
                                </div>
                                <div className="slide">
                                    <img src={SlImgthree} alt="Elon Musk" />
                                </div>
                                <div className="slide">
                                    <img src={SlImgfour} alt="Elon Musk" />
                                </div>
                                <div className="slide">
                                    <img src={SlImgfive} alt="Elon Musk" />
                                </div>
                            </Slider>
                        </div>{/* banner slider end*/}
                        <div className="container">
                            {
                                props !== undefined && props.apiKey !== undefined && props.baseKey !== undefined
                                ?
                                    <Newsletter apiKey={props.apiKey} baseKey={props.baseKey} />
                                :
                                    null
                            }

                        </div>
                        {/*banner content end*/}
                    </div>{/* banner_wrap */}
                    <div className="knowledge_wrap">
                        <div className="container">
                            <h3>What is yourfeed.ai?</h3>
                            <p>Think of yourfeed.ai as a <span className="text-secondary">"bicycle for the mind"</span>. Blend of software and timeless knowledge from a wide range of disciplines helping you master the best what other people are already figured out. <strong>Helping you think better, perform better, and live a better life.</strong></p>
                        </div>
                        {
                            props.apiKey !== undefined && props.baseKey !== undefined
                            ?
                                <TabSection apiKey={props.apiKey} baseKey={props.baseKey} />
                            :
                                null
                        }
                    </div>
                    <div className="soon_page_footer">
                        <div className="container">
                            <NavLink className="soon_footer_logo" exact to="/">
                                <img src={Logosm} alt="YourFeed.ai" />
                            </NavLink>
                            <div className="soon_page_content">
                                <h6>We are not the biggest feed company in the world. That means we need to try harder. Or else.</h6>
                                <h5>We are in the feed business. Competing against giants and <br/> we humbly ask you to help.</h5>
                                <p>We are building a feed for your brain. A feed that uplifts, a feed that educates and helps you think better. A feed that entertains and calms your mind. A feed that creates wealth and works for you. A feed that you control! <strong className="text-secondary"> Your feed.</strong></p>
                                <img src={Handwriting} alt="YourFeed.ai" />
                                <div className="soon_page_text">©2021 Onwards and Upwards Company OÜ</div>
                            </div>
                        </div>
                    </div>
                </div>


        </>
    )
}

export default ComingSoon;