import React from "react";

const SVG = ({
  style = {},
  fill = "#18D551",
  stroke="none",
  width = "19",
  height = "16",
  className = "",
  viewBox = "0 0 19 16"
}) => (
  <svg
    width={width}
    style={style}
    height={height}
    viewBox={viewBox}
    xmlns="http://www.w3.org/2000/svg"
    className={`svg-icon ${className || ""}`}
    xmlnsXlink="http://www.w3.org/1999/xlink"
  >
    <path fill={fill} stroke={stroke} d="M9.4987 0C4.71223 0 0.832031 3.58252 0.832031 8.00033C0.832031 12.4188 4.71223 16 9.4987 16C14.2852 16 18.1654 12.4188 18.1654 8.00033C18.1654 3.58252 14.2848 0 9.4987 0ZM11.7923 7.96686H10.2916C10.2916 10.1806 10.2916 12.9074 10.2916 12.9074H8.06733C8.06733 12.9074 8.06733 10.2086 8.06733 7.96686H7.00939V6.22255H8.06733V5.09227C8.06733 4.2833 8.48333 3.02047 10.3124 3.02047L11.9595 3.02632V4.7206C11.9595 4.7206 10.9579 4.7206 10.7636 4.7206C10.5683 4.7206 10.292 4.81059 10.292 5.19526V6.22287H11.9877L11.7923 7.96686Z" />
  </svg>
);

export default SVG;