import React, {useState,useEffect} from "react";
import {Accordion } from "react-bootstrap";
import { Link } from "react-router-dom";
import QuoteIc from "../assets/img/quote.png";
import UserIc from "../assets/img/user-search.png";
// import Share from "../assets/img/share-icon-2.png";
import LinkImg from "../assets/img/link-y.png";
// import CopyIcon from "../assets/img/SMS.png";
// import MessangerIcon from "../assets/img/Messenger.png";
// import TwitterIcon from "../assets/img/Twitter.png";
// import WhatsappIcon from "../assets/img/WhatsApp.png";
// import EmailIcon from "../assets/img/email-icon.png";

import CopyIconLg from "../assets/img/sms-lg.png";
import ShareIconLg from "../assets/img/share-lg.png";
import MessangerIconLg from "../assets/img/messenger-lg.png";
import TwitterIconLg from "../assets/img/twitter-lg.png";
import WhatsappIconLg from "../assets/img/whatsapp-lg.png";
import EmailIconLg from "../assets/img/email-lg.png";
// import Slider from "react-slick";
import {
    SharingIcon

} from "../assets/icons";
import { RWebShare } from "react-web-share";

const PeopleShareModal = (props) => {
    // console.log('PeopleShareModal_props',props)
    const [open_people_popup,set_open_people_popup] = useState(false);
    const [copy_link_text,set_copy_link_text] = useState('Copy Link');
    const [people_popup_data,set_people_popup_data] = useState({username:'',user_profile_link:''});
    const [share_link,set_share_link] = useState('');

    var ShareSlider = {
        infinite: false,
        slidesToShow: 4,
        slidesToScroll: 1,
        arrows: false,
        dots:false,
    }

    useEffect(() => {
        set_open_people_popup(props.open_people_popup);
        set_people_popup_data(props.people_popup_data);
        if(people_popup_data.profile_page !== undefined && people_popup_data.profile_page === true)
        {
            set_share_link(window.location.origin+'/discover/people/'+props.people_popup_data.slug+'/'+props.people_popup_data.quote_id);
        }
        else
        {
            set_share_link(window.location.origin+'/discover/people/'+props.people_popup_data.slug+'/');
        }
    },[props]);

    function copy(text) {
        var input = document.createElement('input');
        input.setAttribute('value', text);
        document.body.appendChild(input);
        input.select();
        var result = document.execCommand('copy');
        document.body.removeChild(input);
        return result;
     }

    return (
        <>
            {
                <div className={props.open_people_popup === true ? 'share_popup_wrap active' : 'share_popup_wrap'}>
                    <div className={props.open_people_popup === true ? 'share_popup active' : 'share_popup'}>
                        <div className="share_close_ic d-md-none">
                            <button onClick={() => {
                                    props.set_open_people_popup(false);
                            }}></button>
                        </div>

                        {
                            people_popup_data.profile_page !== undefined && people_popup_data.profile_page === true
                            ?
                                <div className="quote_link">
                                    <Link to={`/discover/people/${people_popup_data.slug}/`+people_popup_data.quote_id+'/'}><img src={QuoteIc} alt="user" /> View this specific quote</Link>
                                </div>
                            :
                                <div className="profile_link">
                                    <Link to={'/discover/people/'+people_popup_data.slug+'/'}><img src={UserIc} alt="user" />Explore {people_popup_data.username} profile</Link>
                                </div>
                        }



                        <Accordion flush className="share_link">
                            <Accordion.Item eventKey="0">
                                <Accordion.Header><SharingIcon />Share link to this quote</Accordion.Header>
                                <Accordion.Body className="p-0">
                                    <div className="share_icons">
                                        {
                                            <>
                                                
                                                <span onClick={() => {
                                                    copy(share_link)
                                                }} className="share_icon d-md-inline-block">
                                                    <img src={CopyIconLg} alt="Copy link" />
                                                    <p>Copy link</p>
                                                </span>
                                                
                                                <>
                                                    <RWebShare
                                                        data={{
                                                        url: window.location.hred,
                                                        title: "Your Feed",
                                                        }}
                                                        onClick={() => console.log("shared successfully!")}
                                                    >
                                                        <a className="share_icon">
                                                        <img src={ShareIconLg} alt="Share" />
                                                        <p>Share via</p>
                                                        </a>
                                                    </RWebShare>
                                                </>   
                                            
                                                <a target="_blank" href={`https://twitter.com/intent/tweet?text=${share_link}`} className="share_icon d-md-inline-block">
                                                    <img src={TwitterIconLg} alt="Twitter" />
                                                    <p>Twitter</p>
                                                </a>
                                            
                                                <a target="_blank" href={`https://web.whatsapp.com/send?text=${share_link}`} className="share_icon d-md-inline-block">
                                                    <img src={WhatsappIconLg} alt="Whatsapp" />
                                                    <p>Whatsapp</p>
                                                </a>
                                            
                                                <a target="_blank" href={`https://mail.google.com/mail/?view=cm&fs=1&tf=1&to=&su=&body=${share_link}&ui=2&tf=1&pli=1`} className="share_icon d-md-inline-block">
                                                    <img src={EmailIconLg} alt="Email" />
                                                    <p>Email</p>
                                                </a>
                                            </>
                                        }
                                            

                                    </div>
                                </Accordion.Body>
                            </Accordion.Item>
                        </Accordion>
                        <div className="copy_link">
                            <p><img src={LinkImg} alt="Link" /> <span id="website_link">{window.location.origin}</span></p>
                            <button className="btn" onClick={() => {
                                let website_link_text = document.getElementById('website_link').innerText;
                                copy(window.location.origin);
                                set_copy_link_text('Copied');
                            }} type="button">{copy_link_text}</button>
                        </div>
                        <p className="share_text">Share yourfeed.ai on <strong>Twitter, Snapchat, Email</strong> etc.</p>
                        <div className="share_close">
                            <button onClick={() => {
                                set_copy_link_text('Copy Link');
                                props.set_open_people_popup(false);
                            }} className="btn">Hide</button>
                        </div>
                    </div>
                </div>

            }

        </>

    )
}

export default PeopleShareModal;