import React, { useEffect } from "react";
import {Switch, Route, Redirect } from "react-router-dom";
import './App.css';
import './Responsive.css';
import Navigation from "./components/Navigation";
import Home from "./Home";
import YourFeed from "./YourFeed";
import Discover from "./Discover";
import Apps from "./Apps";
import Pricing from "./Pricing";
import Topics from "./Topics";
import Profile from "./Profile";
import Quotes from "./quotes";
import Footer from "./components/Footer";
import ComingSoon from "./components/ComingSoon";
import GenerateSeoFile from './GenerateSeoFile';
import seoData from "./seo.json";
import ComingSoonModal from './components/SoonModal';
import { useLocation } from 'react-router-dom'

const App =() => {

  const api_key = 'key76aXjCh5MEXl2Q';
  const base_key = 'apphEmqK6AyhPDWCU';
  const location = useLocation();


  useEffect(() => {
  },[]);

  function copy(text) {
    var input = document.createElement('input');
    input.setAttribute('value', text);
    document.body.appendChild(input);
    input.select();
    var result = document.execCommand('copy');
    document.body.removeChild(input);
    return result;
 }

 function get_seo_data_of_page(page_name)
 {
    let title = '';
    let desc = '';
    seoData.map((seoData) => {
        // console.log('seoData_single',seoData.fields);
        if(seoData.fields.Name == page_name)
        {
          title = seoData.fields.MetaTitle;
          desc = seoData.fields.MetaDescription;
        }
    });
    return {title:title,desc:desc};

 }

  return (

    <>
      <Navigation apiKey={api_key} baseKey={base_key} />
        <Switch>

          <Route exact path="/" render={(props) => <Home {...props}  get_seo_data_of_page={get_seo_data_of_page} apiKey={api_key} baseKey={base_key} />} />
          <Route exact path="/feed" render={(props) => <YourFeed {...props} get_seo_data_of_page={get_seo_data_of_page} apiKey={api_key} baseKey={base_key} />} />
          <Route exact path="/your-future-account" render={(props) => <ComingSoon {...props} apiKey={api_key} baseKey={base_key} />} />
          <Route exact path="/discover" render={(props) => <Discover {...props} get_seo_data_of_page={get_seo_data_of_page} apiKey={api_key} baseKey={base_key} />} />
          <Route exact path="/apps" render={(props) => <Apps {...props} get_seo_data_of_page={get_seo_data_of_page} apiKey={api_key} baseKey={base_key} />} />
          <Route exact path="/pricing" render={(props) => <Pricing {...props} get_seo_data_of_page={get_seo_data_of_page} apiKey={api_key} baseKey={base_key} />} />
          <Route exact path={"/discover/topics/:id"} render={(props) => <Topics {...props} get_seo_data_of_page={get_seo_data_of_page} apiKey={api_key} baseKey={base_key} />} />
          <Route exact path={"/discover/people/:id"} render={(props) => <Profile {...props} get_seo_data_of_page={get_seo_data_of_page} apiKey={api_key} baseKey={base_key} />}  />
          <Route exact path="/discover/people/:profile/:id" render={(props) => <Quotes get_seo_data_of_page={get_seo_data_of_page} {...props} apiKey={api_key} baseKey={base_key} copy={copy}/>} />
          <Route exact path="/seo" render={(props) => <GenerateSeoFile {...props} apiKey={api_key} baseKey={base_key} />} />
          <Redirect to="/" />
        </Switch>

      <Footer apiKey={api_key} baseKey={base_key} copy={copy} />
    </>
  );
}

export default App;


