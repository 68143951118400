import React from "react";

const SVG = ({
  style = {},
  fill = "#18D551",
  stroke="none",
  width = "18",
  height = "14",
  className = "",
  viewBox = "0 0 18 14"
}) => (
  <svg
    width={width}
    style={style}
    height={height}
    viewBox={viewBox}
    xmlns="http://www.w3.org/2000/svg"
    className={`svg-icon ${className || ""}`}
    xmlnsXlink="http://www.w3.org/1999/xlink"
  >
<path fill={fill} stroke={stroke} d="M15.906 3.5V3.938C15.906 5.553 15.528 7.112 14.7718 8.616C14.0156 10.119 12.8478 11.392 11.2683 12.435C9.6877 13.479 7.86012 14 5.78228 14C3.77378 14 1.95812 13.473 0.332031 12.418C0.512948 12.441 0.794618 12.452 1.17812 12.452C2.87137 12.452 4.34903 11.947 5.61328 10.938C4.84628 10.938 4.15728 10.708 3.54845 10.248C2.93957 9.78899 2.49172 9.17379 2.26145 8.48C2.41962 8.525 2.64603 8.547 2.93853 8.547C3.27762 8.547 3.59287 8.513 3.88645 8.447C3.07395 8.267 2.39687 7.852 1.8552 7.201C1.31353 6.551 1.0427 5.81 1.0427 4.98V4.913C1.5627 5.204 2.09245 5.35 2.63412 5.35C1.57245 4.677 1.0427 3.701 1.0427 2.422C1.0427 1.839 1.21278 1.244 1.55187 0.638002C3.42387 2.972 5.86137 4.206 8.86437 4.34C8.79682 4.0755 8.7623 3.80474 8.76145 3.533C8.76145 2.568 9.10595 1.738 9.79387 1.043C10.484 0.347002 11.3246 0 12.318 0C13.3559 0 14.2247 0.370002 14.9245 1.11C15.8042 0.931002 16.5604 0.650002 17.192 0.270002C16.9211 1.1 16.4022 1.75 15.6352 2.221C16.426 2.109 17.102 1.918 17.6665 1.649C17.2021 2.35673 16.6062 2.98333 15.906 3.5Z" />
  </svg>
);

export default SVG;