import React, {useState, useEffect, useRef} from "react";
import Slider from "react-slick";
import { Link } from "react-router-dom";
import TabSection from "./components/Tab";
import { Accordion } from "react-bootstrap";
// import elonn from "./assets/img/elonn.png";
// import SlImgone from "./assets/img/sl-img-one.png";
// import SlImgtwo from "./assets/img/sl-img-two.png";
// import SlImgthree from "./assets/img/sl-img-three.png";
// import SlImgfour from "./assets/img/sl-img-four.png";
// import SlImgfive from "./assets/img/sl-img-five.png";
// import Stripelogo from "./assets/img/stripe-secure-payments.png";
import Dollersign from "./assets/img/doller-sign.png";
import DollerHeart from "./assets/img/doller-heart.png";
// import Stripelogo2 from "./assets/img/logo-stripe-secure-payments.png";
// import RobotoArrows from "./assets/img/robot-arrows.png";
// import RobotoArrows2 from "./assets/img/robot-arrows-2.png";
import Airtable from "airtable";
import PageLoader from "./components/PageLoader";
import {Helmet} from "react-helmet";
import Footer from "./components/Footer";

const Pricing = (props) => {

    let myRef = useRef();

    useEffect(() => {
        let seo_default_Data = props.get_seo_data_of_page('Default');
        let seo_page_Data = props.get_seo_data_of_page('Pricing');
        set_default_page_seo_data(seo_default_Data);
        set_page_seo_title(seo_page_Data);
    }, []);

    const [sliderImages,setSliderImages] = useState([]);
    const [banner_data,set_banner_data] = useState([]);
    const [price_boxes,set_price_boxes] = useState([]);
    const [faqs,set_faqs] = useState([]);
    const [faq_section,set_faq_section] = useState([]);
    const [page_loaded,set_page_loaded] = useState(false);
    const [page_seo_data,set_page_seo_title] = useState({title:'',desc:''});
    const [default_page_seo_data,set_default_page_seo_data] = useState({title:'YourFeed',desc:'YourFeed'});

    let faq_index = 0;
    let sliderImagesKey = 0;
    let slider_single_key = 0;
    let price_key = 0;

    useEffect(() => {
        console.log('pricing');
    },[]);

    useEffect(() => {
        console.log('sliderImages',sliderImages);
    },[sliderImages]);

    useEffect(() => {

        if(
            sliderImages !== undefined && sliderImages.length > 0
            && banner_data !== undefined && Object.keys(banner_data).length > 0
            // && price_boxes !== undefined && price_boxes.prices !== undefined && price_boxes.prices.length > 0
            // && faqs !== undefined  && faqs.length > 0
            // && faqs !== undefined  && Object.keys(faq_section).length > 0
        )
        {
            set_page_loaded(true)
        }
    }, [sliderImages,banner_data,price_boxes,faqs,faq_section])

    async function get_slider_images()
    {
        var base = new Airtable({apiKey: props.apiKey}).base(props.baseKey);
        let all_slider_images = [];
        await base('images').select({
            view: "Grid view"
        }).eachPage(function page(records, fetchNextPage) {
            records.forEach(function(record) {
                all_slider_images = [...all_slider_images,record.get('heroImage')];
                return all_slider_images;
            });
            if(all_slider_images.length > 0)
            {
                setSliderImages(all_slider_images);
            }
            fetchNextPage();


        }, function done(err) {
            if (err) { console.error(err); return; }
        });
    }

    async function get_banner_data()
    {
        var base = new Airtable({apiKey: props.apiKey }).base(props.baseKey);
        let all_slider_images = [];
        await base('Pricing').select({
            view: "Grid view",
            filterByFormula:`(LOWER({Name}) = 'banner')`
        }).all().then(records => {
            if(records.length > 0)
            {
                set_banner_data(records[0].fields);
            }

        }).catch(err => {
            // console.error(err);
        });
    }

    async function get_faq_section()
    {
        var base = new Airtable({apiKey: props.apiKey }).base(props.baseKey);
        let all_slider_images = [];
        await base('Pricing').select({
            view: "Grid view",
            filterByFormula:`(LOWER({Name}) = 'faq_section')`
        }).all().then(records => {
            if(records.length > 0)
            {
                set_faq_section(records[0].fields);
            }

        }).catch(err => {
        });
    }

    async function get_price_box()
    {
        var base = new Airtable({apiKey: props.apiKey }).base(props.baseKey);
        let all_slider_images = [];
        await base('Price Box').select({
            view: "Grid view",
        }).all().then(records => {
            if(records.length > 0)
            {
                set_price_boxes({prices:records});
            }

        }).catch(err => {
        });
    }

    async function get_faqs()
    {
        var base = new Airtable({apiKey: props.apiKey }).base(props.baseKey);
        let all_slider_images = [];
        await base('FAQs').select({
            view: "Grid view",
        }).all().then(records => {
            if(records.length > 0)
            {
                set_faqs(records);
            }

        }).catch(err => {
        });
    }

    useEffect(() => {
        let slider_images = get_slider_images();
        let banner_data = get_banner_data();
        let price_box = get_price_box();
        let faqs = get_faqs();
        let faq_Section = get_faq_section();
    }, []);

    useEffect(() => {
    }, [faqs])

    useEffect(() => {
    }, [banner_data])

    useEffect(() => {
    }, [price_boxes])

    useEffect(() => {
    }, [faq_section])

    var settings = {
        infinite: true,
        slidesToShow: 9,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 100,
        cssEase: 'linear',
        speed: 12000,
        arrows: false,
        responsive: [
            {
                breakpoint: 1800,
                settings: {
                    slidesToShow: 8,
                }
            },
            {
                breakpoint: 1600,
                settings: {
                    slidesToShow: 7,
                }
            },
            {
                breakpoint: 1200,
                settings: {
                    slidesToShow: 6,
                }
            },
            {
                breakpoint: 992,
                settings: {
                    slidesToShow: 5,
                }
            },
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 4,
                }
            },
            {
                breakpoint: 425,
                settings: {
                    slidesToShow: 2,
                }
            }
        ]

    };
    var settingsTwo = {
        rtl: true,
        infinite: true,
        slidesToShow: 9,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 100,
        cssEase: 'linear',
        speed: 12000,
        arrows: false,
        responsive: [
            {
                breakpoint: 1800,
                settings: {
                    slidesToShow: 8,
                }
            },
            {
                breakpoint: 1600,
                settings: {
                    slidesToShow: 7,
                }
            },
            {
                breakpoint: 1200,
                settings: {
                    slidesToShow: 6,
                }
            },
            {
                breakpoint: 992,
                settings: {
                    slidesToShow: 5,
                }
            },
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 4,
                }
            },
            {
                breakpoint: 425,
                settings: {
                    slidesToShow: 2,
                }
            }
        ]
    }

    function copy(text) {
        var input = document.createElement('input');
        input.setAttribute('value', text);
        document.body.appendChild(input);
        input.select();
        var result = document.execCommand('copy');
        document.body.removeChild(input);
        return result;
    }

    return(
        <>
        <Helmet>
            <title>{page_seo_data.title != '' ? page_seo_data.title : default_page_seo_data.title}</title>
            <meta name="description" content={page_seo_data.desc != '' ? page_seo_data.desc : default_page_seo_data.desc} />
        </Helmet>
        <div className="pricing_page">
            <PageLoader page_loaded={page_loaded} />
            <div ref={myRef} className="banner_wrap pricinig_banner_wrap">
                <div className="bg_slider_wrap">
                    {
                        sliderImages !== undefined && sliderImages.length >0
                        ?
                            sliderImages.map((banner_slider_images) => {
                                sliderImagesKey = sliderImagesKey + 1;
                                let new_banner_images = [];
                                let new_banner_single_images = [];
                                let is_bg_slider_rtl = sliderImagesKey % 2 == 0 ? 'bg_slider_rtl' : '';
                                let slider_settings = sliderImagesKey % 2 == 0 ? settingsTwo : settings;
                                let minimum_images = 10;
                                if(banner_slider_images.length < minimum_images)
                                {
                                    let total_loops = Math.ceil(minimum_images / banner_slider_images.length)
                                    for(let k = 1;k<=total_loops;k++)
                                    {
                                        new_banner_images = [...new_banner_images,banner_slider_images];
                                    }
                                    if(new_banner_images.length > 0)
                                    {
                                        new_banner_images.forEach((new_banner_val) => {
                                            if(new_banner_val.length > 0)
                                            {
                                                new_banner_val.forEach((inner_single_img) => {
                                                    if(inner_single_img.thumbnails !== undefined && inner_single_img.thumbnails.large !== undefined)
                                                    {
                                                        new_banner_single_images.push(inner_single_img);
                                                    }
                                                })
                                            }
                                        })
                                    }
                                }

                                return <Slider {...slider_settings} className={`slider${sliderImagesKey} bg_slider_row bg_slider ${is_bg_slider_rtl}`} key={sliderImagesKey}>
                                            {
                                                new_banner_single_images.map((final_single_img) => {

                                                   let slide_img = final_single_img.thumbnails !== undefined && final_single_img.thumbnails.large !== undefined ? final_single_img.thumbnails.large.url : final_single_img.url;
                                                   let slide_alt = final_single_img.thumbnails !== undefined && final_single_img.thumbnails.large !== undefined ? final_single_img.thumbnails.large.filename : final_single_img.filename;


                                                    slider_single_key = slider_single_key + 1;
                                                    return  <div className="slide" key={slider_single_key}>
                                                                <img src={slide_img} alt={slide_alt} />
                                                            </div>
                                                })
                                            }
                                        </Slider>
                            })
                        : null
                    }
                </div>{/* banner slider end*/}
                <div className="banner_content pricing_banner_content">
                    <div className="container">
                        {banner_data.Title !== undefined ? <h1 className="hero_title" dangerouslySetInnerHTML={{__html: banner_data.Title}}></h1> : null }
                        {banner_data.Description !== undefined ? <p className="fs-lg hero_text text-white" dangerouslySetInnerHTML={{__html: banner_data.Description}}></p> : null }
                    </div>
                    <div id="price" className="price_wrap">
                        <div className="price_wrap_row">
                            {
                                price_boxes.prices !== undefined && price_boxes.prices.length > 0
                                ?

                                    price_boxes.prices.map((price_values) => {
                                        price_key = price_key + 1;
                                        let price = price_values.fields.Price !== undefined ? price_values.fields.Price : 0;
                                        let ButtonText = price_values.fields.ButtonText !== undefined ? price_values.fields.ButtonText : 'DONATE';
                                        let Title = price_values.fields.Title !== undefined ? price_values.fields.Title : '';
                                        let PriceText = price_values.fields.PriceText !== undefined ? price_values.fields.PriceText : '';
                                        let ButtonBottomText = price_values.fields.ButtonBottomText !== undefined ? price_values.fields.ButtonBottomText : '';
                                        let Image = price_values.fields.Image !== undefined && price_values.fields.Image.length>0 ? price_values.fields.Image[0].url : '';
                                        return  <div className="price_block" key={price_key}>
                                                    {Title != '' ? <h3>{Title}</h3> : null }
                                                    <div className="price">
                                                        {PriceText != '' ? <div dangerouslySetInnerHTML={{__html: PriceText}}></div> : null }
                                                        {price !=0 ? <span>{price}</span> : null }
                                                    </div>
                                                    {price !=0 && ButtonText != '' ? <Link to="#" className="btn donate_btn">{ButtonText}</Link> : null }
                                                    {
                                                        price == 0 ?
                                                            <form action="" method="post">
                                                                <div className="custom_input">
                                                                    <div className="custom_input_icon">
                                                                        <img src={Dollersign} alt="Doller sign" />
                                                                    </div>
                                                                    <input type="text" name="amount field" placeholder="maybe $44?" />
                                                                </div>
                                                                <button type="submit" className="btn donate_btn">{ButtonText}</button>
                                                            </form>
                                                        : null
                                                    }
                                                    {
                                                        ButtonBottomText != ''
                                                        ?
                                                            <div className="price_block_text">
                                                                <p>{ButtonBottomText}</p>
                                                            </div>
                                                        : null
                                                    }

                                                    <div className="stripe_logo">
                                                        {Image != '' ? <img src={Image} alt="Stripe" /> : null }
                                                    </div>
                                                </div>
                                    })


                                : null
                            }


                        </div>
                    </div>
                </div>
                {/*banner content end*/}
            </div>{/* banner_wrap */}

            <div className="knowledge_wrap">
                <div className="container">
                    {banner_data.TitleTwo !== undefined ? <h3 dangerouslySetInnerHTML={{__html: banner_data.TitleTwo}}></h3> : null }
                    {banner_data.DescriptionTwo !== undefined ? <p dangerouslySetInnerHTML={{__html: banner_data.DescriptionTwo}}></p> : null }
                </div>
                <TabSection apiKey={props.apiKey} baseKey={props.baseKey} />
            </div>
            <div className="banner_wrap faq_wrap">
                <div className="faq_wrap_top_overlay"></div>
                <div className="bg_slider_wrap">
                    {
                        sliderImages !== undefined && sliderImages.length >0
                        ?
                            sliderImages.map((banner_slider_images) => {
                                sliderImagesKey = sliderImagesKey + 1;
                                let new_banner_images = [];
                                let new_banner_single_images = [];
                                let is_bg_slider_rtl = sliderImagesKey % 2 == 0 ? 'bg_slider_rtl' : '';
                                let slider_settings = sliderImagesKey % 2 == 0 ? settingsTwo : settings;
                                let minimum_images = 10;
                                if(banner_slider_images.length < minimum_images)
                                {
                                    let total_loops = Math.ceil(minimum_images / banner_slider_images.length)
                                    for(let k = 1;k<=total_loops;k++)
                                    {
                                        new_banner_images = [...new_banner_images,banner_slider_images];
                                    }
                                    if(new_banner_images.length > 0)
                                    {
                                        new_banner_images.forEach((new_banner_val) => {
                                            if(new_banner_val.length > 0)
                                            {
                                                new_banner_val.forEach((inner_single_img) => {
                                                    new_banner_single_images.push(inner_single_img);
                                                })
                                            }
                                        })
                                    }
                                }

                                return <Slider {...slider_settings} className={`slider${sliderImagesKey} bg_slider_row bg_slider ${is_bg_slider_rtl}`} key={sliderImagesKey}>
                                            {
                                                new_banner_single_images.map((final_single_img) => {

                                                    slider_single_key = slider_single_key + 1;
                                                    let slide_img = final_single_img.thumbnails !== undefined && final_single_img.thumbnails.large !== undefined ? final_single_img.thumbnails.large.url : final_single_img.url;
                                                    let slide_alt = final_single_img.thumbnails !== undefined && final_single_img.thumbnails.large !== undefined ? final_single_img.thumbnails.large.filename : final_single_img.filename;

                                                    return  <div className="slide" key={slider_single_key}>
                                                                <img src={slide_img} alt={slide_alt} />
                                                            </div>
                                                })
                                            }
                                        </Slider>
                            })
                        : null
                    }
                </div>{/* banner slider end*/}
                {
                    faqs !== undefined && faqs.length > 0
                    ?
                        <div className="container">
                            <div className="banner_content faq_banner_content">
                                <div className="faqs">
                                    <Accordion defaultActiveKey={faq_index+1} flush>
                                        {
                                            faqs.map((faq_value) => {
                                                faq_index = faq_index + 1
                                                return <Accordion.Item key={`event_`+faq_index} eventKey={faq_index}>
                                                            <Accordion.Header><span>{faq_value.fields.Question}</span></Accordion.Header>
                                                            <Accordion.Body dangerouslySetInnerHTML={{__html: faq_value.fields.Answer}}></Accordion.Body>
                                                        </Accordion.Item>
                                            })
                                        }

                                    </Accordion>
                                </div>
                                <div className="faq_title d-none d-md-block">
                                    {faq_section !== undefined && faq_section.Title !== undefined ? <h2 className="text-white">{faq_section.Title}</h2> : null }
                                </div>
                            </div>
                        </div>
                    : null
                }

                {/*banner content end*/}
            </div>{/* banner_wrap */}
            <div className="faq_section">
                <div className="contaner">
                    <div className="faq_title d-md-none">
                        {faq_section !== undefined &&  faq_section.Title !== undefined ? <h2 className="text-white">{faq_section.Title}</h2> : null }
                    </div>
                    {
                        faq_section !== undefined &&  faq_section.Description !== undefined ?
                            <div className="faq_content" dangerouslySetInnerHTML={{__html: faq_section.Description}}>
                            </div>
                        : null
                    }

                </div>
            </div>
            <div className="kickstart_wrap">
                <div className="container">
                    <div className="kickstart_content">
                        {
                            faq_section !== undefined && faq_section.ButtonText !== undefined
                            ?
                                <Link  onClick={() => faq_section.ButtonURL == undefined ? window.scrollTo({ behavior: 'smooth', top: myRef.current.offsetTop }) : '' } to={faq_section.ButtonURL !== undefined ? faq_section.ButtonURL : '#price'} className="btn btn-info">
                                    <img src={DollerHeart} alt="DollerImage" className="me-2"/>
                                    <span>{faq_section.ButtonText}</span>
                                    </Link>
                            : null

                        }

                        {
                            faq_section !== undefined && faq_section.Image !== undefined && faq_section.Image.length > 0
                            ?
                                <img src={faq_section.Image[0].url} alt="Stripe logo" />
                            : null
                        }

                        {
                            faq_section !== undefined && faq_section.TitleTwo !== undefined
                            ?
                                <p>{faq_section.TitleTwo}</p>
                            : null
                        }

                    </div>
                </div>

            </div>
        </div>


        </>
    )
}
export default Pricing;