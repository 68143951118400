import React,{useState, useEffect, useRef} from "react";
import { Link } from "react-router-dom";
import { Button, Spinner, Tab, Nav, ListGroup, Accordion } from "react-bootstrap";
import Dots from "./assets/img/dots.png";
import DotsMobile from "./assets/img/dots-mb.png";
import infoicon from "./assets/img/infoicon.png";
import GPayIcon from "./assets/img/g-pay-ic.svg";
import VbyVisaIcon from "./assets/img/verifie-bt-visa-ic.svg";
import VisaIcon from "./assets/img/visa-ic.svg";
import MasterCardIcon from "./assets/img/mastercard-ic.svg";
import ApplePayIcon from "./assets/img/apple-pay-ic.svg";
import DollerHeart from "./assets/img/doller-heart.png";
import AirTabel from "./assets/img/ai-airtabel.png";
import Airtable from "airtable";
import PageLoader from "./components/PageLoader";
import PeopleShareModal from './components/people-share-modal';
import {Helmet} from "react-helmet";
import Slider from "react-slick";
import {
    FlashIcon,
    ListingIcon,
} from "./assets/icons";

const YourFeed = (props) => {

    useEffect(() => {
        // console.log('props',props);
        let seo_default_Data = props.get_seo_data_of_page('Default');
        let seo_page_Data = props.get_seo_data_of_page('Feed');
        set_default_page_seo_data(seo_default_Data);
        set_page_seo_title(seo_page_Data);
    }, []);

    var ShareSlider = {
        infinite: false,
        slidesToShow: 4,
        slidesToScroll: 1,
        arrows: false,
        dots:false,
    }

    function copy(text) {
        var input = document.createElement('input');
        input.setAttribute('value', text);
        document.body.appendChild(input);
        input.select();
        var result = document.execCommand('copy');
        document.body.removeChild(input);
        return result;
     }
    const [sliderImages,setSliderImages] = useState([]);
    const [share_popup_wrap,set_share_popup_wrap] = useState(false);
    const [all_person_quotes,set_all_person_quotes] = useState([]);
    const [all_quotes,set_all_quotes] = useState({'all_data':[]});
    const [all_persons,set_all_persons] = useState([]);
    const [total_pages,set_total_pages] = useState(0);
    const [current_page,set_current_page] = useState(0);
    const [current_display_quotes,set_current_display_quotes] = useState([]);
    const [people_popup_data,set_people_popup_data] = useState({username:'',user_profile_link:'',slug:''});
    const [whatsapp_link,set_whatsapp_link] = useState('');
    const [page_loaded,set_page_loaded] = useState(false);
    const [loading,set_loading] = useState(false);
    const [open_people_popup,set_open_people_popup] = useState(false);
    const set_open_people_popup_delay = 100;
    const [page_seo_data,set_page_seo_title] = useState({title:'',desc:''});
    const [default_page_seo_data,set_default_page_seo_data] = useState({title:'YourFeed',desc:'YourFeed'});
    const [faqs,set_faqs] = useState([]);
    const [faq_section,set_faq_section] = useState([]);
    const [bg_slider_class,set_bg_slider_class] = useState('bg_slider_wrap');
    const [beta_release_progress_class,set_beta_release_progress_class] = useState('beta_release_progress');
    const [release_progress_class,set_release_progress_class] = useState('beta_release_progress_inner position-relative');
    const [profile_dots_class,set_profile_dots_class] = useState('profile_dots d-none');
    const [profile_chat_class,set_profile_chat_class] = useState('profile_chat d-none');
    const [profile_chat_btn_class,set_profile_chat_btn_class] = useState('d-none');
    const [feed_animation,set_feed_animation] = useState(undefined);
    let feeds_key = 0;
    let faq_index = 0;
    let sliderImagesKey = 0;
    let slider_single_key = 0;

    let myRef = useRef();

    useEffect(() => {
        // console.log('sliderImages',sliderImages);
    },[sliderImages]);

    useEffect(() => {

        if(
            sliderImages !== undefined && sliderImages.length > 0
            // && price_boxes !== undefined && price_boxes.prices !== undefined && price_boxes.prices.length > 0
            // && faqs !== undefined  && faqs.length > 0
            // && faqs !== undefined  && Object.keys(faq_section).length > 0
        )
        {
            set_page_loaded(true)
        }
    }, [sliderImages])

    async function get_slider_images()
    {
        var base = new Airtable({apiKey: props.apiKey}).base(props.baseKey);
        let all_slider_images = [];
        await base('images').select({
            view: "Grid view"
        }).eachPage(function page(records, fetchNextPage) {
            records.forEach(function(record) {
                all_slider_images = [...all_slider_images,record.get('heroImage')];
                return all_slider_images;
            });
            if(all_slider_images.length > 0)
            {
                setSliderImages(all_slider_images);
            }
            fetchNextPage();


        }, function done(err) {
            if (err) { console.error(err); return; }
        });
    }
    useEffect(() => {
        let slider_images = get_slider_images();
    }, []);

    useEffect(() => {
    }, [page_seo_data])


    useEffect(() => {
        if(all_quotes.my_quotes !== undefined)
        {
        }
        if(
            all_person_quotes !== undefined && all_person_quotes.length > 0
            // && all_quotes !== undefined && Object.keys(all_quotes).length > 0
            && all_persons !== undefined && Object.keys(all_persons).length > 0
        )
        {
            setTimeout(function(){
                set_page_loaded(true);
            },500);
        }
    }, [all_person_quotes,all_quotes,all_persons]);

    function shuffle(array) {
        let currentIndex = array.length,  randomIndex;

        // While there remain elements to shuffle...
        while (currentIndex != 0) {

            // Pick a remaining element...
            randomIndex = Math.floor(Math.random() * currentIndex);
            currentIndex--;

            // And swap it with the current element.
            [array[currentIndex], array[randomIndex]] = [
            array[randomIndex], array[currentIndex]];
        }

        return array;
    }

    function add_more_quotes(page_id)
    {
        set_loading(true);
        let page_all_data = current_display_quotes;
        let quote_page_data = all_person_quotes[page_id];
        setTimeout(function(){
            if(page_id >= 0 && quote_page_data !== undefined && quote_page_data.length > 0)
            {
                let quote_page_data = all_person_quotes[page_id];
                if(quote_page_data !== undefined && quote_page_data.length > 0)
                {
                    if(page_id == 0)
                    {
                        page_all_data = [...page_all_data,...quote_page_data];
                    }
                    else
                    {
                        page_all_data = [...page_all_data[0],...quote_page_data];
                    }

                }
                set_current_page(page_id);
                set_current_display_quotes([page_all_data]);
                get_quotes(quote_page_data);
                set_loading(false);
            }
        },500);


    }

    useEffect(() => {
        console.log('current_display_quotes',current_display_quotes);
        setTimeout(function(){
            var nodes = document.querySelectorAll('.card_wraper.d-none.d-md-block .card_block');
            console.log(nodes);
            var last = nodes[nodes.length- 1];
            console.log(last);
            // last.scrollIntoView();

        },1000);

    }, [current_display_quotes])



    function shuffle(array) {
        let currentIndex = array.length,  randomIndex;

        // While there remain elements to shuffle...
        while (currentIndex != 0) {

          // Pick a remaining element...
          randomIndex = Math.floor(Math.random() * currentIndex);
          currentIndex--;

          // And swap it with the current element.
          [array[currentIndex], array[randomIndex]] = [
            array[randomIndex], array[currentIndex]];
        }

        return array;
      }

    useEffect(() => {
    }, [current_display_quotes])

    useEffect(() => {
        set_total_pages(all_person_quotes.length);
     }, [all_person_quotes])

    useEffect(() => {

        if(total_pages > 0)
        {
            set_current_page(0);
            add_more_quotes(0);
        }
     }, [total_pages])

    useEffect(() => {
     }, [all_quotes])

    useEffect(() => {
     }, [all_persons])

    function chunks(arr, size = 2) {
        return arr.map((x, i) => i % size == 0 && arr.slice(i, i + size)).filter(x => x)
    }

    async function get_feed()
    {
        var base = new Airtable({apiKey: props.apiKey}).base(props.baseKey);
        let all_slider_images = [];
        await base('persons').select({
            // Selecting the first 3 records in Grid view:
            view: "Grid view"
        }).all().then(records => {
            let persons = records;
            // console.log('persons',persons);
            let all_persons_details = [];
            if(persons.length > 0)
            {

                let all_quotes = [];
                persons.forEach((person_val,person_key) => {
                    all_persons_details[person_val.id] = person_val.fields
                    let person_quotes = person_val.fields.quotes;
                    if(person_quotes !== undefined)
                    {
                        person_quotes.forEach((person_quotes_val) => {
                            all_quotes.push(person_quotes_val);
                        })
                    }
                });
                // console.log('all_quotes',all_quotes);
                set_all_persons(all_persons_details);
                set_all_person_quotes( chunks( shuffle(all_quotes),15) );
            }

        }).catch(err => {
            console.error(err);
        });
    }

    async function get_single_quote_data(quote_id)
    {
        var base = new Airtable({apiKey: props.apiKey}).base(props.baseKey);
        let all_quotes_with_id = all_quotes.all_data;
        await base('quotes').find(quote_id, function(err, record) {
            let quotes = record;
            if(quotes.fields !== undefined)
            {
                all_quotes_with_id[quotes.id] = quotes.fields;
            }
            set_all_quotes({'all_data':all_quotes_with_id});
        });

    }

    async function get_quotes(quote_page_data)
    {
        if(quote_page_data !== undefined && quote_page_data.length > 0)
        {
            quote_page_data.forEach((quote_id,quote_val) => {

                let all_slider_images = [];
                get_single_quote_data(quote_id);
            })
        }
    }

    async function get_persons()
    {
        var base = new Airtable({apiKey: props.apiKey}).base(props.baseKey);
        let all_slider_images = [];
        await base('persons').select({
            // Selecting the first 3 records in Grid view:
            view: "Grid view"
        }).all().then(records => {

            let persons = records;
            set_all_persons(persons);

        }).catch(err => {
            console.error(err);
        });
    }

    useEffect(() => {
        let feed = get_feed();
        // let quotes = get_quotes();
        set_whatsapp_link('https://web.whatsapp.com/send?text='+window.location.href);
    }, [props]);

    function copy(text) {
        var input = document.createElement('input');
        input.setAttribute('value', text);
        document.body.appendChild(input);
        input.select();
        var result = document.execCommand('copy');
        document.body.removeChild(input);
        return result;
     }

    async function get_faqs()
    {
        var base = new Airtable({apiKey: props.apiKey }).base(props.baseKey);
        let all_slider_images = [];
        await base('FAQs').select({
            view: "Grid view",
        }).all().then(records => {
            if(records.length > 0)
            {
                set_faqs(records);
            }

        }).catch(err => {
        });
    }
    async function get_faq_section()
    {
        var base = new Airtable({apiKey: props.apiKey }).base(props.baseKey);
        let all_slider_images = [];
        await base('Pricing').select({
            view: "Grid view",
            filterByFormula:`(LOWER({Name}) = 'faq_section')`
        }).all().then(records => {
            if(records.length > 0)
            {
                set_faq_section(records[0].fields);
            }

        }).catch(err => {
        });
    }
    useEffect(() => {
        let faqs = get_faqs();
        let faq_Section = get_faq_section();
    }, []);

    var settings = {
        infinite: true,
        slidesToShow: 9,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 100,
        cssEase: 'linear',
        speed: 12000,
        arrows: false,
        responsive: [
            {
                breakpoint: 1800,
                settings: {
                    slidesToShow: 8,
                }
            },
            {
                breakpoint: 1600,
                settings: {
                    slidesToShow: 7,
                }
            },
            {
                breakpoint: 1200,
                settings: {
                    slidesToShow: 6,
                }
            },
            {
                breakpoint: 992,
                settings: {
                    slidesToShow: 5,
                }
            },
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 4,
                }
            },
            {
                breakpoint: 425,
                settings: {
                    slidesToShow: 2,
                }
            }
        ]

    };
    var settingsTwo = {
        rtl: true,
        infinite: true,
        slidesToShow: 9,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 100,
        cssEase: 'linear',
        speed: 12000,
        arrows: false,
        responsive: [
            {
                breakpoint: 1800,
                settings: {
                    slidesToShow: 8,
                }
            },
            {
                breakpoint: 1600,
                settings: {
                    slidesToShow: 7,
                }
            },
            {
                breakpoint: 1200,
                settings: {
                    slidesToShow: 6,
                }
            },
            {
                breakpoint: 992,
                settings: {
                    slidesToShow: 5,
                }
            },
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 4,
                }
            },
            {
                breakpoint: 425,
                settings: {
                    slidesToShow: 2,
                }
            }
        ]
    }

    function start_feed_animation()
    {
        setTimeout(function(){
            set_release_progress_class('beta_release_progress_inner position-relative d-none');
            set_profile_dots_class('profile_dots');
        },3000);

        setTimeout(function(){
            set_profile_dots_class('profile_dots d-none');
            set_profile_chat_class('profile_chat');
        },6000);

        setTimeout(function(){
            set_profile_chat_btn_class('');
        },9000);

        // setTimeout(function(){
        //     set_beta_release_progress_class('beta_release_progress d-none');
        // },20000);
    }

    return(
        <>
        <Helmet>
            <title>{page_seo_data.title != '' ? page_seo_data.title : default_page_seo_data.title}</title>
            <meta name="description" content={page_seo_data.desc != '' ? page_seo_data.desc : default_page_seo_data.desc} />
        </Helmet>
        <PageLoader page_loaded={page_loaded} />
        {/* total_pages length = {total_pages} */}
        {/* current_page = {current_page} */}
        {/* current_display_quotes = {current_display_quotes[0] !== undefined ? current_display_quotes[0].length : 0} */}
        <div className="feed_page position-relative">
        <div className={bg_slider_class}>
            {
                sliderImages !== undefined && sliderImages.length >0
                ?
                    sliderImages.map((banner_slider_images) => {
                        sliderImagesKey = sliderImagesKey + 1;
                        let new_banner_images = [];
                        let new_banner_single_images = [];
                        let is_bg_slider_rtl = sliderImagesKey % 2 == 0 ? 'bg_slider_rtl' : '';
                        let slider_settings = sliderImagesKey % 2 == 0 ? settingsTwo : settings;
                        let minimum_images = 10;
                        if(banner_slider_images.length < minimum_images)
                        {
                            let total_loops = Math.ceil(minimum_images / banner_slider_images.length)
                            for(let k = 1;k<=total_loops;k++)
                            {
                                new_banner_images = [...new_banner_images,banner_slider_images];
                            }
                            if(new_banner_images.length > 0)
                            {
                                new_banner_images.forEach((new_banner_val) => {
                                    if(new_banner_val.length > 0)
                                    {
                                        new_banner_val.forEach((inner_single_img) => {
                                            new_banner_single_images.push(inner_single_img);
                                        })
                                    }
                                })
                            }
                        }

                        return <Slider {...slider_settings} className={`slider${sliderImagesKey} bg_slider_row bg_slider ${is_bg_slider_rtl}`} key={sliderImagesKey}>
                                    {
                                        new_banner_single_images.map((final_single_img) => {

                                            slider_single_key = slider_single_key + 1;
                                            let slide_img = final_single_img.thumbnails !== undefined && final_single_img.thumbnails.large !== undefined ? final_single_img.thumbnails.large.url : final_single_img.url;
                                            let slide_alt = final_single_img.thumbnails !== undefined && final_single_img.thumbnails.large !== undefined ? final_single_img.thumbnails.large.filename : final_single_img.filename;

                                            return  <div className="slide" key={slider_single_key}>
                                                        <img src={slide_img} alt={slide_alt} />
                                                    </div>
                                        })
                                    }
                                </Slider>
                    })
                : null
            }
        </div>
            <div className="container">
                <div className="feed_tabs_wrapper">
                    <Tab.Container id="feed-tabs" defaultActiveKey="your-feed">
                        <Nav className="feed_tabs justify-content-between">
                            <Nav.Item>
                                <Nav.Link eventKey="your-feed" onClick={() => {set_bg_slider_class('bg_slider_wrap');}}><FlashIcon fill="#ffffff" /> Your feed</Nav.Link>
                            </Nav.Item>
                            <Nav.Item>
                                <Nav.Link eventKey="beta-release" onClick={() => {set_bg_slider_class('bg_slider_wrap inactive');console.log('clicked on beta release...');start_feed_animation();}}><ListingIcon /> Beta release</Nav.Link>
                            </Nav.Item>
                        </Nav>
                        <Tab.Content>
                                <Tab.Pane eventKey="your-feed">
                                    <div className="your_feed_tab_block">
                                        <h1 className="text-center">WE ARE JUST GETTING STARTED</h1>
                                        <p className="text-center">and humbly ask your help</p>
                                        <div className="mental_footer_box">
                                            <div className="mental_footer_box_inner">
                                                <div className="d-flex align-items-center info-box">
                                                    <img src={infoicon} alt="Info Icon" />
                                                    <p className="mb-0">We are still working on this section of the site and this is the main section. Meanwhile, you can sign up for <strong>early access</strong> or become yourfeed.ai "<strong>founding member</strong>".</p>
                                                </div>
                                                <div className="mental_footer_btns_wrap text-center">
                                                    <Link to="/your-future-account" className="btn form_btn">Get early access</Link>
                                                    <Link to="/pricing" className="btn form_btn">Go back to beta release</Link>
                                                    <Link to="/pricing" className="btn form_btn">Become a founding member</Link>
                                                </div>
                                                <div className="payment-cards text-center">
                                                    <ListGroup as="ul" horizontal className="fund_payment_type pt-0">
                                                        <ListGroup.Item as="li" className="bg-transparent"><img src={GPayIcon} alt="GPayIcon" /></ListGroup.Item>
                                                        <ListGroup.Item as="li" className="bg-transparent"><img src={VbyVisaIcon} alt="VbyVisaIcon" /></ListGroup.Item>
                                                        <ListGroup.Item as="li" className="bg-transparent"><img src={VisaIcon} alt="VisaIcon" /></ListGroup.Item>
                                                        <ListGroup.Item as="li" className="bg-transparent"><img src={MasterCardIcon} alt="MasterCardIcon" /></ListGroup.Item>
                                                        <ListGroup.Item as="li" className="bg-transparent"><img src={ApplePayIcon} alt="ApplePayIcon" /></ListGroup.Item>
                                                    </ListGroup>
                                                    <p>because your support is so valuable</p>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="knowledge_wrap">
                                            <h3 className="d-none d-md-block">What is yourfeed.ai?</h3>
                                            <p>Think of yourfeed.ai as a <span className="text-secondary">"bicycle for the mind"</span>. Blend of software and timeless knowledge from a wide range of disciplines helping you master the best what other people are already figured out. <strong>Helping you think better, perform better, and live a better life.</strong></p>
                                        </div>
                                        <div className="your_feed_faqs_wrapper">
                                            {
                                                faqs !== undefined && faqs.length > 0
                                                ?
                                                <div className="faqs">
                                                    <Accordion defaultActiveKey={faq_index+1} flush>
                                                        {
                                                            faqs.map((faq_value) => {
                                                                faq_index = faq_index + 1
                                                                return <Accordion.Item key={`event_`+faq_index} eventKey={faq_index}>
                                                                            <Accordion.Header><span>{faq_value.fields.Question}</span></Accordion.Header>
                                                                            <Accordion.Body dangerouslySetInnerHTML={{__html: faq_value.fields.Answer}}></Accordion.Body>
                                                                        </Accordion.Item>
                                                            })
                                                        }

                                                    </Accordion>
                                                </div>
                                                : null
                                            }
                                        </div>
                                        <div className="your_explore_today_block">
                                            <div className="your_explore_today_title">
                                                {faq_section !== undefined &&  faq_section.Title !== undefined ? <h2 className="text-white">{faq_section.Title}</h2> : null }
                                            </div>
                                            {
                                                faq_section !== undefined &&  faq_section.Description !== undefined ?
                                                    <div className="your_explore_today_content" dangerouslySetInnerHTML={{__html: faq_section.Description}}>
                                                    </div>
                                                : null
                                            }
                                            <div className="explore_cta_payment_method">
                                                {
                                                    faq_section !== undefined && faq_section.ButtonText !== undefined
                                                    ?
                                                        <Link to="/pricing" className="btn btn-info">
                                                            <img src={DollerHeart} alt="DollerImage" />
                                                            <span>{faq_section.ButtonText}</span>
                                                            </Link>
                                                    : null

                                                }

                                                {
                                                    faq_section !== undefined && faq_section.Image !== undefined && faq_section.Image.length > 0
                                                    ?
                                                        <img src={faq_section.Image[0].url} alt="Stripe logo" />
                                                    : null
                                                }

                                                {
                                                    faq_section !== undefined && faq_section.TitleTwo !== undefined
                                                    ?
                                                        <p>{faq_section.TitleTwo}</p>
                                                    : null
                                                }
                                            </div>
                                        </div>
                                    </div>
                                </Tab.Pane>
                                <Tab.Pane eventKey="beta-release">
                                    {
                                        feed_animation === undefined
                                        ?
                                            <div className="beta_release_tab_block">
                                                <div className={beta_release_progress_class}>
                                                    <div className={release_progress_class}>
                                                        <span className="progress_circle"></span>
                                                    </div>
                                                    <div className={profile_dots_class}>
                                                        <img src={AirTabel} alt="AirTabel" />
                                                        <span className="profile_dots_wrap">
                                                            <span className="profile_dot"></span>
                                                            <span className="profile_dot"></span>
                                                            <span className="profile_dot"></span>
                                                        </span>
                                                    </div>
                                                    <div className={profile_chat_class}>
                                                        <img src={AirTabel} alt="AirTabel" />
                                                        <p className="mb-0">Hey I'm Morgan. Your AI. Have a look around.</p>
                                                        <Button onClick={() => {
                                                            set_beta_release_progress_class('beta_release_progress d-none');
                                                        }} className={profile_chat_btn_class}>Hide</Button>
                                                    </div>
                                                </div>
                                                {
                                                    current_display_quotes !== undefined && current_display_quotes[0] !== undefined && current_display_quotes[0].length > 0
                                                    ?
                                                        <>
                                                            <div className="card_wraper d-none d-md-block">
                                                                {
                                                                    current_display_quotes[0].map((feed_id) => {
                                                                        feeds_key = feeds_key+1;
                                                                        let feed_id_value = undefined;
                                                                        console.log('all_quotes',all_quotes);
                                                                        // console.log('feed_id',feed_id);
                                                                        if(all_quotes.all_data !== undefined)
                                                                        {
                                                                            feed_id_value = all_quotes.all_data[feed_id];
                                                                        }


                                                                        if(feed_id_value !== undefined && feed_id_value["RemainingChar"] !== undefined && feed_id_value["RemainingChar"] >= 0)
                                                                        {
                                                                            // console.log('feed_id_value',feed_id_value["Remaining Char."]);
                                                                            // console.log('feed_id_value',feed_id_value.author[0]);
                                                                            let quote_id = feed_id;
                                                                            let quote_text = feed_id_value.text;
                                                                            let quote_author_id = feed_id_value.author !== undefined && feed_id_value.author.length > 0 ? feed_id_value.author[0] : '';
                                                                            let author_details = all_persons[quote_author_id];
                                                                            if(author_details !== undefined)
                                                                            {
                                                                                let author_name = author_details.name;
                                                                                let author_role = author_details.role;

                                                                                let author_slug = author_name.replace(/[&\/\\#,+()$~%.'":*?<>{}]/g,'');
                                                                                author_slug  = author_slug.toLowerCase().split(' ').join('-');
                                                                                let author_photo  = author_details.photo[0] !== undefined && author_details.photo[0].thumbnails !== undefined && author_details.photo[0].thumbnails.large !== undefined ? author_details.photo[0].thumbnails.large.url : author_details.photo[0].url;
                                                                                let author_photo_extension_type = author_details.photo[0] !== undefined && author_details.photo[0].thumbnails !== undefined && author_details.photo[0].thumbnails.large !== undefined ? author_details.photo[0].thumbnails.large.type : author_details.photo[0].type;
                                                                                let author_photo_extension = author_photo_extension_type !== undefined ? author_photo_extension_type.split('/').reverse()[0] : '';

                                                                                return <div className="card_block" key={`desktop_`+feeds_key}>
                                                                                            <div className="card_block_img flex-shrink-0">
                                                                                            {
                                                                                                    author_photo_extension == 'mp4'
                                                                                                    ?
                                                                                                        <>
                                                                                                            <video width="130px" height="100%" className="" src={author_photo} autoPlay muted loop controls={false}></video>
                                                                                                        </>

                                                                                                    :
                                                                                                        <img src={author_photo} alt={author_name} />
                                                                                                }
                                                                                            </div>
                                                                                            <div className="card_block_right">
                                                                                                <div className="card_block_author">
                                                                                                    <div>
                                                                                                        <Link to={`/discover/people/${author_slug}`}><h4>{author_name}</h4></Link>
                                                                                                        <div className="fs-sm">{author_role}</div>
                                                                                                    </div>
                                                                                                    <span onClick={() => {
                                                                                                        setTimeout(function(){
                                                                                                            // set_open_people_popup(true);
                                                                                                            // set_people_popup_data({username:author_name,user_profile_link:'discover/people/'+author_slug,slug:author_slug,quote_id:feed_id_value.author[0]});
                                                                                                            set_open_people_popup(true);
                                                                                                            set_people_popup_data({username:author_name,user_profile_link:'discover/people/profile/quotes/'+quote_id,slug:author_slug,quote_id:quote_id})
                                                                                                        },set_open_people_popup_delay);

                                                                                                    }}><img src={Dots} alt="dots" /></span>
                                                                                                </div>
                                                                                                <div className="card_block_content">
                                                                                                    {quote_text}
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                            }
                                                                        }
                                                                    })
                                                                }
                                                                {
                                                                    current_page < total_pages - 1
                                                                    ?

                                                                        <div className="btn_wrap text-center">
                                                                            <Button className="btn more_btn" onClick={() => {
                                                                                    add_more_quotes(current_page+1)
                                                                                }}><FlashIcon /> More
                                                                                {
                                                                                    loading === true
                                                                                    ?
                                                                                        <Spinner as="span" animation="border" className="ms-2" size="sm" role="status" aria-hidden="true">
                                                                                            <span className="visually-hidden">Loading...</span>
                                                                                        </Spinner>
                                                                                    : null
                                                                                }
                                                                            </Button>
                                                                        </div>
                                                                    : null
                                                                }
                                                            </div>

                                                            <div className="card_wraper d-md-none">
                                                                {
                                                                    current_display_quotes[0].map((feed_id) => {
                                                                        feeds_key = feeds_key + 1;
                                                                        let feed_id_value = undefined;
                                                                        if(all_quotes.all_data !== undefined)
                                                                        {
                                                                            feed_id_value = all_quotes.all_data[feed_id];
                                                                        }

                                                                        if( feed_id_value !== undefined && feed_id_value["RemainingChar"] !== undefined && feed_id_value["RemainingChar"] >= 0 )
                                                                        {
                                                                            let quote_text = feed_id_value.text;
                                                                            let quote_author_id = feed_id_value.author !== undefined && feed_id_value.author.length > 0 ? feed_id_value.author[0] : '';
                                                                            let author_details = all_persons[quote_author_id];
                                                                            if(author_details !== undefined)
                                                                            {
                                                                                let author_name = author_details.name;
                                                                                let author_role = author_details.role;
                                                                                let author_slug  = author_name.toLowerCase().split(' ').join('-');

                                                                                let author_photo  = author_details.photo[0] !== undefined && author_details.photo[0].thumbnails !== undefined && author_details.photo[0].thumbnails.large !== undefined ? author_details.photo[0].thumbnails.large.url : author_details.photo[0].url;
                                                                                let author_photo_extension_type = author_details.photo[0] !== undefined && author_details.photo[0].thumbnails !== undefined && author_details.photo[0].thumbnails.large !== undefined ? author_details.photo[0].thumbnails.large.type : author_details.photo[0].type;
                                                                                let author_photo_extension = author_photo_extension_type !== undefined ? author_photo_extension_type.split('/').reverse()[0] : '';

                                                                                return  <div className="card_block" key={`mobile_`+feeds_key}>
                                                                                            <div className="card_block_top">
                                                                                                <div className="card_block_img flex-shrink-0">
                                                                                                {
                                                                                                    author_photo_extension == 'mp4'
                                                                                                    ?
                                                                                                        <>
                                                                                                            <video width="70px" height="100%" className="" src={author_photo} autoPlay muted loop controls={false}></video>
                                                                                                        </>

                                                                                                    :
                                                                                                        <img src={author_photo} alt={author_name} />
                                                                                                }
                                                                                                </div>
                                                                                                <div className="card_block_author">
                                                                                                    <div>
                                                                                                        <Link to={`/discover/people/${author_slug}`}><h4>{author_name}</h4></Link>
                                                                                                        <div className="fs-sm">{author_role}</div>
                                                                                                    </div>
                                                                                                    <span onClick={() => {
                                                                                                        setTimeout(function(){
                                                                                                            set_open_people_popup(true);
                                                                                                            set_people_popup_data({username:author_name,user_profile_link:'discover/people/'+author_slug,slug:author_slug,quote_id:feed_id_value.author[0]})
                                                                                                        });

                                                                                                    }}><img src={DotsMobile} alt="dots" /></span>
                                                                                                </div>
                                                                                            </div>
                                                                                            <div className="card_block_content">
                                                                                                {quote_text}
                                                                                            </div>
                                                                                        </div>
                                                                            }
                                                                        }
                                                                    })
                                                                }


                                                                {
                                                                    current_page < total_pages - 1
                                                                    ?
                                                                        <div className="btn_wrap text-center">
                                                                            <Button className="btn more_btn" onClick={() => {
                                                                                add_more_quotes(current_page+1)
                                                                            }}><FlashIcon /> More
                                                                            {
                                                                                loading === true
                                                                                ?
                                                                                    <Spinner as="span" animation="border" className="ms-2" size="sm" role="status" aria-hidden="true">
                                                                                        <span className="visually-hidden">Loading...</span>
                                                                                    </Spinner>
                                                                                : null
                                                                            }
                                                                        </Button>
                                                                        </div>
                                                                    : null
                                                                }

                                                            </div>
                                                        </>
                                                    : null
                                                }
                                            </div>
                                        : null
                                    }
                                </Tab.Pane>
                            </Tab.Content>
                    </Tab.Container>
                </div>
            </div>
            <PeopleShareModal open_people_popup={open_people_popup} set_open_people_popup={set_open_people_popup} people_popup_data={people_popup_data} />

        </div>

        </>
    )
}

export default YourFeed;