import React,{useState,useEffect,useRef} from "react";
import { Tab, Nav, Button, Spinner, ListGroup, Toast } from "react-bootstrap";
import { Link } from "react-router-dom";
import Dots from "./assets/img/dots.png";
import LinkIcon from "./assets/img/link.png";
import Slider from "react-slick";
import HomeIcon from "./assets/img/home-icon.png";
import Airtable from "airtable";
import { useLocation } from 'react-router-dom';
import ShareIcon2 from "./assets/img/share-icon-3.png";
import BlobShape from "./assets/img/blob-shape.svg";
// import loaderLine from "./assets/img/loader-line.png";
// import loadercircle from "./assets/img/loader-circle.png";
import PeopleShareModal from './components/people-share-modal';
import PageLoader from "./components/PageLoader";
import ShareModal from './components/ShareModal';
import {Helmet} from "react-helmet";
import GPayIcon from "./assets/img/g-pay-ic.svg";
import VbyVisaIcon from "./assets/img/verifie-bt-visa-ic.svg";
import VisaIcon from "./assets/img/visa-ic.svg";
import MasterCardIcon from "./assets/img/mastercard-ic.svg";
import ApplePayIcon from "./assets/img/apple-pay-ic.svg";
import Dollersign from "./assets/img/doller-sign.png"
import Newsletter from "./components/Newsletter";

import {
    SharingIcon,
    FundIcon,
    LinkingIcon,
    RightAngleIcon,
    FaceBookIcon,
    TweetIcon,
    RedditIcon,
    LockIcon,
    FlashIcon
} from "./assets/icons";

const Profile = (props) => {

    useEffect(() => {
        let seo_default_Data = props.get_seo_data_of_page('Default');
        let seo_page_Data = props.get_seo_data_of_page('Profile');
        set_default_page_seo_data(seo_default_Data);
        set_page_seo_title(seo_page_Data);
        get_slider_images();
    }, []);

    async function get_slider_images()
    {
        var base = new Airtable({apiKey: props.apiKey}).base(props.baseKey);
        let all_slider_images = [];
        await base('images').select({
            view: "Grid view"
        }).eachPage(function page(records, fetchNextPage) {

            records.forEach(function(record) {
                all_slider_images = [...all_slider_images,record.get('heroImage')];
                return all_slider_images;
            });
            if(all_slider_images.length > 0)
            {
                setSliderImages(all_slider_images);
            }
            fetchNextPage();
        }, function done(err) {
            if (err) { console.error(err); return; }
        });
    }

    const location = useLocation();
    const [username,setUsername] = useState('');
    const [profilePhoto,setProfilePhoto] = useState('');
    const [profilePhotoExtension,setProfilePhotoExtension] = useState('');
    const [profileName,setProfileName] = useState('');
    const [profileDescription,setProfileDescription] = useState('');
    const [profileBookDescription,setProfileBookDescription] = useState('');
    const [profileQuotes,setProfileQuotes] = useState([]);
    const [similarPeople,setSimilarPeople] = useState([]);
    const [profileQuotesText,setProfileQuotesText] = useState([]);
    const [profileQuotesText2,setProfileQuotesText2] = useState([]);
    const [profileTotalQuotes,setProfileTotalQuotes] = useState(0);
    const [profileTotalQuotesPages,setProfileTotalQuotesPages] = useState(0);
    const [error,setError] = useState('');
    const [allPersons,setAllPersons] = useState([]);
    const per_page_quotes = 10;
    const [current_quote_page,set_current_quote_page] = useState(0);
    const [open_people_popup,set_open_people_popup] = useState(false);
    const [show_share_modal,set_show_share_modal] = useState(false);
    const [people_popup_data,set_people_popup_data] = useState({username:'',user_profile_link:'',slug:'',profile_page:true});
    const [current_url,set_current_url] = useState('');
    const [loading,set_loading] = useState(false);
    const [page_loaded,set_page_loaded] = useState(false);
    const [copy_tag_link_text,set_copy_tag_link_text] = useState('Copy Link');
    const set_open_people_popup_delay = 100;
    const [page_seo_data,set_page_seo_title] = useState({title:'',desc:''});
    const [default_page_seo_data,set_default_page_seo_data] = useState({title:'YourFeed',desc:'YourFeed'});
    const [fund_class,set_fund_class] = useState('fund_topic_block text-center');
    const [jdi_class,set_jdi_class] = useState('just_do_it_block');
    const [price,set_price] = useState('');
    const payBtn = useRef(null);
    const priceInput = useRef(null);
    const [showA, setShowA] = useState(false);
    const toggleShowA = () => setShowA(!showA);
    const [copy_link_text, set_copy_link_text] = useState('Copy Link');
    const [amount1_checked,set_amount1_checked] = useState("form-check-input");
    const [amount2_checked,set_amount2_checked] = useState("form-check-input");
    const [amount3_checked,set_amount3_checked] = useState("form-check-input");
    const [sliderImages,setSliderImages] = useState(undefined);
    const [sliderImagesOptimized,setSliderImagesOptimized] = useState(false);
    const [final_quotes,set_final_quotes] = useState([]);
    let quotes_key = 0;
    let quotes_mobile_key = 0;
    let sliderImagesKey = 0;
    let slider_single_key = 0;
    let [my_quotes,set_my_quotes] = useState([]);

    useEffect(() => {
        if(sliderImages !== undefined && sliderImages.length > 0 && sliderImagesOptimized === false)
        {
            update_slider_images();
        }
    },[sliderImages])

    function update_slider_images()
    {
        if(sliderImages.length > 0)
        {
            let final_slide_images = [];
            sliderImages.forEach(function(val,key){
                let imageobj = {};
                if(val.length > 0)
                {
                    final_slide_images = [...final_slide_images,[]];
                    val.forEach(function(inner_img){
                        let img_obj = {};
                        if(inner_img.thumbnails !== undefined && inner_img.thumbnails.large !== undefined)
                        {
                            img_obj.url = inner_img.thumbnails.large.url;
                            img_obj.alt = inner_img.filename;
                            final_slide_images[key].push(img_obj);
                        }
                    });
                }


            });
            setSliderImagesOptimized(true);
            setSliderImages(final_slide_images);
        }

    }

    useEffect(() => {
        if(price > 0)
        {
            payBtn.current.style.display = 'block';
            priceInput.current.value = price;
        }
        else
        {
            payBtn.current.style.display = 'none';
            priceInput.current.value = '';
        }

    },[price])

    useEffect(() => {
        if(
            username != ''
            && allPersons !=  undefined && allPersons.length > 0
        )
        {
            set_page_loaded(true);
        }
    }, [allPersons])

    const reset_all_data = () => {
        setProfilePhoto('');
        setProfileName('');
        setProfileDescription('');
        setProfileBookDescription('');
        setProfileQuotes([]);
        setSimilarPeople([]);
        setProfileQuotesText([]);
        setProfileQuotesText2([]);
        setProfileTotalQuotes(0);
        setProfileTotalQuotesPages(0);
        setError('');
        setAllPersons([]);
        set_current_quote_page(0);
        set_current_url('');
    }

    useEffect(() => {
        let username = props.match.params.id.split('-');
        let username_new = username.map(item=> item.charAt(0).toUpperCase() + item.substr(1).toLowerCase()).join(' ')
        setUsername(username_new);
        get_all_persons();
    },[]);

    function changePrice(price=0)
    {
        set_price(price);
    }

    const inputChange = (event) => {
        const re = /^[0-9\b]+$/;

        if (event.target.value !== '' && re.test(event.target.value) === true ) {
            set_price( !isNaN(event.target.value) && event.target.value > 0 ? event.target.value : '');
            priceInput.current.value = !isNaN(event.target.value) && event.target.value > 0 ? event.target.value : ''
        }
        else if(event.target.value == '')
        {
            set_price('');
            priceInput.current.value = price > 0 ? price : '';
        }
        else
        {
            priceInput.current.value = price > 0 ? price : '';
        }

    };

    async function get_all_persons()
    {
        var base = new Airtable({apiKey: props.apiKey}).base(props.baseKey);
        let all_slider_images = [];
        await base('persons').select({
            // Selecting the first 3 records in Grid view:
            view: "Grid view"
        }).all().then(records => {
            if(records.length > 0)
            {
                let all_persons_with_key = allPersons;
                records.forEach((all_person_val) => {

                    all_persons_with_key[all_person_val['id']] = all_person_val.fields;
                })
                setAllPersons([all_persons_with_key]);
            }
            else
            {
                setAllPersons([]);
            }
        }).catch(err => {
            // console.error(err);
        });
    }

    async function get_author_details()
    {
        let username_smaller = username.toLowerCase();
        var base = new Airtable({apiKey: props.apiKey}).base(props.baseKey);
        let find_user = await base('persons').select({
            // Selecting the first 3 records in Grid view:
            view: "Grid view",
            filterByFormula:`(LOWER({name}) = '${username_smaller}')`
        }).all();
        if(find_user.length > 0)
        {
            setError('');
            setProfileName(find_user[0].fields.name);
            setProfileDescription(find_user[0].fields.description);
            setProfileBookDescription(find_user[0].fields.description);
            setProfilePhoto(find_user[0].fields.photo[0].thumbnails !== undefined ? find_user[0].fields.photo[0].thumbnails.large.url : find_user[0].fields.photo[0].url);
            let profile_photo_extension = find_user[0].fields.photo[0].type.split('/').reverse()[0];
            setProfilePhotoExtension(profile_photo_extension);

            if(find_user[0].fields.quotes !== undefined)
            {
                setProfileQuotes(find_user[0].fields.quotes);
                setProfileTotalQuotes(find_user[0].fields.quotes.length);
            }
            setSimilarPeople(find_user[0].fields.similarPeople);
        }
        else
        {
            setError('User profile not found');
        }
    }

    async function get_author_quotes()
    {
        if(current_quote_page<profileTotalQuotesPages)
        {
            set_loading(true);
            let next_page = current_quote_page + 1;
            setTimeout(function(){
                set_current_quote_page(current_quote_page+1);
                set_loading(false);
            },500);
        }
    }

    function copy(text) {
        var input = document.createElement('input');
        input.setAttribute('value', text);
        document.body.appendChild(input);
        input.select();
        var result = document.execCommand('copy');
        document.body.removeChild(input);
        return result;
     }

    useEffect(() => {
        if(username !== undefined && username != '')
        {
            get_author_details();
        }
    }, [username])

    useEffect(() => {
        get_author_quotes();
     }, [profileTotalQuotesPages]);

    useEffect(() => {


        let changed_username = props.match.params.id.split('-');
        let username_new = changed_username.map(item=> item.charAt(0).toUpperCase() + item.substr(1).toLowerCase()).join(' ')

        if(username !== username_new)
        {
            reset_all_data();
            get_all_persons();
            setUsername(username_new);
            set_current_url(window.location.href);
        }

        // document.body.scrollTop = document.documentElement.scrollTop = 0;
        set_open_people_popup(false);
      }, [location]);

    var relatedSlider = {
        //centerMode: true,
        variableWidth: true,
        //centerPadding: '0',
        infinite: true,
        slidesToShow: similarPeople!== undefined ? (similarPeople.length > 4 ? 4 : similarPeople.length) : 0,
        slidesToScroll: similarPeople!== undefined ? (similarPeople.length > 4 ? 4 : similarPeople.length ) : 0,
        autoplay: true,
        autoplaySpeed: 3000,
        speed: 700,
        arrows: false,
        dots:false,
        responsive: [
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 3,
                }
            },
            {
                breakpoint: 500,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2,
                }
            },
            {
                breakpoint: 335,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                }
            }
        ]
    }
    var settings = {
        infinite: true,
        slidesToShow: 9,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 100,
        cssEase: 'linear',
        speed: 12000,
        arrows: false,
        responsive: [
            {
                breakpoint: 1800,
                settings: {
                    slidesToShow: 8,
                }
            },
            {
                breakpoint: 1600,
                settings: {
                    slidesToShow: 7,
                }
            },
            {
                breakpoint: 1200,
                settings: {
                    slidesToShow: 6,
                }
            },
            {
                breakpoint: 992,
                settings: {
                    slidesToShow: 5,
                }
            },
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 4,
                }
            },
            {
                breakpoint: 425,
                settings: {
                    slidesToShow: 2,
                }
            }
        ]

    };
    var settingsTwo = {
        rtl: true,
        infinite: true,
        slidesToShow: 9,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 100,
        cssEase: 'linear',
        speed: 12000,
        arrows: false,
        responsive: [
            {
                breakpoint: 1800,
                settings: {
                    slidesToShow: 8,
                }
            },
            {
                breakpoint: 1600,
                settings: {
                    slidesToShow: 7,
                }
            },
            {
                breakpoint: 1200,
                settings: {
                    slidesToShow: 6,
                }
            },
            {
                breakpoint: 992,
                settings: {
                    slidesToShow: 5,
                }
            },
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 4,
                }
            },
            {
                breakpoint: 425,
                settings: {
                    slidesToShow: 2,
                }
            }
        ]
    }

    useEffect(() => {
        set_loading(true);
    },[]);

    useEffect(() => {
        get_user_all_quotes();
    },[username]);

    async function get_user_all_quotes()
    {
        let username_smaller = username.toLowerCase();
        var base = new Airtable({apiKey: props.apiKey}).base(props.baseKey);


        let find_user = await base('quotes').select({
            // Selecting the first 3 records in Grid view:
            view: "Grid view",
            // filterByFormula:`(LOWER({author}) = '${username_smaller}') AND {RemainingChar} >= 0`,
            filterByFormula: `AND(LOWER({author})='${username_smaller}', {RemainingChar} >= 0 )`,
            // filterByFormula:`({RemainingChar} >= 0)`
        }).all();
        if(find_user.length > 0)
        {
            // console.log('find_user',find_user);
            set_final_quotes(find_user);
            set_my_quotes(find_user);
            // console.log('final_quotes',final_quotes);

            setProfileTotalQuotesPages( Math.ceil(find_user.length/per_page_quotes) );

        }
        else
        {
        }
    }

    useEffect(() => {
        // console.log('final_quotes',final_quotes);
    });

    useEffect(() => {
        // console.log('my_quotes',my_quotes);
    },[my_quotes]);

    return (
        <>
            <Helmet>
                <title>{page_seo_data.title != '' ? page_seo_data.title : default_page_seo_data.title}</title>
                <meta name="description" content={page_seo_data.desc != '' ? page_seo_data.desc : default_page_seo_data.desc} />
            </Helmet>

            <div className="profile_page">
                <PageLoader page_loaded={page_loaded} />
                <div className={error !== undefined && error != '' ? 'profile_block_wrap page_error' : 'profile_block_wrap' }>
                    {
                        error !== undefined && error != ''
                        ? error
                        :
                        <Tab.Container id="left-tabs-example" defaultActiveKey="first">
                            <div className="profile_block_inner_wrap">
                            <div className="container">
                                <div className="profile_block">
                                    <div className="profile_img">
                                        {
                                            profilePhotoExtension == 'mp4'
                                            ?
                                                <>
                                                    <video width="15%" height="100%" className="" src={profilePhoto} autoPlay muted loop controls={false}></video>
                                                </>

                                            :
                                                <img src={profilePhoto} alt={profileName} />
                                        }
                                    </div>
                                    <div className="profile_share hero_text_wrap">
                                        <span onClick={toggleShowA} to="/discover/people/profile" className="btn btn-outline-secondary">
                                            <img src={ShareIcon2} alt="Share" /> Share
                                        </span>
                                        <Toast show={showA} onClose={toggleShowA}>
                                            <Toast.Header>Click the links. Share the stuff.</Toast.Header>
                                            <Toast.Body>
                                                    <ul className="list-unstyled social_share">
                                                        <li className="twitter">

                                                            <a target="_blank" rel='noopener noreferrer' href={"https://twitter.com/intent/tweet?text="+window.location.href}>
                                                                <div className="share_icon"><TweetIcon /></div> Twitter
                                                            </a>
                                                        </li>
                                                        <li className="fb">
                                                            <a to="void(0);" onClick={() => {
                                                                window.open('https://www.facebook.com/sharer/sharer.php?u=' + window.location.href + '&title=Your Feed', 'fbShareWindow', 'height=450, width=550, top=' + (window.innerHeight / 2 - 275) + ', left=' + (window.innerWidth / 2 - 225) + ', toolbar=0, location=0, menubar=0, directories=0, scrollbars=0');
                                                            }}>
                                                                <div className="share_icon"><FaceBookIcon /></div> Facebook
                                                            </a>
                                                        </li>
                                                        <li className="reddit">
                                                            <a target="_blank" rel='noopener noreferrer' href={'https://www.reddit.com/submit?url='+window.location.href}>
                                                                <div className="share_icon"><RedditIcon /></div> Reddit
                                                            </a>
                                                        </li>
                                                        <li className="link d-md-none">
                                                            <a href="void(0);" onClick={(e) => {
                                                                    e.preventDefault();
                                                                    let website_link_text = document.getElementById('website_link').innerText;
                                                                    copy(window.location.href);
                                                                    set_copy_link_text('Link copied');
                                                                }}>
                                                                <div className="share_icon"><img src={LinkIcon} alt="LinkIcon" /></div> {copy_link_text} <span className="d-none" id="website_link">{window.location.href}</span>
                                                            </a>
                                                        </li>
                                                        <li className="link d-md-block d-none">
                                                            <Link to="#">
                                                                <div className="share_icon">
                                                                    <img src={LinkIcon} alt="LinkIcon" />
                                                                </div>
                                                                <button type="button" className="btn copy_btn" onClick={(e) => {
                                                                    e.preventDefault();
                                                                    let website_link_text = document.getElementById('website_link').innerHTML;
                                                                    website_link_text = window.location.href;
                                                                    copy(window.location.href);
                                                                    set_copy_link_text('Link copied');
                                                                }}>{copy_link_text} </button>
                                                            </Link>
                                                        </li>
                                                    </ul>
                                                <Link to="/pricing" className="fund_btn"><FundIcon fill="#FEDF30" />Fund Yourfeed.ai</Link>
                                            </Toast.Body>
                                        </Toast>
                                    </div>
                                </div>
                                <Tab.Content>
                                    <Tab.Pane eventKey="first">
                                        <div className="profile_block_content">
                                            <h1>{profileName} quotes</h1>
                                            <p>{profileDescription}</p>
                                        </div>
                                    </Tab.Pane>
                                    <Tab.Pane eventKey="second">
                                        <div className="profile_block_content">
                                            <h2>{profileName} book recommentations</h2>
                                            <p>{profileBookDescription}</p>
                                        </div>
                                    </Tab.Pane>
                                </Tab.Content>
                            </div>
                            <div className="profile_tab_links">
                            <div className="container">
                                <Nav className="justify-content-center">
                                    <Nav.Item>
                                    <Nav.Link eventKey="first">{profileName} quotes</Nav.Link>
                                    </Nav.Item>
                                    <Nav.Item>
                                        <Nav.Link eventKey="second">
                                            <span className="d-none d-md-block">{profileName} book recommentations</span>
                                            <span className="d-md-none">Book recommentations</span>
                                        </Nav.Link>
                                    </Nav.Item>
                                </Nav>
                            </div>
                            </div>
                            </div>
                                <Tab.Content className="profile_tab_content">
                                    <Tab.Pane eventKey="first">


                                        {
                                            my_quotes !== undefined && my_quotes.length > 0 && allPersons[0] !== undefined
                                            ?
                                                <>

                                                    <div className="card_wraper d-none d-md-block">
                                                        <div className="container">
                                                            {
                                                                my_quotes.slice(0,3).map((first3_quotes) => {
                                                                    let quote_id = first3_quotes.id;
                                                                    first3_quotes = first3_quotes.fields;
                                                                    quotes_key = quotes_key + 1;
                                                                    let first3_quotes_quote = first3_quotes.text;
                                                                    // console.log('first3_quotes',first3_quotes);
                                                                    let first3_quotes_author_id = first3_quotes.author !== undefined && first3_quotes.author[0] !== undefined ? first3_quotes.author[0] : '';
                                                                    let author_details = allPersons[0][first3_quotes_author_id] !== undefined ? allPersons[0][first3_quotes_author_id] : [];
                                                                    let author_name = author_details.name !== undefined ? author_details.name : '';
                                                                    let author_role = author_details.role !== undefined ? author_details.role : '';
                                                                    let author_image = author_details.photo !== undefined ? author_details.photo[0].url : '';
                                                                    let author_slug = author_name.toLowerCase().split(' ').join('-');
                                                                    let author_image_photo_extension =  author_details.photo !== undefined && author_details.photo[0] !== undefined ? author_details.photo[0].type.split('/').reverse()[0] : '';
                                                                    return  <div className="card_block" key={quotes_key}>
                                                                                <div className="card_block_img flex-shrink-0">
                                                                                {
                                                                                    author_image_photo_extension === 'mp4'
                                                                                    ?
                                                                                        <>
                                                                                            <video width="130px" height="100%" className="" src={author_image} autoPlay muted loop controls={false}></video>
                                                                                        </>

                                                                                    :
                                                                                        <img src={author_image} alt={author_name} />
                                                                                }
                                                                                </div>
                                                                                <div className="card_block_right">
                                                                                    <div className="card_block_author">
                                                                                        <div>
                                                                                            <Link to={`/discover/people/${author_slug}`}><h4>{author_name}</h4></Link>
                                                                                            <div className="fs-sm">{author_role}</div>
                                                                                        </div>
                                                                                        <span onClick={() => {
                                                                                            setTimeout(function(){
                                                                                                set_open_people_popup(true);
                                                                                                set_people_popup_data({username:author_name,user_profile_link:'discover/people/profile/quotes/'+quote_id,slug:author_slug,profile_page:true,quote_id:quote_id})

                                                                                            },set_open_people_popup_delay)
                                                                                        }} to="/">
                                                                                            <img src={Dots} alt="dots" /></span>
                                                                                    </div>
                                                                                    <div className="card_block_content">
                                                                                        {first3_quotes_quote}
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                })
                                                            }


                                                        </div>
                                                    </div>

                                                    <div className="card_wraper d-md-none">
                                                        <div className="container">
                                                            {
                                                                final_quotes.slice(0,3).map((first3_quotes) => {
                                                                    let quote_id = first3_quotes.id;
                                                                    quotes_mobile_key = quotes_mobile_key + 1;
                                                                    first3_quotes = first3_quotes.fields;
                                                                    let first3_quotes_quote = first3_quotes.text;
                                                                    let first3_quotes_author_id = first3_quotes.author !== undefined && first3_quotes.author[0] !== undefined ? first3_quotes.author[0] : '';;
                                                                    let author_details = allPersons[0][first3_quotes_author_id] !== undefined ? allPersons[0][first3_quotes_author_id] : [];
                                                                    let author_name = author_details.name !== undefined ? author_details.name : '';
                                                                    let author_role = author_details.role !== undefined ? author_details.role : '';
                                                                    let author_image = author_details.photo !== undefined && author_details.photo[0] !== undefined && author_details.photo[0].thumbnails !== undefined ? author_details.photo[0].thumbnails.large.url : author_details.photo !== undefined && author_details.photo[0] !== undefined ? author_details.photo[0].url : '';
                                                                    let author_slug = author_name.toLowerCase().split(' ').join('-');
                                                                    let author_image_photo_extension =  author_details.photo !== undefined && author_details.photo[0] !== undefined ? author_details.photo[0].type.split('/').reverse()[0] : '';

                                                                    return  <div className="card_block" key={quotes_mobile_key} data-id={quotes_mobile_key}>
                                                                                <div className="card_block_top">
                                                                                    <div className="card_block_img flex-shrink-0">
                                                                                        {
                                                                                            author_image_photo_extension == 'mp4'
                                                                                            ?
                                                                                                <>
                                                                                                    <video width="55px" height="100%" className="" src={author_image} autoPlay muted loop controls={false}></video>
                                                                                                </>

                                                                                            :
                                                                                                <img src={author_image} alt={author_name} />
                                                                                        }
                                                                                    </div>
                                                                                    <div className="card_block_author">
                                                                                        <div>
                                                                                            <Link to={`/discover/people/${author_slug}`}><h4>{author_name}</h4></Link>
                                                                                            <div className="fs-sm">{author_role}</div>
                                                                                        </div>
                                                                                        <span onClick={() => {
                                                                                            setTimeout(function(){

                                                                                                set_open_people_popup(true);
                                                                                                set_people_popup_data({username:author_name,user_profile_link:'/discover/people/profile/quotes'+quote_id,slug:author_slug,profile_page:true,quote_id:quote_id})
                                                                                            },set_open_people_popup_delay);
                                                                                        }}><img src={Dots} alt="dots" /></span>
                                                                                    </div>
                                                                                </div>
                                                                                <div className="card_block_content">
                                                                                    {first3_quotes_quote}
                                                                                </div>
                                                                            </div>
                                                                })
                                                            }
                                                        </div>
                                                    </div>
                                                </>
                                        : null
                                        }

                                        <div className="copy_wrap">
                                            <div className="container">
                                                <div className="copy_wrap_tab_block">
                                                    <Tab.Container id="topics-tabs" defaultActiveKey="share-topic">
                                                        <Nav className="topics_tab_links justify-content-center">
                                                            <Nav.Item>
                                                                <Nav.Link eventKey="share-topic"><SharingIcon /> Share</Nav.Link>
                                                            </Nav.Item>
                                                            <Nav.Item>
                                                                <Nav.Link eventKey="fund-topic"><FundIcon /> Fund Yourfeed.ai</Nav.Link>
                                                            </Nav.Item>
                                                        </Nav>
                                                        <Tab.Content>
                                                                <Tab.Pane eventKey="share-topic">
                                                                    <div className="link_wrap">
                                                                        <p><LinkingIcon /> <span id="copy_tag_link">{window.location.origin}</span></p>
                                                                        <button className="btn copy_btn" onClick={() => {
                                                                            copy(document.getElementById('copy_tag_link').innerText);
                                                                            set_copy_tag_link_text('Copied');
                                                                        }}>{copy_tag_link_text}</button>
                                                                    </div>
                                                                    <div className="copy_text">
                                                                        <img src={BlobShape} alt="BlobShape" className="blob_shape" />
                                                                        Share yourfeed.ai on <strong>Twitter</strong>, <strong>Snapchat</strong>, <strong>Email</strong> etc.
                                                                    </div>
                                                                </Tab.Pane>
                                                                <Tab.Pane eventKey="fund-topic">
                                                                    <div className="fund_jdi_tab position-relative">
                                                                        <div className={fund_class}>
                                                                            <h2>Will you give today?</h2>
                                                                            <p>We know most people will ignore this message, but if everyone gave a little, we could build cool software as a community.</p>
                                                                            <div className="protip_txt"><strong>Protip:</strong> You also get a <span className="text-secondary">"founding member badge".</span></div>
                                                                            <div className="fund_cta">
                                                                                <button type="button" className="btn btn-info"
                                                                                onClick={() => {
                                                                                    set_jdi_class('just_do_it_block active');
                                                                                    set_fund_class('fund_topic_block text-center inactive');
                                                                                }}><FundIcon /> Quick donate</button>
                                                                                <Link to="/pricing" className="btn btn-dark"><RightAngleIcon /> Tell me more</Link>
                                                                            </div>
                                                                            <ListGroup as="ul" horizontal className="fund_payment_type">
                                                                                <ListGroup.Item as="li" className="bg-transparent"><img src={GPayIcon} alt="GPayIcon" /></ListGroup.Item>
                                                                                <ListGroup.Item as="li" className="bg-transparent"><img src={VbyVisaIcon} alt="VbyVisaIcon" /></ListGroup.Item>
                                                                                <ListGroup.Item as="li" className="bg-transparent"><img src={VisaIcon} alt="VisaIcon" /></ListGroup.Item>
                                                                                <ListGroup.Item as="li" className="bg-transparent"><img src={MasterCardIcon} alt="MasterCardIcon" /></ListGroup.Item>
                                                                                <ListGroup.Item as="li" className="bg-transparent"><img src={ApplePayIcon} alt="ApplePayIcon" /></ListGroup.Item>
                                                                            </ListGroup>

                                                                        </div>
                                                                        <div className={jdi_class}>
                                                                            <h2 className="text-center text-white">Just do it ;-)</h2>
                                                                            <p className="text-center text-white">because your help is so valuable</p>
                                                                            <form action="" method="post">
                                                                                <div className="custom_radio">
                                                                                    <div className="ps-0 mb-0 form-check">
                                                                                        <input name="amount" type="radio" id="amount" className={amount1_checked} onClick={() => {changePrice(10);set_amount1_checked("form-check-input checked");set_amount2_checked("form-check-input");set_amount3_checked("form-check-input");}} defaultChecked={amount1_checked}/>
                                                                                        <label title="" htmlFor="amount" className="form-check-label"><sub>$</sub>10</label>
                                                                                    </div>
                                                                                </div>
                                                                                <div className="custom_radio">
                                                                                    <div className="ps-0 mb-0 form-check">
                                                                                        <input name="amount" type="radio" id="amount2" className={amount2_checked} onClick={() => {changePrice(15);set_amount1_checked("form-check-input");set_amount2_checked("form-check-input checked");set_amount3_checked("form-check-input");}} defaultChecked={amount2_checked}/>
                                                                                        <label title="" htmlFor="amount2" className="form-check-label"><sub>$</sub>15</label>
                                                                                    </div>
                                                                                </div>
                                                                                <div className="custom_radio">
                                                                                    <div className="ps-0 mb-0 form-check">
                                                                                        <input name="amount" type="radio" id="amount3" className={amount3_checked} onClick={() => {changePrice(20);set_amount1_checked("form-check-input");set_amount2_checked("form-check-input");set_amount3_checked("form-check-input checked");}} defaultChecked={amount3_checked}/>
                                                                                        <label title="" htmlFor="amount3" className="form-check-label"><sub>$</sub>20</label>
                                                                                    </div>
                                                                                </div>
                                                                                <div className="custom_input_group">
                                                                                    <span className="d-block">Set your own amount</span>
                                                                                    <div className="custom_input">
                                                                                        <div className="custom_input_icon"><img src={Dollersign} alt="Doller sign" /></div>
                                                                                        <input ref={priceInput} onChange={(e) => {inputChange(e)}} placeholder="maybe $44?" type="text" defaultValue={price} className="form-control"></input>
                                                                                    </div>
                                                                                </div>
                                                                                <button ref={payBtn} type="submit" className="btn form_btn"><LockIcon />Secure payment</button>
                                                                            </form>
                                                                            <ListGroup as="ul" horizontal className="fund_payment_type">
                                                                                <ListGroup.Item as="li" className="bg-transparent"><img src={GPayIcon} alt="GPayIcon" /></ListGroup.Item>
                                                                                <ListGroup.Item as="li" className="bg-transparent"><img src={VbyVisaIcon} alt="VbyVisaIcon" /></ListGroup.Item>
                                                                                <ListGroup.Item as="li" className="bg-transparent"><img src={VisaIcon} alt="VisaIcon" /></ListGroup.Item>
                                                                                <ListGroup.Item as="li" className="bg-transparent"><img src={MasterCardIcon} alt="MasterCardIcon" /></ListGroup.Item>
                                                                                <ListGroup.Item as="li" className="bg-transparent"><img src={ApplePayIcon} alt="ApplePayIcon" /></ListGroup.Item>
                                                                            </ListGroup>
                                                                            <div className="share_close mt-4">
                                                                                <button onClick={() => {
                                                                                    set_jdi_class('just_do_it_block');
                                                                                    set_fund_class('fund_topic_block text-center active');
                                                                                }} className="btn">Back</button>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </Tab.Pane>
                                                            </Tab.Content>
                                                    </Tab.Container>
                                                </div>
                                            </div>
                                        </div>

                                        {/* LAST ALL QUOTES */}
                                        {
                                            final_quotes !== undefined && final_quotes !== undefined && final_quotes.length > 3 && allPersons[0] !== undefined
                                            ?
                                                <>

                                                    <div className="card_wraper d-none d-md-block">
                                                        <div className="container">
                                                            {
                                                                final_quotes.slice(3,(current_quote_page*per_page_quotes)).map((last_quotes) => {
                                                                        let quote_id = last_quotes.id;
                                                                        quotes_key = quotes_key + 1;
                                                                        last_quotes = last_quotes.fields;
                                                                        let last_quotes_quote = last_quotes.text;
                                                                        let last_quotes_author_id = last_quotes.author !== undefined && last_quotes.author[0] !== undefined ? last_quotes.author[0] : '';
                                                                        let author_details = allPersons[0][last_quotes_author_id] !== undefined ? allPersons[0][last_quotes_author_id] : [];
                                                                        let author_name = author_details.name !== undefined ? author_details.name : '';
                                                                        let author_role = author_details.role !== undefined ? author_details.role : '';
                                                                        let author_image = author_details.photo !== undefined && author_details.photo[0] !== undefined && author_details.photo[0].thumbnails !== undefined ? author_details.photo[0].thumbnails.large.url : author_details.photo !== undefined && author_details.photo[0] !== undefined ? author_details.photo[0].url : '';

                                                                        let author_slug = author_name.toLowerCase().split(' ').join('-');
                                                                        let author_image_photo_extension =  author_details.photo !== undefined && author_details.photo[0] !== undefined ? author_details.photo[0].type.split('/').reverse()[0] : '';

                                                                        return <div className="card_block" data-page={current_quote_page} key={quotes_key} data-id={quotes_key}>
                                                                                    <div className="card_block_img flex-shrink-0">
                                                                                        {
                                                                                            author_image_photo_extension == 'mp4'
                                                                                            ?
                                                                                                <>

                                                                                                    <video width="130px" height="100%" className="" src={author_image} autoPlay muted loop controls={false}></video>
                                                                                                </>

                                                                                            :
                                                                                                <img src={author_image} alt={author_name} />
                                                                                        }
                                                                                    </div>
                                                                                    <div className="card_block_right">
                                                                                        <div className="card_block_author">
                                                                                            <div>
                                                                                                <Link to={`/discover/people/${author_slug}`}><h4>{author_name}</h4></Link>
                                                                                                <div className="fs-sm">{author_role}</div>
                                                                                            </div>
                                                                                            <span onClick={() => {
                                                                                                setTimeout(function(){
                                                                                                    set_open_people_popup(true);
                                                                                                    set_people_popup_data({username:author_name,user_profile_link:'/discover/people/profile/quotes'+quote_id,slug:author_slug,profile_page:true,quote_id:quote_id})
                                                                                                },set_open_people_popup_delay);


                                                                                            }} ><img src={Dots} alt="dots" /></span>
                                                                                        </div>
                                                                                        <div className="card_block_content">
                                                                                            {last_quotes_quote}
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                })
                                                            }
                                                            {
                                                                current_quote_page < profileTotalQuotesPages
                                                                ?
                                                                    <div className="btn_wrap text-center">
                                                                        <Button className="btn more_btn" onClick={() =>{
                                                                            get_author_quotes()
                                                                        }}><FlashIcon /> More
                                                                        {
                                                                            loading === true
                                                                            ?
                                                                                <Spinner as="span" animation="border" className="ms-2" size="sm" role="status" aria-hidden="true">
                                                                                    <span className="visually-hidden">Loading...</span>
                                                                                </Spinner>
                                                                            : null
                                                                        }

                                                                        </Button>

                                                                    </div>
                                                                : null
                                                            }

                                                        </div>
                                                    </div>
                                                    <div className="card_wraper d-md-none">
                                                        <div className="container">
                                                            {
                                                                final_quotes.slice(3,(current_quote_page*per_page_quotes)).map((last_quotes) => {
                                                                    let quote_id = last_quotes.id;
                                                                    quotes_mobile_key = quotes_mobile_key + 1;
                                                                    last_quotes = last_quotes.fields;
                                                                    let last_quotes_quote = last_quotes.text;
                                                                    let last_quotes_author_id = last_quotes.author !== undefined && last_quotes.author[0] !== undefined ? last_quotes.author[0] : '';
                                                                    let author_details = allPersons[0][last_quotes_author_id] !== undefined ? allPersons[0][last_quotes_author_id] : [];
                                                                    let author_name = author_details.name !== undefined ? author_details.name : '';
                                                                    let author_role = author_details.role !== undefined ? author_details.role : '';
                                                                    let author_image = author_details.photo !== undefined && author_details.photo[0] !== undefined && author_details.photo[0].thumbnails !== undefined ? author_details.photo[0].thumbnails.large.url : author_details.photo !== undefined && author_details.photo[0] !== undefined ? author_details.photo[0].url : '';

                                                                    let author_slug = author_name.toLowerCase().split(' ').join('-');
                                                                    let author_image_photo_extension =  author_details.photo !== undefined && author_details.photo[0] !== undefined ? author_details.photo[0].type.split('/').reverse()[0] : '';

                                                                    return  <div className="card_block" key={quotes_mobile_key} data-id={quotes_mobile_key}>
                                                                                <div className="card_block_top">
                                                                                    <div className="card_block_img flex-shrink-0">
                                                                                        {
                                                                                            author_image_photo_extension == 'mp4'
                                                                                            ?
                                                                                                <>

                                                                                                    <video width="55px" height="100%" className="" src={author_image} autoPlay muted loop controls={false}></video>
                                                                                                </>

                                                                                            :
                                                                                                <img src={author_image} alt={author_name} />
                                                                                        }
                                                                                    </div>
                                                                                    <div className="card_block_author">
                                                                                        <div>
                                                                                            <Link to={`/discover/people/${author_slug}`}><h4>{author_name}</h4></Link>
                                                                                            <div className="fs-sm">{author_role}</div>
                                                                                        </div>
                                                                                        <span onClick={() => {
                                                                                            setTimeout(function(){
                                                                                                set_open_people_popup(true);
                                                                                                set_people_popup_data({username:author_name,user_profile_link:'/discover/people/profile/quotes'+quote_id,slug:author_slug,profile_page:true,quote_id:quote_id})
                                                                                            },set_open_people_popup_delay);

                                                                                        }} ><img src={Dots} alt="dots" /></span>
                                                                                    </div>
                                                                                </div>
                                                                                <div className="card_block_content">
                                                                                    {last_quotes_quote}
                                                                                </div>
                                                                            </div>
                                                                })
                                                            }

                                                            {
                                                                profileTotalQuotesPages > 1 && current_quote_page < profileTotalQuotesPages
                                                                ?

                                                                    <div className="btn_wrap text-center">
                                                                        <Button className="btn more_btn" onClick={() =>{
                                                                            get_author_quotes()
                                                                        }}><FlashIcon /> More
                                                                            {
                                                                                loading === true
                                                                                ?
                                                                                    <Spinner as="span" animation="border" className="ms-2" size="sm" role="status" aria-hidden="true">
                                                                                        <span className="visually-hidden">Loading...</span>
                                                                                    </Spinner>
                                                                                : null
                                                                            }
                                                                        </Button>
                                                                    </div>
                                                                : null
                                                            }
                                                        </div>
                                                    </div>
                                                </>
                                            : null
                                        }

                                        {
                                            similarPeople !== undefined && similarPeople.length > 0 && allPersons !== undefined && allPersons.length>0  && allPersons[0] !== undefined ?

                                            <div className="related_wrap">
                                                <div className="container">
                                                    <div className="related_wrap_title">
                                                        <h6>You may also like</h6>
                                                    </div>
                                                    <div className="related_slider">
                                                        <Slider {...relatedSlider}>
                                                            {
                                                                similarPeople.map((similar_people_id) => {
                                                                    quotes_key = quotes_key + 1;
                                                                    let similar_person_details = allPersons[0][similar_people_id];
                                                                    if(similar_person_details !== undefined)
                                                                    {
                                                                        let similar_person_name = similar_person_details.name;
                                                                        let similar_person_role = similar_person_details.role;
                                                                        // let similar_person_image = similar_person_details.photo[0].url;
                                                                        let similar_person_image = similar_person_details.photo !== undefined && similar_person_details.photo[0] !== undefined && similar_person_details.photo[0].thumbnails !== undefined ? similar_person_details.photo[0].thumbnails.large.url : similar_person_details.photo !== undefined && similar_person_details.photo[0] !== undefined ? similar_person_details.photo[0].url : '';

                                                                        let similar_person_slug = similar_person_name.toLowerCase().split(' ').join('-');
                                                                        let similar_person_image_photo_extension =  similar_person_details.photo[0] !== undefined ? similar_person_details.photo[0].type.split('/').reverse()[0] : '';

                                                                        return <div className="slide" data-id={similar_people_id} key={similar_people_id}>
                                                                                    <div className="related_block">
                                                                                        <Link className="d-block" to={`/discover/people/${similar_person_slug}`}>
                                                                                        <div className="related_block_img">
                                                                                        {
                                                                                            similar_person_image_photo_extension == 'mp4'
                                                                                            ?
                                                                                                <>
                                                                                                    {/* <div className="blob">
                                                                                                        <canvas className="not_rendered"></canvas>
                                                                                                    </div> */}
                                                                                                    <video width="100%" height="100%" className="" src={similar_person_image} autoPlay muted loop controls={false}></video>
                                                                                                </>

                                                                                            :
                                                                                                <img src={similar_person_image} alt={similar_person_name} />
                                                                                        }
                                                                                        </div>
                                                                                        <div className="related_block_content">
                                                                                            <h6>{similar_person_name}</h6>
                                                                                            <p>{similar_person_role}</p>
                                                                                        </div>
                                                                                        </Link>
                                                                                    </div>
                                                                                </div>
                                                                    }
                                                                })
                                                            }


                                                        </Slider>
                                                    </div>
                                                </div>
                                            </div>
                                            : null
                                        }


                                        <div className="container">
                                            <div className="breadcrumbs">
                                                <ul className="list-unstyled">
                                                    <li><Link to="/"><img src={HomeIcon} alt="home" /></Link></li>
                                                    <li><Link to="/discover">Discover</Link></li>
                                                    <li>People</li>
                                                    <li>{profileName}</li>
                                                </ul>
                                            </div>
                                        </div>
                                    </Tab.Pane>
                                    <Tab.Pane eventKey="second">
                                    <div className="soon_wrap banner_wrap">
                                        <div className="bg_slider_wrap">
                                            {
                                                sliderImages !== undefined && sliderImages.length > 0
                                                ?
                                                    sliderImages.map((banner_slider_images) => {
                                                        sliderImagesKey = sliderImagesKey + 1;
                                                        let new_banner_images = [];
                                                        let new_banner_single_images = [];
                                                        let is_bg_slider_rtl = sliderImagesKey % 2 == 0 ? 'bg_slider_rtl' : '';
                                                        let slider_settings = sliderImagesKey % 2 == 0 ? settingsTwo : settings;
                                                        let minimum_images = 10;
                                                        if (banner_slider_images.length < minimum_images) {
                                                            let total_loops = Math.ceil(minimum_images / banner_slider_images.length)
                                                            for (let k = 1; k <= total_loops; k++) {
                                                                new_banner_images = [...new_banner_images, banner_slider_images];
                                                            }
                                                            if (new_banner_images.length > 0) {
                                                                new_banner_images.forEach((new_banner_val) => {
                                                                    if (new_banner_val.length > 0) {
                                                                        new_banner_val.forEach((inner_single_img) => {
                                                                            new_banner_single_images.push(inner_single_img);
                                                                        })
                                                                    }
                                                                })
                                                            }
                                                        }

                                                        return <Slider {...slider_settings} className={`slider${sliderImagesKey} bg_slider_row bg_slider ${is_bg_slider_rtl}`} key={sliderImagesKey}>

                                                            {
                                                                new_banner_single_images.map((final_single_img) => {

                                                                    slider_single_key = slider_single_key + 1;
                                                                    return  final_single_img.url !== undefined ?
                                                                        <div className="slide" key={slider_single_key}>
                                                                            <img src={final_single_img.url} alt={final_single_img.alt} />
                                                                        </div>
                                                                    : "";
                                                                })
                                                            }



                                                        </Slider>
                                                    })
                                                : null
                                            }
                                        </div>
                                        <div className="container">
                                            <Newsletter mental_footer_box={true} apiKey={props.apiKey} baseKey={props.baseKey} />
                                        </div>
                                    </div>
                                    </Tab.Pane>
                                </Tab.Content>
                        </Tab.Container>
                    }

                </div>
                <PeopleShareModal open_people_popup={open_people_popup} set_open_people_popup={set_open_people_popup} people_popup_data={people_popup_data} />

                <ShareModal open_share_popup={show_share_modal} set_show_share_modal={set_show_share_modal} share_links={
                    {
                        facebook : 'https://www.facebook.com/sharer/sharer.php?u=' + window.location.href + '&title=Your Feed, fbShareWindow,height=450, width=550, top=' + (window.innerHeight / 2 - 275) + ', left=' + (window.innerWidth / 2 - 225) + ', toolbar=0, location=0, menubar=0, directories=0, scrollbars=0',
                        twitter : `https://twitter.com/intent/tweet?text=${window.location.href}`,
                        whatsapp : `https://web.whatsapp.com/send?text=${window.location.href}`,
                        email : `https://mail.google.com/mail/?view=cm&fs=1&tf=1&to=&su=&body=${window.location.href}&ui=2&tf=1&pli=1`,
                    }
                } />
            </div>
        </>
    )
}

export default Profile;