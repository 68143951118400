import React,{useState} from "react";
import { Modal, ListGroup, Form, Button } from "react-bootstrap";
import { Link } from "react-router-dom";
import Logo from "../assets/img/invite-your-feed-logo.svg";
import LogoMobile from "../assets/img/invite-your-feed-logo-mobile.svg";
import infoicon from "../assets/img/infoicon.png";
import GPayIcon from "../assets/img/g-pay-ic.svg";
import VbyVisaIcon from "../assets/img/verifie-bt-visa-ic.svg";
import VisaIcon from "../assets/img/visa-ic.svg";
import MasterCardIcon from "../assets/img/mastercard-ic.svg";
import ApplePayIcon from "../assets/img/apple-pay-ic.svg";
import BlobShape from "../assets/img/blob-shape.svg";
import {
    LinkingIcon,
    MailIcon
} from "../assets/icons";

const InviteModal = (props) =>
{


    function copy(text) {
        var input = document.createElement('input');
        input.setAttribute('value', text);
        document.body.appendChild(input);
        input.select();
        var result = document.execCommand('copy');
        document.body.removeChild(input);
        return result;
     }
    const [copy_tag_link_text,set_copy_tag_link_text] = useState('Copy Link');

    return  <Modal className="invite_modal" show={props.open_invite_popup} onHide={() => {
        props.set_open_invite_popup(false);
    }} keyboard={false}>
                <Modal.Body className="p-0">
                    <div className="modal_logo d-flex align-items-center justify-content-between">
                        <img src={Logo} alt="Your feed ai" className="d-none d-md-block" />
                        <img src={LogoMobile} alt="Your feed ai" className="d-md-none" />
                        <span className="close" onClick={() => { props.set_open_invite_popup(false); }}>Close</span>
                    </div>
                    <div className="invite_title">
                        <h3>Invite by Link</h3>
                    </div>
                    <div className="invite_copy_link text-center">
                            <p>Copy the link to invite people</p>
                        <div className="link_wrap">
                            <p><LinkingIcon fill="rgba(255, 255, 255, 0.5)" /> <span id="copy_tag_link">{window.location.origin}</span></p>
                            <Button className="btn copy_btn" onClick={() => {
                                copy(window.location.origin);
                                set_copy_tag_link_text('Copied');
                            }}>{copy_tag_link_text}</Button>
                        </div>
                        <div className="copy_text">
                            <img src={BlobShape} alt="BlobShape" className="blob_shape" />
                            Share yourfeed.ai on <strong>Twitter, Snapchat, Email</strong> etc.
                        </div>
                    </div>
                    <div className="invite_email_modal_box">
                            <div className="invite_email_tag"><MailIcon />Invite by Email</div>
                            <div className="invite_mail_card position-relative">
                                <Form>
                                    <Form.Group className="form_group" controlId="formBasicEmail">
                                        <Form.Control type="email" placeholder="Add yo to 100 email addresses here" />
                                    </Form.Group>
                                    <Form.Group className="form_group" controlId="Comments">
                                        <Form.Control as="textarea" placeholder="Hi!
                                        I'm a member of Yourfeed.ai Community, and I think you should be too. Come join me!


                                        See you there!
                                        Elon Musk " />
                                    </Form.Group>
                                    <div className="incite_cta_block d-flex align-items-center justify-content-between">
                                        <Button variant="dark">Import Contacts</Button>
                                        <Button variant="primary" type="submit">Send</Button>
                                    </div>
                                </Form>
                                <Modal.Footer>
                                    <div className="mental_footer_box">
                                        <div className="d-flex align-items-center info-box">
                                            <img src={infoicon} alt="Info Icon" />
                                            <p className="mb-0">We are still working on <strong>“Invite by Email“</strong> and other sections of yourfeed.ai. Meanwhile, you can sign up for early access or become yourfeed.ai <strong>"founding member"</strong>.</p>
                                        </div>
                                        <div className="mental_footer_btns_wrap">
                                            <Link to="/your-future-account" className="btn form_btn">
                                                Get early access
                                            </Link>
                                            <Link to="/pricing" className="btn form_btn">
                                                Become a founding member
                                            </Link>
                                        </div>
                                        <div className="payment-cards text-center">
                                            <ListGroup as="ul" horizontal className="fund_payment_type pt-0">
                                                <ListGroup.Item as="li" className="bg-transparent"><img src={GPayIcon} alt="GPayIcon" /></ListGroup.Item>
                                                <ListGroup.Item as="li" className="bg-transparent"><img src={VbyVisaIcon} alt="VbyVisaIcon" /></ListGroup.Item>
                                                <ListGroup.Item as="li" className="bg-transparent"><img src={VisaIcon} alt="VisaIcon" /></ListGroup.Item>
                                                <ListGroup.Item as="li" className="bg-transparent"><img src={MasterCardIcon} alt="MasterCardIcon" /></ListGroup.Item>
                                                <ListGroup.Item as="li" className="bg-transparent"><img src={ApplePayIcon} alt="ApplePayIcon" /></ListGroup.Item>
                                            </ListGroup>
                                            <p>because your support is so valuable</p>
                                        </div>
                                    </div>
                                </Modal.Footer>
                            </div>
                    </div>
                </Modal.Body>

            </Modal>
}

export default InviteModal;