import React from "react";
import { NavLink } from "react-router-dom";
import TabSection from "../components/Tab";
import Logo from "../assets/img/logo.png";
import Logosm from "../assets/img/logo-sm.png";
import BackImg from "../assets/img/arrow-back.png";
import BarImg from "../assets/img/bar.png";
import Slider from "react-slick";
import Handwriting from "../assets/img/Handwriting.png";
import { useState } from "react";
import { Modal } from "react-bootstrap";
import Newsletter from "./Newsletter";

const ComingSoonModal = (props) => {
    // console.log('ComingSoonModalprops',props);

    let sliderImagesKey = 0;
    let slider_single_key = 0;

    var settings = {
        infinite: true,
        slidesToShow: 9,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 100,
        cssEase: 'linear',
        speed: 12000,
        arrows: false,
        responsive: [
            {
                breakpoint: 1800,
                settings: {
                    slidesToShow: 8,
                }
            },
            {
                breakpoint: 1600,
                settings: {
                    slidesToShow: 7,
                }
            },
            {
                breakpoint: 1200,
                settings: {
                    slidesToShow: 6,
                }
            },
            {
                breakpoint: 992,
                settings: {
                    slidesToShow: 5,
                }
            },
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 4,
                }
            },
            {
                breakpoint: 425,
                settings: {
                    slidesToShow: 2,
                }
            }
        ]

    };
    var settingsTwo = {
        rtl: true,
        infinite: true,
        slidesToShow: 9,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 100,
        cssEase: 'linear',
        speed: 12000,
        arrows: false,
        responsive: [
            {
                breakpoint: 1800,
                settings: {
                    slidesToShow: 8,
                }
            },
            {
                breakpoint: 1600,
                settings: {
                    slidesToShow: 7,
                }
            },
            {
                breakpoint: 1200,
                settings: {
                    slidesToShow: 6,
                }
            },
            {
                breakpoint: 992,
                settings: {
                    slidesToShow: 5,
                }
            },
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 4,
                }
            },
            {
                breakpoint: 425,
                settings: {
                    slidesToShow: 2,
                }
            }
        ]
    }

    const [show, setShow] = useState(false);

    const handleClose = () => props.set_show_soon_popup(false);
    const handleShow = () => props.set_show_soon_popup(true);
    return (
        <>

        <Modal className="soon_modal" show={props.show_soon_popup} onHide={handleClose} backdrop="static" keyboard={false}>
            <Modal.Body className="p-0">
                <div className="soon_page">
                    <div className="soon_page_header d-flex align-items-center justify-content-between">
                        <button type="button" className="btn back_btn" onClick={handleClose}>
                            <img src={BackImg} alt="Back to Home" />
                        </button>
                        <NavLink className="navbar-brand" exact to="/" onClick={handleClose}>
                            <img src={Logo} alt="YourFeed.ai" />
                        </NavLink>
                    </div>
                    {
                    props.sliderImages !== undefined && props.sliderImages.length > 0 ?
                        <div className="soon_wrap banner_wrap">
                            <div className="bg_slider_wrap">
                            {

                                props.sliderImages.map((banner_slider_images) => {
                                    sliderImagesKey = sliderImagesKey + 1;
                                    let new_banner_images = [];
                                    let new_banner_single_images = [];
                                    let is_bg_slider_rtl = sliderImagesKey % 2 == 0 ? 'bg_slider_rtl' : '';
                                    let slider_settings = sliderImagesKey % 2 == 0 ? settingsTwo : settings;
                                    let minimum_images = 10;
                                    if (banner_slider_images.length < minimum_images) {
                                        let total_loops = Math.ceil(minimum_images / banner_slider_images.length)
                                        for (let k = 1; k <= total_loops; k++) {
                                            new_banner_images = [...new_banner_images, banner_slider_images];
                                        }
                                        if (new_banner_images.length > 0) {
                                            new_banner_images.forEach((new_banner_val) => {
                                                if (new_banner_val.length > 0) {
                                                    new_banner_val.forEach((inner_single_img) => {
                                                        new_banner_single_images.push(inner_single_img);
                                                    })
                                                }
                                            })
                                        }
                                    }

                                    return <Slider {...slider_settings} className={`slider${sliderImagesKey} bg_slider_row bg_slider ${is_bg_slider_rtl}`} key={sliderImagesKey}>

                                        {
                                            new_banner_single_images.map((final_single_img) => {

                                                slider_single_key = slider_single_key + 1;
                                                return  final_single_img.url !== undefined ?
                                                    <div className="slide" key={slider_single_key}>
                                                        <img src={final_single_img.url} alt={final_single_img.alt} />
                                                    </div>
                                                : "";
                                            })
                                        }



                                    </Slider>
                                })
                            }
                        </div>

                            <div className="container">
                                <Newsletter apiKey={props.apiKey} baseKey={props.baseKey} />
                            </div>

                        </div>
                    : null
                    }
                    <div className="knowledge_wrap">
                        <div className="container">
                            {props.tab_content !== undefined && props.tab_content.Title !== undefined ? <h3>{props.tab_content.Title}</h3> : null }
                            {props.tab_content !== undefined && props.tab_content.Description !== undefined ? <p dangerouslySetInnerHTML={{__html: props.tab_content.Description }} ></p> : null }
                        </div>
                        <TabSection apiKey={props.apiKey} baseKey={props.baseKey} />
                    </div>

                    <div className="soon_page_footer">
                        <div className="container">
                            <NavLink className="soon_footer_logo" exact to="/">
                                <img src={Logosm} alt="YourFeed.ai" />
                            </NavLink>
                            <div className="soon_page_content">
                                { props.soon_footer_content !== undefined && props.soon_footer_content.Title !== undefined ? <h6>{props.soon_footer_content.Title}</h6> : null }
                                { props.soon_footer_content !== undefined && props.soon_footer_content.SubTitle !== undefined ? <h5 dangerouslySetInnerHTML={{__html: props.banner_content.SubTitle}} ></h5> : null }

                                { props.soon_footer_content !== undefined && props.soon_footer_content.Description !== undefined ? <p dangerouslySetInnerHTML={{__html: props.soon_footer_content.Description}} ></p> : null }

                                <img src={Handwriting} alt="YourFeed.ai" />
                                { props.soon_footer_content !== undefined && props.soon_footer_content.CopyRightText !== undefined ? <div className="soon_page_text">{props.soon_footer_content.CopyRightText}</div> : null }


                            </div>
                        </div>
                    </div>
                </div>
            </Modal.Body>
        </Modal>

        </>
    )
}

export default ComingSoonModal;