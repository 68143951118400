import React,{useState, useEffect} from "react";
import Airtable from "airtable";

const GenerateSeoFile = (props) => {
    // console.log('seo_props',props)
    async function get_seo_data()
    {
        var base = new Airtable({apiKey: props.apiKey}).base(props.baseKey);
        let all_slider_images = [];
        await base('Meta Content').select({
            // Selecting the first 3 records in Grid view:
            view: "Grid view",
        }).all().then(records => {
            console.log('Meta content',records);
            setMyData(records);
            // if(records.length > 0)
            // {
            //     set_banner_content(records[0].fields);
            // }
            
        }).catch(err => {
            // console.error(err);
        });
    }

    

    const [myData,setMyData] = useState([]);

    useEffect(() => {
        // console.log('myData',myData);
        download(JSON.stringify(myData), "seo.json", "text/plain");

    }, [myData])
    

    useEffect(() => {
        get_seo_data();
    },[]);

    function download(content, fileName, contentType) {
        const a = document.createElement("a");
        const file = new Blob([content], { type: contentType });
        a.href = URL.createObjectURL(file);
        a.download = fileName;
        a.click();
       }
    
    return(
        <>
        </>
    )
}

export default GenerateSeoFile;