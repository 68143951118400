import React,{useState} from "react";
import { Link } from "react-router-dom";
import { Modal } from "react-bootstrap";
import Logo from "../assets/img/logo.png";
import CloseImg from "../assets/img/close.png";
import FBIcon from "../assets/img/fb-ic.png";
import MsgIcon from "../assets/img/fb_msg-ic.png";
import TwitterIcon from "../assets/img/twitter-ic.png";
import WhatsappIcon from "../assets/img/whatsapp-ic.png";
import EmailIcon from "../assets/img/email-icon-sm.png";
import LinkIcon from "../assets/img/link-ic.png";

const ShareModal = (props) =>
{
    // console.log('ShareModal_props',props);

    function copy(text) {
        var input = document.createElement('input');
        input.setAttribute('value', text);
        document.body.appendChild(input);
        input.select();
        var result = document.execCommand('copy');
        document.body.removeChild(input);
        return result;
     }

    const [copy_link_text, set_copy_link_text] = useState('Copy Link');

    return  <Modal className="share_modal" show={props.open_share_popup} onHide={() => {
        props.set_show_share_modal(false);
    }} backdrop="static" keyboard={false}>
                <Modal.Body className="p-0">
                    <div className="modal_logo">
                        <img src={Logo} alt="Your feed ai" />
                    </div>
                    <div className="click_title">
                        <h3>Click the links. Share the stuff.</h3>
                    </div>
                    <div className="share_modal_box">
                        <img src={CloseImg} alt="Close" className="close" onClick={() => {
                            props.set_show_share_modal(false);
                            set_copy_link_text('Copy Link');
                                }} />
                        <ul className="list-unstyled social_share">
                            <li className="fb">
                                <a to="javascript:;" onClick={() => {
                                    window.open('https://www.facebook.com/sharer/sharer.php?u=' + window.location.origin + '&title=Your Feed', 'fbShareWindow', 'height=450, width=550, top=' + (window.innerHeight / 2 - 275) + ', left=' + (window.innerWidth / 2 - 225) + ', toolbar=0, location=0, menubar=0, directories=0, scrollbars=0');
                                }}>
                                    <div className="social_icon"><img src={FBIcon} alt="FBIcon" /></div>Facebook
                                </a>
                            </li>
                            <li className="fb_msg">
                                <Link to="#">
                                    <div className="social_icon"><img src={MsgIcon} alt="MsgIcon" /> </div>Facebook Messenger
                                </Link>
                            </li>
                            <li className="twitter">
                                <a target="_blank" rel='noopener noreferrer' href={props.share_links !== undefined && props.share_links.twitter !== undefined ? props.share_links.twitter : null}>
                                    <div className="social_icon"><img src={TwitterIcon} alt="TwitterIcon" /> </div>Twitter
                                </a>
                            </li>
                            <li className="whatsapp">
                                <a target="_blank" rel='noopener noreferrer' href={props.share_links !== undefined && props.share_links.whatsapp !== undefined ? props.share_links.whatsapp : null}>
                                    <div className="social_icon"><img src={WhatsappIcon} alt="WhatsappIcon" /> </div>WhatsApp
                                </a>
                            </li>
                            <li className="email">
                                <a href={props.share_links !== undefined && props.share_links.email !== undefined ? props.share_links.email : null} target="_blank">
                                    <div className="social_icon"><img src={EmailIcon} alt="EmailIcon" /></div> Email
                                </a>
                            </li>
                            <li className="link d-md-block d-none">
                                <Link to="#">
                                    <div className="social_icon">
                                        <img src={LinkIcon} alt="LinkIcon" />
                                    </div><span id="website_link">{window.location.origin}</span>
                                    <button type="button" className="btn copy_btn" onClick={() => {
                                        let website_link_text = document.getElementById('website_link').innerHTML;
                                        copy(window.location.origin);
                                        set_copy_link_text('Copied');
                                    }}>{copy_link_text} </button>
                                </Link>
                            </li>
                            <li className="link d-md-none">
                                <a href="javascript:;" onClick={() => {
                                        let website_link_text = document.getElementById('website_link').innerText;
                                        copy(website_link_text);
                                        set_copy_link_text('Copied');
                                    }}>
                                    <div className="social_icon"><img src={LinkIcon} alt="LinkIcon" /></div> {copy_link_text} <span id="website_link">www.yourfeed.ai</span>
                                </a>
                            </li>
                        </ul>

                    </div>
                </Modal.Body>
            </Modal>
}

export default ShareModal;