import React, { useState, useEffect, useRef } from "react";
import {Tab, Nav, ListGroup, Accordion } from "react-bootstrap";
import { Link } from "react-router-dom";
import CopyIconLg from "../assets/img/sms-lg.png";
import ShareIconLg from "../assets/img/share-lg.png";
import MessangerIconLg from "../assets/img/messenger-lg.png";
import TwitterIconLg from "../assets/img/twitter-lg.png";
import WhatsappIconLg from "../assets/img/whatsapp-lg.png";
import EmailIconLg from "../assets/img/email-lg.png";
import GPayIcon from "../assets/img/g-pay-ic.svg";
import VbyVisaIcon from "../assets/img/verifie-bt-visa-ic.svg";
import VisaIcon from "../assets/img/visa-ic.svg";
import MasterCardIcon from "../assets/img/mastercard-ic.svg";
import ApplePayIcon from "../assets/img/apple-pay-ic.svg";
import Dollersign from "../assets/img/doller-sign.png";
import { RWebShare } from "react-web-share";
import validator from 'validator'
import { NavLink } from "react-router-dom";


// import Slider from "react-slick";
import {
    SharingIcon,
    FundIcon,
    LinkingIcon,
    RightAngleIcon,
    LockIcon
} from "../assets/icons";

const ShareWithFundModal = (props) => {
    const [open_share_with_fund_popup,set_open_share_with_fund_popup] = useState(false);
    const [copy_link_text,set_copy_link_text] = useState('Copy Link');
    const [share_with_fund_popup_data,set_share_with_fund_popup_data] = useState({username:'',user_profile_link:''});
    const [copy_tag_link_text,set_copy_tag_link_text] = useState('Copy Link');
    const [jdi_class,set_jdi_class] = useState('just_do_it_block');
    const [fund_class,set_fund_class] = useState('fund_topic_block text-center');
    const [price,set_price] = useState('');
    const hidePopupBtn = useRef(null);
    const [amount1_checked,set_amount1_checked] = useState("form-check-input");
    const [amount2_checked,set_amount2_checked] = useState("form-check-input");
    const [amount3_checked,set_amount3_checked] = useState("form-check-input");

    var ShareSlider = {
        infinite: false,
        slidesToShow: 4,
        slidesToScroll: 1,
        arrows: false,
        dots:false,
    }

    useEffect(() => {
        if(price > 0)
        {
            payBtn.current.style.display = 'block';
        }
        else
        {
            payBtn.current.style.display = 'none';
        }
        priceInput.current.value = price;
    },[price])

    useEffect(() => {
        console.log('props',props);

        set_open_share_with_fund_popup(props.open_share_with_fund_popup);
        set_share_with_fund_popup_data(props.share_with_fund_popup_data);
    },[props]);

    useEffect(() => {
    },[]);

    function copy(text) {
        var input = document.createElement('input');
        input.setAttribute('value', text);
        document.body.appendChild(input);
        input.select();
        var result = document.execCommand('copy');
        document.body.removeChild(input);
        return result;
     }


    function trim(str)
    {
        return str.replace(/\s/g, '');
    }
    function changePrice(price=0)
    {
        set_price(price);
    }

    const inputChange = (event) => {
        // 👇 Get input value from "event"
        const re = /^[0-9\b]+$/;

        // if value is not blank, then test the regex
        if (event.target.value !== '' && re.test(event.target.value) === true ) {
            set_price( !isNaN(event.target.value) && event.target.value > 0 ? event.target.value : '');
            priceInput.current.value = !isNaN(event.target.value) && event.target.value > 0 ? event.target.value : ''
        }
        else if(event.target.value == '')
        {
            set_price('');
            priceInput.current.value = price > 0 ? price : '';
        }
        else
        {
            priceInput.current.value = price > 0 ? price : '';
        }

    }


    const payBtn = useRef(null);
    const priceInput = useRef(null);


    return (
        <>
            {
                <div className={props.open_share_with_fund_popup === true ? 'share_popup_wrap active' : 'share_popup_wrap'}>
                    <div className={props.open_share_with_fund_popup === true ? 'share_with_fund_popup share_popup active' : 'share_with_fund_popup share_popup'}>
                        <div className="share_close_ic d-none">
                            <button onClick={() => {
                                    props.set_open_share_with_fund_popup(false);
                            }}></button>
                        </div>

                        <div className="copy_wrap_tab_block">
                        <Tab.Container id="topics-tabs" defaultActiveKey="share-topic">
                            <Nav className="topics_tab_links justify-content-center">
                                <Nav.Item>
                                    <Nav.Link eventKey="share-topic"><SharingIcon /> Share</Nav.Link>
                                </Nav.Item>
                                <Nav.Item>
                                    <Nav.Link eventKey="fund-topic"><FundIcon /> Fund Yourfeed.ai</Nav.Link>
                                </Nav.Item>
                            </Nav>
                            <Tab.Content>
                                    <Tab.Pane eventKey="share-topic">
                                        <div className="click_title">
                                            <h3>Click the links. Share the stuff.</h3>
                                        </div>

                                        <div className="other_link text-center">
                                            or <NavLink to={`/pricing/`}>become a yourfeed.ai founding member</NavLink>
                                        </div>
                                        <Accordion defaultActiveKey="0" flush className="share_link">
                                            <Accordion.Item eventKey="0">
                                                <Accordion.Header><SharingIcon />Share link to this quote</Accordion.Header>
                                                <Accordion.Body className="p-0">
                                                    <div className="share_icons">
                                                            <span onClick={() => {
                                                                copy(window.location.href)
                                                            }} className="share_icon d-md-inline-block">
                                                                <img src={CopyIconLg} alt="Copy link" />
                                                                <p>Copy link</p>
                                                            </span>

                                                            <>
                                                                <RWebShare
                                                                    data={{
                                                                    url: window.location.hred,
                                                                    title: "Your Feed",
                                                                    }}
                                                                    onClick={() => console.log("shared successfully!")}
                                                                >
                                                                   <a className="share_icon">
                                                                    <img src={ShareIconLg} alt="Share" />
                                                                    <p>Share via</p>
                                                                   </a>
                                                                </RWebShare>
                                                            </>
                                                            <a target="_blank" href={`https://twitter.com/intent/tweet?text=${window.location.href}`} className="share_icon d-none d-md-inline-block">
                                                                <img src={TwitterIconLg} alt="Twitter" />
                                                                <p>Twitter</p>
                                                            </a>
                                                            <a target="_blank" href={`https://twitter.com/intent/tweet?text=${window.location.href}`} className="share_icon d-md-none">
                                                                <img src={TwitterIconLg} alt="Twitter" />
                                                                <p>Twitter</p>
                                                            </a>
                                                            <a target="_blank" href={`https://web.whatsapp.com/send?text=${window.location.href}`} className="share_icon d-none d-md-inline-block">
                                                                <img src={WhatsappIconLg} alt="Whatsapp" />
                                                                <p>Whatsapp</p>
                                                            </a>
                                                            <a target="_blank" href={`https://web.whatsapp.com/send?text=${window.location.href}`} className="share_icon d-md-none">
                                                                <img src={WhatsappIconLg} alt="Whatsapp" />
                                                                <p>Whatsapp</p>
                                                            </a>
                                                            <a target="_blank" href={`https://mail.google.com/mail/?view=cm&fs=1&tf=1&to=&su=&body=+${window.location.href}+&ui=2&tf=1&pli=1'`} className="share_icon d-none d-md-inline-block">
                                                                <img src={EmailIconLg} alt="Email" />
                                                                <p>Email</p>
                                                            </a>
                                                            <a target="_blank" href={`https://mail.google.com/mail/?view=cm&fs=1&tf=1&to=&su=&body=+${window.location.href}+&ui=2&tf=1&pli=1'`} className="share_icon d-md-none">
                                                                <img src={EmailIconLg} alt="Email" />
                                                                <p>Email</p>
                                                            </a>
                                                    </div>
                                                </Accordion.Body>
                                            </Accordion.Item>
                                        </Accordion>
                                        <div className="copy_link">
                                            <p><LinkingIcon fill="rgba(255, 255, 255, 0.5)" /> <span id="website_link">{window.location.origin}</span></p>
                                            <button className="btn" onClick={() => {
                                                let website_link_text = document.getElementById('website_link').innerText;
                                                copy(window.location.origin);
                                                set_copy_link_text('Copied');
                                            }} type="button">{copy_link_text}</button>
                                        </div>
                                        <p className="share_text">Share yourfeed.ai on <strong>Twitter, Snapchat, Email</strong> etc.</p>
                                        <div className="share_close">
                                            <button onClick={() => {
                                                set_copy_link_text('Copy Link');
                                                props.set_open_share_with_fund_popup(false);
                                            }} className="btn">Hide</button>
                                        </div>
                                    </Tab.Pane>
                                    <Tab.Pane eventKey="fund-topic">
                                        <div className="fund_jdi_tab position-relative">
                                            <div className={fund_class}>
                                                <h2>Will you give today?</h2>
                                                <p>We know most people will ignore this message, but if everyone gave a little, we could build cool software as a community.</p>
                                                <div className="protip_txt"><strong>Protip:</strong> You also get a <span className="text-secondary">"founding member badge".</span></div>
                                                <div className="fund_cta">
                                                    <button type="button" className="btn btn-info"
                                                    onClick={() => {
                                                        set_jdi_class('just_do_it_block active');
                                                        set_fund_class('fund_topic_block text-center inactive');
                                                        hidePopupBtn.current.style.display = 'none';
                                                    }}><FundIcon /> Quick donate</button>
                                                    <Link to="/pricing" onClick={(e) => {
                                                        // e.preventDefault();
                                                        // alert(123);
                                                        props.set_open_share_with_fund_popup(false);
                                                    }} className="btn btn-dark"><RightAngleIcon /> Tell me more</Link>
                                                </div>
                                                {/* <ListGroup as="ul" horizontal className="fund_payment_type">
                                                    <ListGroup.Item as="li" className="bg-transparent"><img src={GPayIcon} alt="GPayIcon" /></ListGroup.Item>
                                                    <ListGroup.Item as="li" className="bg-transparent"><img src={VbyVisaIcon} alt="VbyVisaIcon" /></ListGroup.Item>
                                                    <ListGroup.Item as="li" className="bg-transparent"><img src={VisaIcon} alt="VisaIcon" /></ListGroup.Item>
                                                    <ListGroup.Item as="li" className="bg-transparent"><img src={MasterCardIcon} alt="MasterCardIcon" /></ListGroup.Item>
                                                    <ListGroup.Item as="li" className="bg-transparent"><img src={ApplePayIcon} alt="ApplePayIcon" /></ListGroup.Item>
                                                </ListGroup> */}
                                            </div>
                                            <div className={jdi_class}>
                                                <h2 className="text-center text-white">Just do it ;-)</h2>
                                                <p className="text-center text-white">because your help is so valuable</p>
                                                <form action="" method="post">
                                                    <div className="custom_radio">
                                                        <div className="ps-0 mb-0 form-check" >
                                                            <input name="amount" type="radio" id="amount" className={amount1_checked} onClick={() => {changePrice(10);set_amount1_checked("form-check-input checked");set_amount2_checked("form-check-input");set_amount3_checked("form-check-input");}} defaultChecked={amount1_checked} />
                                                            <label  title="" htmlFor="amount" className="form-check-label"><sub>$</sub>10</label>
                                                        </div>
                                                    </div>
                                                    <div className="custom_radio">
                                                        <div className="ps-0 mb-0 form-check" >
                                                            <input name="amount" type="radio" id="amount2" className={amount2_checked} onClick={() => {changePrice(15);set_amount1_checked("form-check-input");set_amount2_checked("form-check-input checked");set_amount3_checked("form-check-input");}} defaultChecked={amount1_checked} />
                                                            <label  title="" htmlFor="amount2" className="form-check-label"><sub>$</sub>15</label>
                                                        </div>
                                                    </div>
                                                    <div className="custom_radio">
                                                        <div className="ps-0 mb-0 form-check" >
                                                            <input name="amount" type="radio" id="amount3" className={amount3_checked} onClick={() => {changePrice(20);set_amount1_checked("form-check-input");set_amount2_checked("form-check-input");set_amount3_checked("form-check-input checked");}} defaultChecked={amount1_checked} />
                                                            <label  title="" htmlFor="amount3" className="form-check-label"><sub>$</sub>20</label>
                                                        </div>
                                                    </div>
                                                    <div className="custom_input_group">
                                                        <span className="d-block">Set your own amount</span>
                                                        <div className="custom_input">
                                                            <div className="custom_input_icon"><img src={Dollersign} alt="Doller sign" /></div>
                                                            <input ref={priceInput} onChange={inputChange} placeholder="maybe $44?" type="text" className="form-control"></input>
                                                        </div>
                                                    </div>
                                                    <button ref={payBtn} type="submit" className="btn form_btn"><LockIcon />Secure payment</button>
                                                </form>
                                                <ListGroup as="ul" horizontal className="fund_payment_type pt-0">
                                                    <ListGroup.Item as="li" className="bg-transparent"><img src={GPayIcon} alt="GPayIcon" /></ListGroup.Item>
                                                    <ListGroup.Item as="li" className="bg-transparent"><img src={VbyVisaIcon} alt="VbyVisaIcon" /></ListGroup.Item>
                                                    <ListGroup.Item as="li" className="bg-transparent"><img src={VisaIcon} alt="VisaIcon" /></ListGroup.Item>
                                                    <ListGroup.Item as="li" className="bg-transparent"><img src={MasterCardIcon} alt="MasterCardIcon" /></ListGroup.Item>
                                                    <ListGroup.Item as="li" className="bg-transparent"><img src={ApplePayIcon} alt="ApplePayIcon" /></ListGroup.Item>
                                                </ListGroup>
                                                <div className="share_close mt-4">
                                                    <button onClick={() => {
                                                        set_jdi_class('just_do_it_block');
                                                        set_fund_class('fund_topic_block text-center active');
                                                        hidePopupBtn.current.style.display = 'block';
                                                    }} className="btn">Back</button>
                                                </div>
                                            </div>
                                        </div>
                                        <div ref={hidePopupBtn} className="share_close">
                                            <button onClick={() => {
                                                set_copy_link_text('Copy Link');
                                                props.set_open_share_with_fund_popup(false);
                                            }} className="btn">Hide</button>
                                        </div>
                                    </Tab.Pane>
                                </Tab.Content>
                        </Tab.Container>
                    </div>
                    </div>
                </div>

            }

        </>

    )
}

export default ShareWithFundModal;