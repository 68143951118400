import React, {useState, useEffect} from "react";
import {Container, Nav, Navbar } from "react-bootstrap";
import { NavLink } from "react-router-dom";
import { useLocation } from "react-router";
import logo from "../assets/img/logo.png";
import Airtable from "airtable";
import ComingSoonModal from './SoonModal';

const Navigation = (props) => {
  // console.log('Navigation',props);
  const location = useLocation();

  const [navbar,set_navbar] = useState([]);
  const [path,set_path] = useState('');
  const [nav_button,set_nav_button] = useState([]);
  const [sliderImages,setSliderImages] = useState([]);
  const [show_soon_popup,set_show_soon_popup] = useState(false);
  const [banner_content,set_banner_content] = useState([]);
  const [tab_content,set_tab_content] = useState([]);
  const [soon_footer_content,set_soon_footer_content] = useState([]);
  const [pathname,set_pathname] = useState(undefined);
  let navigation_key = 0;

  async function get_slider_images()
  {
      var base = new Airtable({apiKey: props.apiKey}).base(props.baseKey);
      let all_slider_images = [];
      await base('images').select({
          // Selecting the first 3 records in Grid view:
          maxRecords: -1,
          view: "Grid view"
      }).eachPage(function page(records, fetchNextPage) {
          // This function (`page`) will get called for each page of records.

          records.forEach(function(record) {
              all_slider_images = [...all_slider_images,record.get('heroImage')];
              return all_slider_images;
          });
          if(all_slider_images.length > 0)
          {
              // console.log('all_slider_images',all_slider_images);
              setSliderImages(all_slider_images);
          }
          fetchNextPage();


      }, function done(err) {
          if (err) { console.error(err); return; }
      });
  }

  async function get_soon_banner_data()
  {
      var base = new Airtable({apiKey: props.apiKey}).base(props.baseKey);
      await base('Coming Soon').select({
          // Selecting the first 3 records in Grid view:
          view: "Grid view",
          filterByFormula:`(LOWER({Name}) = 'banner')`
      }).all().then(records => {
        // console.log('soon banner',records);
        if(records.length > 0)
        {
            set_banner_content(records[0].fields);
        }
      }).catch(err => {
        console.error(err);
      });
  }

  async function get_tab_data()
  {
      var base = new Airtable({apiKey: props.apiKey}).base(props.baseKey);
      await base('Coming Soon').select({
          // Selecting the first 3 records in Grid view:
          view: "Grid view",
          filterByFormula:`(LOWER({Name}) = 'tab_section')`
      }).all().then(records => {
        // console.log('tab_section',records);
        if(records.length > 0)
        {
          set_tab_content(records[0].fields);
        }
      }).catch(err => {
        console.error(err);
      });
  }

  async function get_soon_footer_data()
  {
      var base = new Airtable({apiKey: props.apiKey}).base(props.baseKey);
      await base('Coming Soon').select({
          // Selecting the first 3 records in Grid view:
          view: "Grid view",
          filterByFormula:`(LOWER({Name}) = 'footer')`
      }).all().then(records => {
        // console.log('footer_section',records);
        if(records.length > 0)
        {
          set_soon_footer_content(records[0].fields);
        }
      }).catch(err => {
        console.error(err);
      });
  }


  useEffect(() => {
    get_slider_images();
    get_soon_banner_data();
    get_tab_data();
    get_soon_footer_data();
  }, [])

  useEffect(() => {
    // console.log('show_soon_popup',show_soon_popup);
    // console.log('sliderImages',sliderImages);
    // console.log('banner_content',banner_content);
    // console.log('tab_content',tab_content);
  }, [show_soon_popup,sliderImages,banner_content,tab_content])

  async function get_navbar_data()
  {
      var base = new Airtable({apiKey: props.apiKey}).base(props.baseKey);
      let all_slider_images = [];
      await base('Header').select({
          // Selecting the first 3 records in Grid view:
          view: "Grid view",
      }).all().then(records => {
          // console.log('header_data',records);
          if(records.length > 0)
          {
            set_navbar(records);
          }

      }).catch(err => {
          console.error(err);
      });
  }

  async function get_button(slug)
  {

      if(slug.split('/').length == 6 )
      {
        slug = '/single-quote';
      }
      else if(slug.split('/').length > 2 && slug.split('/')[1] == 'discover' && slug.split('/')[2] == 'topics' )
      {
        // console.log(slug.split('/')[1]);
        // console.log(slug.split('/')[2]);
        // console.log('else if');
        slug = '/single-topic';
      }
      else if(slug.split('/').length > 2 && slug.split('/')[1] == 'discover' && slug.split('/')[2] == 'people' )
      {
        // console.log(slug.split('/')[1]);
        // console.log(slug.split('/')[2]);
        // console.log('else if');
        slug = '/single-profile';
      }




      //console.log('slug',slug);
      var base = new Airtable({apiKey: props.apiKey}).base(props.baseKey);
      let all_slider_images = [];
      await base('Header').select({
          // Selecting the first 3 records in Grid view:
          view: "Grid view",
          filterByFormula:`(LOWER({MenuURL}) = '${slug}')`
      }).all().then(records => {
          // console.log('button_data',records);
          if(records.length > 0)
          {
            set_nav_button(records[0].fields);
          }

      }).catch(err => {
          console.error(err);
      });
  }

  useEffect(() => {
    let navbar_data = get_navbar_data();
  }, []);

  useEffect(() => {
    // console.log('navbar',navbar);
  }, [navbar]);

  useEffect(() => {
    // console.log('nav_button',nav_button);
  }, [nav_button]);

  useEffect(() => {
    if(location !== undefined && location.pathname !== undefined)
    {
      set_pathname(location.pathname.split('/'));
    }
    
    let button = get_button(location.pathname);
  }, [location]);

  useEffect(() => {
  },[pathname])

    return (
          <>
          {
            pathname !== undefined && !pathname.includes('your-future-account')
            ?
              <>
                <ComingSoonModal apiKey={props.apiKey} baseKey={props.baseKey} soon_footer_content={soon_footer_content} tab_content={tab_content} banner_content={banner_content} sliderImages={sliderImages} show_soon_popup={show_soon_popup} set_show_soon_popup={set_show_soon_popup} />

                <Navbar bg="dark" variant="dark">
                  <Container fluid className="justify-content-between">
                  <NavLink className="navbar-brand" exact to="/">
                    <img src={logo} alt="YourFeed.ai" />
                  </NavLink>
                    {
                      navbar !== undefined && navbar.length > 0
                      ?
                      <Nav>
                        {
                          navbar.map((navbar_val) => {
                            //console.log('navbar_val',navbar_val);
                            const show_navbar_item = navbar_val.fields.show !== undefined && navbar_val.fields.show === true ? true : false;
                            navigation_key = navigation_key + 1;
                            let SubText = navbar_val.fields.SubText !== undefined ? navbar_val.fields.SubText : '';
                            if(show_navbar_item === true)
                            {
                              return <NavLink key={navigation_key} className="nav-link" exact to={navbar_val.fields.MenuURL} activeClassName={navbar_val.fields.MenuURL == path ? 'active' : ''}>{navbar_val.fields.Name} { SubText != '' ? <span className="badge">{SubText}</span> : null }</NavLink>
                            }
                          })
                        }
                      </Nav>
                      : null
                    }
                  {
                    nav_button !== undefined && nav_button.button_text !== undefined && nav_button.button_url !== undefined
                    ?
                      <div className="nav_btn">
                        {
                          nav_button.button_url == 'soon_popup'
                          ?
                            <span onClick={ () => set_show_soon_popup(true) } className="btn btn-danger" dangerouslySetInnerHTML={{__html: nav_button.button_text}}></span>
                          :
                            <NavLink exact to={nav_button.button_url} className="btn btn-danger" dangerouslySetInnerHTML={{__html: nav_button.button_text}}></NavLink>
                        }
                      </div>
                    : null
                  }
                  </Container>
                </Navbar>
              </>
            :
             null
          }
          </>
    )
}

export default Navigation;