
import React,{useEffect,useState} from "react";
//import Airtable from "airtable";
import PageLoader from "./components/PageLoader";
import BannerSlider  from "./components/BannerSlider";
import Insight from "./components/Insight";
import CardScroll from "./components/Card-scroll";
import TabSection from "./components/Tab";
import CTASlider from "./components/CTA";
import Airtable from "airtable";
import {Helmet} from "react-helmet";


const Home = (props) => {

    useEffect(() => {
        let seo_default_Data = props.get_seo_data_of_page('Default');
        let seo_page_Data = props.get_seo_data_of_page('Home');
        set_default_page_seo_data(seo_default_Data);
        set_page_seo_title(seo_page_Data);
    }, []);

    



    const [sliderImages,setSliderImages] = useState(undefined);
    const [sliderImagesOptimized,setSliderImagesOptimized] = useState(false);
    const [testimonials,setTestimonials] = useState([]);
    const [testimonialsOptimized,setTestimonialsOptimized] = useState(false);
    const [insights_slider,set_insights_slider] = useState(undefined);
    const [cta_content,set_cta_content] = useState([]);
    const [banner_content,set_banner_content] = useState(undefined);
    const [insight_content,set_insight_content] = useState(undefined);
    const [testimonial_content,set_testimonial_content] = useState([]);
    const [page_loaded,set_page_loaded] = useState(false);
    const [page_seo_data,set_page_seo_title] = useState({title:'',desc:''});
    const [default_page_seo_data,set_default_page_seo_data] = useState({title:'YourFeed',desc:'YourFeed'});

    useEffect(() => {
    }, [default_page_seo_data])

    useEffect(() => {
    }, [insights_slider])

    useEffect(() => {
    }, [cta_content])

    useEffect(() => {
    }, [banner_content])

    useEffect(() => {
        if(sliderImages !== undefined && sliderImages.length > 0 && sliderImagesOptimized === false)
        {
            update_slider_images();
        }
    },[sliderImages])

    useEffect(() => {
        // console.log('home sliders',sliderImages);
    }, [sliderImages])

    useEffect(() => {
        if(testimonials !== undefined && testimonials.length > 0 && testimonialsOptimized === false)
        {
            update_testimonials();
        }
    }, [testimonials])

    useEffect(() => {
    }, [insight_content])

    useEffect(() => {
        let slider_images = get_slider_images();
        let testimonials = get_testimonials();
        let cta_data = get_cta_data();
        let banner_data = get_banner_data();
        let insight_data = get_insight_data();
        let testimonial_data = get_testimonial_data();
        let insight_slider_data = get_insight_slider_data();
    },[]);


    function update_testimonials()
    {
        let final_testimonials = [];
        if(testimonials !== undefined && testimonials.length > 0)
        {
            testimonials.forEach(function(val){
                let testimonial_obj = {};
                testimonial_obj.id = val.id;
                testimonial_obj.name = val.fields.Name;
                testimonial_obj.role = val.fields.role;
                testimonial_obj.testimonial = val.fields.testimonial;
                testimonial_obj.photo = val.fields.photo[0] !== undefined ? {'url':val.fields.photo[0].thumbnails.small.url,'alt':val.fields.photo[0].filename} : undefined;
                final_testimonials = [...final_testimonials,testimonial_obj];
            });
        }
        setTestimonialsOptimized(true);
        setTestimonials(final_testimonials);
    }


    function update_slider_images()
    {
        if(sliderImages.length > 0)
        {
            let final_slide_images = [];
            sliderImages.forEach(function(val,key){
                let imageobj = {};
                if(val.length > 0)
                {
                    final_slide_images = [...final_slide_images,[]];
                    val.forEach(function(inner_img){
                        let img_obj = {};
                        if(inner_img.thumbnails !== undefined && inner_img.thumbnails.large !== undefined)
                        {
                            img_obj.url = inner_img.thumbnails.large.url;
                            img_obj.alt = inner_img.filename;
                            final_slide_images[key].push(img_obj);
                        }
                    });
                }


            });
            setSliderImagesOptimized(true);
            setSliderImages(final_slide_images);
        }

    }

    async function get_cta_data()
    {
        var base = new Airtable({apiKey: props.apiKey }).base(props.baseKey);
        let all_slider_images = [];
        await base('Home').select({
            // Selecting the first 3 records in Grid view:
            view: "Grid view",
            filterByFormula:`(LOWER({Name}) = 'cta')`
        }).all().then(records => {
            // console.log('home_cta',records);
            if(records.length > 0)
            {
                set_cta_content(records[0].fields);
            }

        }).catch(err => {
            // console.error(err);
        });
    }

    async function get_banner_data()
    {
        var base = new Airtable({apiKey: props.apiKey}).base(props.baseKey);
        let all_slider_images = [];
        await base('Home').select({
            // Selecting the first 3 records in Grid view:
            view: "Grid view",
            filterByFormula:`(LOWER({Name}) = 'banner')`
        }).all().then(records => {
            // console.log('banner content',records);
            if(records.length > 0)
            {
                set_banner_content(records[0].fields);
            }

        }).catch(err => {
            // console.error(err);
        });
    }

    async function get_insight_data()
    {
        var base = new Airtable({apiKey: props.apiKey}).base(props.baseKey);
        let all_slider_images = [];
        await base('Home').select({
            // Selecting the first 3 records in Grid view:
            view: "Grid view",
            filterByFormula:`(LOWER({Name}) = 'insight section')`
        }).all().then(records => {
            // console.log('Insight section',records);
            if(records.length > 0)
            {
                set_insight_content(records[0].fields);
            }

        }).catch(err => {
            // console.error(err);
        });
    }

    async function get_insight_slider_data()
    {
        var base = new Airtable({apiKey: props.apiKey}).base(props.baseKey);
        let all_slider_images = [];
        await base('Insights').select({
            // Selecting the first 3 records in Grid view:
            view: "Grid view",
        }).all().then(records => {
            // console.log('Insight_slider_section',records);
            if(records.length > 0)
            {
                set_insights_slider(records);
            }
            set_page_loaded(true);
        }).catch(err => {
            // console.error(err);
            set_page_loaded(true);
        });
    }

    async function get_testimonial_data()
    {
        var base = new Airtable({apiKey: props.apiKey}).base(props.baseKey);
        let all_slider_images = [];
        await base('Home').select({
            // Selecting the first 3 records in Grid view:
            view: "Grid view",
            filterByFormula:`(LOWER({Name}) = 'testimonial')`
        }).all().then(records => {
            // console.log('testimonial_section',records);
            if(records.length > 0)
            {
                set_testimonial_content(records[0].fields);
            }

        }).catch(err => {
            // console.error(err);
        });
    }

    async function get_slider_images()
    {
        var base = new Airtable({apiKey: props.apiKey}).base(props.baseKey);
        let all_slider_images = [];
        await base('images').select({
            // Selecting the first 3 records in Grid view:
            // maxRecords: -1,
            view: "Grid view"
        }).eachPage(function page(records, fetchNextPage) {
            // This function (`page`) will get called for each page of records.

            records.forEach(function(record) {
                all_slider_images = [...all_slider_images,record.get('heroImage')];
                return all_slider_images;
            });
            if(all_slider_images.length > 0)
            {
                setSliderImages(all_slider_images);
            }
            fetchNextPage();


        }, function done(err) {
            if (err) { console.error(err); return; }
        });
    }

    async function get_testimonials()
    {
        var base = new Airtable({apiKey: props.apiKey}).base(props.baseKey);
        await base('testimonial').select({
            // Selecting the first 3 records in Grid view:
            view: "Grid view"
        }).all().then(records => {
            // console.log('testimonial_records',records);
            if(records.length > 0)
            {
                setTestimonials(records);
            }

        }).catch(err => {
            // console.error(err);
        });
    }






    return(
        <>
        <Helmet>
            <title>{page_seo_data.title != '' ? page_seo_data.title : default_page_seo_data.title}</title>
            <meta name="description" content={page_seo_data.desc != '' ? page_seo_data.desc : default_page_seo_data.desc} />
        </Helmet>

        <PageLoader page_loaded={page_loaded} />
        
        {
            sliderImages !== undefined && banner_content !== undefined ? <BannerSlider sliderImages={sliderImages} banner_content={banner_content} /> : null
        }

        {
            testimonials !== undefined && insight_content !== undefined ? <Insight testimonials={testimonials} insight_content={insight_content} apiKey={props.apiKey} baseKey={props.baseKey}/> : null
        }


        {
            insights_slider !== undefined && testimonial_content !== undefined && page_loaded === true ?  <CardScroll insights_slider={insights_slider} testimonial_content={testimonial_content} apiKey={props.apiKey}
            baseKey={props.baseKey} /> : null
        }



        {
            page_loaded === true ? <TabSection apiKey={props.apiKey} baseKey={props.baseKey} /> : null
        }
        {
            sliderImages !== undefined && cta_content !== undefined ? <CTASlider sliderImages={sliderImages} cta_content={cta_content} /> : null
        }

        </>
    )
}

export default Home;


