// import { apiKey } from "airtable";
import React,{ useState } from "react";
import { Container, Row, Col} from "react-bootstrap";
import { Link } from "react-router-dom";
import Slider from "react-slick";
import BtnIcon from "../assets/img/arrows-expand.png"
import MentalModal from "./MentalModal";


const Insight = (props) => {
    var settings = {
        infinite: true,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: false,
        autoplaySpeed: 3000,
        speed: 700,
        arrows: false,
        dots:true
    };

    let testimonial_key = 0;

    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(false);
    const [show_mental_modal,set_show_mental_modal] = useState(false);

    return (
        <>

        <div className="insight_wrapper">
            <Container className="position-relative">
                <div className="d-none title_wrap text-center">
                    {props.insight_content.Title !== undefined ? <h3>{props.insight_content.Title}</h3> : null }
                    {props.insight_content.Description !== undefined ? <p>{props.insight_content.Description}</p> : null }

                </div>
                <div className="d-none title_wrap text-center">
                    {props.insight_content.Title !== undefined ? <h3>{props.insight_content.Title}</h3> : null }
                    {props.insight_content.Description !== undefined ? <p>{props.insight_content.Description}</p> : null }
                </div>
                <div className="insight_wrap">
                    <Row>
                        <Col lg={6} xs={12}>
                            <div className="insight_left_content">
                                {props.insight_content.TitleTwo !== undefined ? <h2>{props.insight_content.TitleTwo}</h2> : null }
                                {props.insight_content.DescriptionTwo !== undefined ? <p dangerouslySetInnerHTML={{__html: props.insight_content.DescriptionTwo}}></p> : null }

                                {props.insight_content.ButtonText !== undefined && props.insight_content.ButtonURL !== undefined ?
                                <Link to={props.insight_content.ButtonURL} className="btn btn-info" onClick={() => {
                                    set_show_mental_modal(true);
                                }} ><span><img src={BtnIcon} alt="arrow" /> {props.insight_content.ButtonText}</span></Link> : null }
                            </div>
                        </Col>
                        <Col lg={6} xs={12}>
                            {
                                props.testimonials !== undefined && props.testimonials.length > 0
                                ?
                                    <Slider {...settings} className="insight_right_content">
                                        {
                                            props.testimonials.map((testimonial_val) => {
                                                testimonial_key = testimonial_key + 1;
                                                let testimonial_text = testimonial_val.testimonial;
                                                let testimonial_author_name = testimonial_val.name;
                                                let testimonial_author_role = testimonial_val.role;
                                                let testimonial_author_image = testimonial_val.photo !== undefined && testimonial_val.photo.url !== undefined ? testimonial_val.photo.url : '';

                                                return <div className="slide" key={testimonial_key}>
                                                            <div className="client_slider">
                                                                <div className="client_slider_content">
                                                                    {testimonial_text}
                                                                </div>
                                                                <div className="d-flex align-items-center">
                                                                    <div className="flex-shrink-0">
                                                                        <img src={testimonial_author_image} alt={testimonial_author_name} />
                                                                    </div>
                                                                    <div className="client_slider_author">
                                                                        <h4>{testimonial_author_name}</h4>
                                                                        <div className="fs-sm">
                                                                            {testimonial_author_role}
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                            })
                                        }
                                    </Slider>
                                : null
                            }

                        </Col>
                    </Row>
                </div>
            </Container>
        </div>

        <MentalModal open_mental_popup={show_mental_modal} set_show_mental_modal={set_show_mental_modal} apiKey={props.apiKey} baseKey={props.baseKey} ></MentalModal>

        </>
    )
}
export default Insight;