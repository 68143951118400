import React, { useState, useEffect, useRef } from "react";
import { Link } from "react-router-dom";
import { Tab, Nav, ListGroup, Button, Spinner, Toast } from "react-bootstrap";
import HomeIcon from "./assets/img/home-icon.png";
import Dots from "./assets/img/dots.png";
import LinkIcon from "./assets/img/link.png";
import ShareIcon from "./assets/img/mdi_share.png";
import Airtable from "airtable";
import PeopleShareModal from './components/people-share-modal';
import PageLoader from "./components/PageLoader";
// import ShareModal from './components/ShareModal';
import TopicShareModal from './components/TopicShareModal';
import GPayIcon from "./assets/img/g-pay-ic.svg";
import VbyVisaIcon from "./assets/img/verifie-bt-visa-ic.svg";
import VisaIcon from "./assets/img/visa-ic.svg";
import MasterCardIcon from "./assets/img/mastercard-ic.svg";
import ApplePayIcon from "./assets/img/apple-pay-ic.svg";
import Dollersign from "./assets/img/doller-sign.png"
import BlobShape from "./assets/img/blob-shape.svg";
import {Helmet} from "react-helmet";
import {
    FlashIcon,
    SharingIcon,
    FundIcon,
    LinkingIcon,
    RightAngleIcon,
    FaceBookIcon,
    TweetIcon,
    RedditIcon,
    LockIcon,
    InstagramIcon
} from "./assets/icons";

const Topics = (props) => {
    const [showA, setShowA] = useState(false);

    const toggleShowA = () => setShowA(!showA);

    const [copy_link_text, set_copy_link_text] = useState('Copy Link');

    useEffect(() => {
        let seo_default_Data = props.get_seo_data_of_page('Default');
        let seo_page_Data = props.get_seo_data_of_page('Topic');
        set_default_page_seo_data(seo_default_Data);
        set_page_seo_title(seo_page_Data);
    }, []);

    // NOTE :  Here we are adding all states of this page
    const [tag_slug,set_tag_slug] = useState('');
    let [tag_name,set_tag_name] = useState('');
    const [tag_discription,set_tag_discription] = useState('');
    const [error,set_error] = useState('');
    const [allQuotes,setAllQuotes] = useState([]);
    const [profileQuotesText,setProfileQuotesText] = useState([]);
    const [profileQuotesText2,setProfileQuotesText2] = useState([]);
    const [current_quote_page,set_current_quote_page] = useState(0);
    const [total_quotes_length,set_total_quotes_length] = useState(0);
    const [total_quotes_pages_length,set_total_quotes_pages_length] = useState(0);
    const [allPersons,setAllPersons] = useState([]);
    const per_page_quotes = 10;
    const [open_people_popup,set_open_people_popup] = useState(false);
    const [show_share_modal,set_show_share_modal] = useState(false);
    const [people_popup_data,set_people_popup_data] = useState({username:'',user_profile_link:'',slug:''});
    const [loading,set_loading] = useState(false);
    const [page_loaded,set_page_loaded] = useState(false);
    const set_open_people_popup_delay = 100;
    const [copy_tag_link_text,set_copy_tag_link_text] = useState('Copy Link');
    const [page_seo_data,set_page_seo_title] = useState({title:'',desc:''});
    const [default_page_seo_data,set_default_page_seo_data] = useState({title:'YourFeed',desc:'YourFeed'});
    let topics_key = 0;
    let topics_key_mobile = 0;
    const [jdi_class,set_jdi_class] = useState('just_do_it_block');
    const [fund_class,set_fund_class] = useState('fund_topic_block text-center');
    const [price,set_price] = useState('');
    const [amount1_checked,set_amount1_checked] = useState("form-check-input");
    const [amount2_checked,set_amount2_checked] = useState("form-check-input");
    const [amount3_checked,set_amount3_checked] = useState("form-check-input");
    const [final_quotes,set_final_quotes] = useState([]);

    useEffect(() => {
        if(price > 0)
        {
            payBtn.current.style.display = 'block';
        }
        else
        {
            payBtn.current.style.display = 'none';
        }
        priceInput.current.value = price;
    },[price])

    async function get_all_persons()
    {
        var base = new Airtable({apiKey: props.apiKey}).base(props.baseKey);
        let all_slider_images = [];
        await base('persons').select({
            // Selecting the first 3 records in Grid view:
            view: "Grid view"
        }).all().then(records => {
            if(records.length > 0)
            {
                let all_persons_with_key = allPersons;
                records.forEach((all_person_val) => {

                    all_persons_with_key[all_person_val['id']] = all_person_val.fields;
                })
                setAllPersons([all_persons_with_key]);
            }
        }).catch(err => {
            console.error(err);
        });
    }

    useEffect(() => {
        get_all_persons();
    }, [])


    // on total page length change we fire request
    useEffect(() => {
        get_author_quotes();
        window.scroll({top: 0, left: 0, behavior: 'smooth' });
    }, [total_quotes_pages_length])

    // NOTE :  On change of the match props
    useEffect(() => {
       let tag_slug = props.match.params.id;
       if(tag_slug != undefined && tag_slug != '')
       {
            let tag_name = tag_slug.split('-');
            tag_name = tag_name.map( a => a.charAt(0).toUpperCase() + a.substr(1) ).join(' ');
            set_tag_slug(tag_slug);
            set_tag_name(tag_name)
            set_error('');
       }
       else
       {
            set_error('Tag name is not found');
       }
    }, [props]);

    async function get_author_quotes()
    {
        if(current_quote_page<total_quotes_pages_length)
        {
            set_loading(true);
            setTimeout(function(){
                set_current_quote_page(current_quote_page+1);
                set_open_people_popup(false);
                set_loading(false);
            },500);
        }

    }

    function shuffle(array) {
        let currentIndex = array.length,  randomIndex;
        while (currentIndex != 0) {
            randomIndex = Math.floor(Math.random() * currentIndex);
            currentIndex--;
            [array[currentIndex], array[randomIndex]] = [
            array[randomIndex], array[currentIndex]];
        }

        return array;
    }

    async function get_tag_details()
    {
        var base = new Airtable({apiKey: props.apiKey}).base(props.baseKey);
        tag_name = tag_name.toLowerCase();
        let find_tag = await base('quoteTags').select({
            // Selecting the first 3 records in Grid view:
            view: "Grid view",
            filterByFormula:`(LOWER({name}) = '${tag_name}')`
        }).all();
        if(find_tag.length > 0)
        {
            set_tag_discription(find_tag[0]['fields'].description);
            setAllQuotes(shuffle(find_tag[0]['fields'].quotes));
            set_total_quotes_length(find_tag[0]['fields'].quotes.length);
            // set_total_quotes_pages_length( Math.ceil(find_tag[0]['fields'].quotes.length/per_page_quotes) );
        }
    }




    // NOTE : On tag name change we will sending request and get data
    useEffect(() => {
        if(tag_name !== undefined && tag_name !== null &&  tag_name !== '')
        {
            get_tag_details();
            get_all_quotes();
        }
    }, [tag_name])

    async function get_all_quotes()
    {
        var base = new Airtable({apiKey: props.apiKey}).base(props.baseKey);
        let find_tag = await base('quotes').select({
            view: "Grid view",
            filterByFormula:`AND(FIND("${tag_name}",LOWER({topics}))>0,{RemainingChar}>=0)`
        }).all();

        if(find_tag.length > 0)
        {
            set_final_quotes(shuffle(find_tag));
            set_total_quotes_pages_length( Math.ceil(find_tag.length/per_page_quotes) );
        }
        set_page_loaded(true);
    }

    function copy(text) {
        var input = document.createElement('input');
        input.setAttribute('value', text);
        document.body.appendChild(input);
        input.select();
        var result = document.execCommand('copy');
        document.body.removeChild(input);
        return result;
     }


    function changePrice(price=0)
    {
        set_price(price);
    }

    const inputChange = (event) => {
        const re = /^[0-9\b]+$/;
        if (event.target.value !== '' && re.test(event.target.value) === true ) {
            set_price( !isNaN(event.target.value) && event.target.value > 0 ? event.target.value : '');
            priceInput.current.value = !isNaN(event.target.value) && event.target.value > 0 ? event.target.value : ''
        }
        else if(event.target.value == '')
        {
            set_price('');
            priceInput.current.value = price > 0 ? price : '';
        }
        else
        {
            priceInput.current.value = price > 0 ? price : '';
        }

      };

    const payBtn = useRef(null);
    const priceInput = useRef(null);

    useEffect(() => {
    },[current_quote_page]);

    return(
        <>
        <Helmet>
            <title>{page_seo_data.title != '' ? page_seo_data.title : default_page_seo_data.title}</title>
            <meta name="description" content={page_seo_data.desc != '' ? page_seo_data.desc : default_page_seo_data.desc} />
        </Helmet>
        <div className="topic_page">
            <PageLoader page_loaded={page_loaded} />
            <div className="hero_wrap">
                <div className="container">
                    <div className="breadcrumbs">
                        <ul className="list-unstyled">
                            <li><Link to="/"><img src={HomeIcon} alt="home" /></Link></li>
                            <li><Link to="/discover">discover</Link></li>
                            <li>topics</li>
                            <li>{tag_name}</li>
                        </ul>
                    </div>
                    <div className="hero_text_wrap">
                        <div>
                            <h1>{tag_name} quotes</h1>
                            <p>{tag_discription}</p>

                            <Button onClick={toggleShowA} className="hero_share p-0"><img src={ShareIcon} alt="share" /></Button>
                            <Toast show={showA} onClose={toggleShowA}>
                                <Toast.Header>Click the links. Share the stuff.</Toast.Header>
                                <Toast.Body>
                                <ul className="list-unstyled social_share">
                                    <li className="twitter">
                                        <a target="_blank" rel='noopener noreferrer' href={"https://twitter.com/intent/tweet?text="+window.location.href}>
                                            <div className="share_icon"><TweetIcon /></div> Twitter
                                        </a>
                                    </li>
                                    <li className="fb">
                                        <a to="void(0);" onClick={() => {
                                            window.open('https://www.facebook.com/sharer/sharer.php?u=' + window.location.href + '&title=Your Feed', 'fbShareWindow', 'height=450, width=550, top=' + (window.innerHeight / 2 - 275) + ', left=' + (window.innerWidth / 2 - 225) + ', toolbar=0, location=0, menubar=0, directories=0, scrollbars=0');
                                        }}>
                                            <div className="share_icon"><FaceBookIcon /></div> Facebook
                                        </a>
                                    </li>
                                    <li className="reddit">
                                        <a target="_blank" rel='noopener noreferrer' href={'https://www.reddit.com/submit?url='+window.location.href}>
                                            <div className="share_icon"><RedditIcon /></div> Reddit
                                        </a>
                                    </li>
                                    <li className="link d-md-none">
                                        <a href="void(0);" onClick={(e) => {
                                                e.preventDefault();
                                                let website_link_text = document.getElementById('website_link').innerText;
                                                copy(website_link_text);
                                                set_copy_link_text('Link copied');
                                            }}>
                                            <div className="share_icon"><img src={LinkIcon} alt="LinkIcon" /></div> {copy_link_text} <span className="d-none" id="website_link">{window.location.href}</span>
                                        </a>
                                    </li>
                                    <li className="link d-md-block d-none">
                                        <a href="void(0);">
                                            <div className="share_icon">
                                                <img src={LinkIcon} alt="LinkIcon" />
                                            </div>
                                            <button type="button" className="btn copy_btn" onClick={(e) => {
                                                e.preventDefault();
                                                let website_link_text = document.getElementById('website_link').innerHTML;
                                                copy(website_link_text);
                                                set_copy_link_text('Link copied');
                                            }}>{copy_link_text} </button>
                                        </a>
                                    </li>
                                </ul>
                                <Link to="/pricing" className="fund_btn"><FundIcon fill="#FEDF30" />Fund Yourfeed.ai</Link>
                            </Toast.Body>
                            </Toast>
                        </div>
                    </div>
                </div>
            </div>
            {
                final_quotes !== undefined && final_quotes.length > 0
                ?
                    <>
                        <div className="card_wraper d-none d-md-block">
                            <div className="container">
                            {
                                final_quotes.slice(0,3).map((first3_quotes) => {
                                    let share_quote_id = first3_quotes.id;
                                    first3_quotes = first3_quotes.fields;
                                    topics_key = topics_key + 1;
                                    let first3_quotes_quote = first3_quotes.text;
                                    let first3_quotes_author_id = first3_quotes !== undefined && first3_quotes.author !== undefined && first3_quotes.author[0] !== undefined ? first3_quotes.author[0] : '';
                                    let author_details = allPersons[0][first3_quotes_author_id] !== undefined ? allPersons[0][first3_quotes_author_id] : [];
                                    let author_name = author_details.name !== undefined ? author_details.name : '';
                                    let author_role = author_details.role !== undefined ? author_details.role : '';
                                    let author_image = author_details.photo !== undefined && author_details.photo[0] !== undefined && author_details.photo[0].thumbnails !== undefined ? author_details.photo[0].thumbnails.large.url : author_details.photo[0].url;
                                    let author_alt = author_details.photo !== undefined && author_details.photo[0] !== undefined && author_details.photo[0].thumbnails !== undefined ? author_details.photo[0].thumbnails.large.filename : author_details.photo[0].filename;
                                    let author_slug = author_name.toLowerCase().split(' ').join('-');
                                    let author_image_photo_extension =  author_details.photo[0] !== undefined ? author_details.photo[0].type.split('/').reverse()[0] : '';

                                    return  <div className="card_block" key={topics_key} data-id={topics_key}>
                                                <div className="card_block_img flex-shrink-0">
                                                    {
                                                        author_image_photo_extension == 'mp4'
                                                        ?
                                                            <video width="130px" height="100%" className="" src={author_image} autoPlay muted loop controls={false}></video>

                                                        :
                                                            <img src={author_image} alt={author_alt} />
                                                    }
                                                </div>
                                                <div className="card_block_right">
                                                    <div className="card_block_author">
                                                        <div>
                                                            <Link to={`/discover/people/${author_slug}`}><h4>{author_name}</h4></Link>
                                                            <div className="fs-sm">{author_role}</div>
                                                        </div>
                                                        <span  onClick={() => {
                                                            setTimeout(function(){
                                                                set_open_people_popup(true);
                                                                set_people_popup_data({username:author_name,user_profile_link:'discover/people/'+author_slug,slug:author_slug,quote_id:share_quote_id,profile_page:true});
                                                            },set_open_people_popup_delay);

                                                        }}><img src={Dots} alt="dots" /></span>
                                                    </div>
                                                    <div className="card_block_content">
                                                        {first3_quotes_quote}
                                                    </div>
                                                </div>
                                            </div>
                                })
                            }
                            </div>
                        </div>
                        <div className="card_wraper d-md-none">
                            <div className="container">
                                {
                                    final_quotes.slice(0,3).map((first3_quotes) => {
                                        let share_quote_id = first3_quotes.id;
                                        first3_quotes = first3_quotes.fields;
                                        topics_key = topics_key + 1;
                                        let first3_quotes_quote = first3_quotes.text;
                                        let first3_quotes_author_id = first3_quotes !== undefined && first3_quotes.author !== undefined && first3_quotes.author[0] !== undefined ? first3_quotes.author[0] : '';;
                                        let author_details = allPersons[0][first3_quotes_author_id] !== undefined ? allPersons[0][first3_quotes_author_id] : [];
                                        let author_name = author_details.name !== undefined ? author_details.name : '';
                                        let author_role = author_details.role !== undefined ? author_details.role : '';
                                        let author_image = author_details.photo !== undefined && author_details.photo[0] !== undefined && author_details.photo[0].thumbnails !== undefined ? author_details.photo[0].thumbnails.large.url : author_details.photo[0].url;
                                        let author_alt = author_details.photo !== undefined && author_details.photo[0] !== undefined && author_details.photo[0].thumbnails !== undefined ? author_details.photo[0].thumbnails.large.filename : author_details.photo[0].filename;
                                        let author_slug = author_name.toLowerCase().split(' ').join('-');
                                        let author_image_photo_extension =  author_details.photo[0] !== undefined ? author_details.photo[0].type.split('/').reverse()[0] : '';

                                        return  <div className="card_block" key={topics_key} data-id={topics_key_mobile}>
                                                    <div className="card_block_top">
                                                        <div className="card_block_img flex-shrink-0">
                                                        {
                                                            author_image_photo_extension == 'mp4'
                                                            ?
                                                                <video width="55px" height="100%" className="" src={author_image} autoPlay muted loop controls={false}></video>

                                                            :
                                                                <img src={author_image} alt={author_alt} />
                                                        }
                                                        </div>
                                                        <div className="card_block_author">
                                                            <div>
                                                                <Link to={`/discover/people/${author_slug}`}><h4>{author_name}</h4></Link>
                                                                <div className="fs-sm">{author_role}</div>
                                                            </div>
                                                            <span onClick={() => {
                                                                setTimeout(function(){

                                                                    set_open_people_popup(true);
                                                                    set_people_popup_data({username:author_name,user_profile_link:'discover/people/'+author_slug,slug:author_slug,quote_id:share_quote_id,profile_page:true});
                                                                },set_open_people_popup_delay);
                                                            }} ><img src={Dots} alt="dots" /></span>
                                                        </div>
                                                    </div>
                                                    <div className="card_block_content">
                                                        {first3_quotes_quote}
                                                    </div>
                                                </div>
                                    })
                                }
                            </div>
                        </div>
                    </>
            : null
            }

            <div className="copy_wrap">
                <div className="container">
                    <div className="copy_wrap_tab_block">
                        <Tab.Container id="topics-tabs" defaultActiveKey="share-topic">
                            <Nav className="topics_tab_links justify-content-center">
                                <Nav.Item>
                                    <Nav.Link eventKey="share-topic"><SharingIcon /> Share</Nav.Link>
                                </Nav.Item>
                                <Nav.Item>
                                    <Nav.Link eventKey="fund-topic"><FundIcon /> Fund Yourfeed.ai</Nav.Link>
                                </Nav.Item>
                            </Nav>
                            <Tab.Content>
                                    <Tab.Pane eventKey="share-topic">
                                        <div className="link_wrap">
                                            <p><LinkingIcon /> <span id="copy_tag_link">{window.location.origin}</span></p>
                                            <button className="btn copy_btn" onClick={() => {
                                                copy(window.location.origin);
                                                set_copy_tag_link_text('Copied');
                                            }}>{copy_tag_link_text}</button>
                                        </div>
                                        <div className="copy_text">
                                            <img src={BlobShape} alt="BlobShape" className="blob_shape" />
                                            Share yourfeed.ai on <strong>Twitter</strong>, <strong>Snapchat</strong>, <strong>Email</strong> etc.
                                        </div>
                                    </Tab.Pane>
                                    <Tab.Pane eventKey="fund-topic">
                                        <div className="fund_jdi_tab position-relative">
                                            <div className={fund_class}>
                                                <h2>Will you give today?</h2>
                                                <p>We know most people will ignore this message, but if everyone gave a little, we could build cool software as a community.</p>
                                                <div className="protip_txt"><strong>Protip:</strong> You also get a <span className="text-secondary">"founding member badge".</span></div>
                                                <div className="fund_cta">
                                                    <button type="button" className="btn btn-info"
                                                    onClick={() => {
                                                        set_jdi_class('just_do_it_block active');
                                                        set_fund_class('fund_topic_block text-center inactive');
                                                    }}><FundIcon /> Quick donate</button>
                                                    <Link to="/pricing" className="btn btn-dark"><RightAngleIcon /> Tell me more</Link>
                                                </div>
                                                <ListGroup as="ul" horizontal className="fund_payment_type">
                                                    <ListGroup.Item as="li" className="bg-transparent"><img src={GPayIcon} alt="GPayIcon" /></ListGroup.Item>
                                                    <ListGroup.Item as="li" className="bg-transparent"><img src={VbyVisaIcon} alt="VbyVisaIcon" /></ListGroup.Item>
                                                    <ListGroup.Item as="li" className="bg-transparent"><img src={VisaIcon} alt="VisaIcon" /></ListGroup.Item>
                                                    <ListGroup.Item as="li" className="bg-transparent"><img src={MasterCardIcon} alt="MasterCardIcon" /></ListGroup.Item>
                                                    <ListGroup.Item as="li" className="bg-transparent"><img src={ApplePayIcon} alt="ApplePayIcon" /></ListGroup.Item>
                                                </ListGroup>
                                            </div>
                                            <div className={jdi_class}>
                                                <h2 className="text-center text-white">Just do it ;-)</h2>
                                                <p className="text-center text-white">because your help is so valuable</p>
                                                <form action="" method="post">
                                                    <div className="custom_radio">
                                                        <div className="ps-0 mb-0 form-check">
                                                            <input name="amount" type="radio" id="amount" className={amount1_checked} onClick={() => {changePrice(10);set_amount1_checked("form-check-input checked");set_amount2_checked("form-check-input");set_amount3_checked("form-check-input");}}/>
                                                            <label title="" htmlFor="amount" className="form-check-label"><sub>$</sub>10</label>
                                                        </div>
                                                    </div>
                                                    <div className="custom_radio">
                                                        <div className="ps-0 mb-0 form-check">
                                                            <input name="amount" type="radio" id="amount2" className={amount2_checked} onClick={() => {changePrice(15);set_amount1_checked("form-check-input");set_amount2_checked("form-check-input checked");set_amount3_checked("form-check-input");}}/>
                                                            <label title="" htmlFor="amount2" className="form-check-label"><sub>$</sub>15</label>
                                                        </div>
                                                    </div>
                                                    <div className="custom_radio">
                                                        <div className="ps-0 mb-0 form-check">
                                                            <input name="amount" type="radio" id="amount3" className={amount3_checked} onClick={() => {changePrice(20);set_amount1_checked("form-check-input");set_amount2_checked("form-check-input");set_amount3_checked("form-check-input checked");}}/>
                                                            <label title="" htmlFor="amount3" className="form-check-label"><sub>$</sub>20</label>
                                                        </div>
                                                    </div>
                                                    <div className="custom_input_group">
                                                        <span className="d-block">Set your own amount</span>
                                                        <div className="custom_input">
                                                            <div className="custom_input_icon"><img src={Dollersign} alt="Doller sign" /></div>
                                                            <input ref={priceInput} onChange={inputChange} placeholder="maybe $44?" defaultValue={price} type="text" className="form-control"></input>
                                                        </div>
                                                    </div>
                                                    <button ref={payBtn} type="submit" className="btn form_btn"><LockIcon />Secure payment</button>
                                                </form>
                                                <ListGroup as="ul" horizontal className="fund_payment_type pt-0">
                                                    <ListGroup.Item as="li" className="bg-transparent"><img src={GPayIcon} alt="GPayIcon" /></ListGroup.Item>
                                                    <ListGroup.Item as="li" className="bg-transparent"><img src={VbyVisaIcon} alt="VbyVisaIcon" /></ListGroup.Item>
                                                    <ListGroup.Item as="li" className="bg-transparent"><img src={VisaIcon} alt="VisaIcon" /></ListGroup.Item>
                                                    <ListGroup.Item as="li" className="bg-transparent"><img src={MasterCardIcon} alt="MasterCardIcon" /></ListGroup.Item>
                                                    <ListGroup.Item as="li" className="bg-transparent"><img src={ApplePayIcon} alt="ApplePayIcon" /></ListGroup.Item>
                                                </ListGroup>
                                                <div className="share_close mt-4">
                                                    <button onClick={() => {
                                                        set_jdi_class('just_do_it_block');
                                                        set_fund_class('fund_topic_block text-center active');
                                                    }} className="btn">Back</button>
                                                </div>
                                            </div>
                                        </div>
                                    </Tab.Pane>
                                </Tab.Content>
                        </Tab.Container>
                    </div>
                </div>
            </div>

            {/* LAST ALL QUOTES */}

            {
                final_quotes !== undefined && final_quotes.length > 3
                ?
                    <>
                        <div className="card_wraper d-none d-md-block">
                            <div className="container">
                                {
                                    final_quotes.slice(3,(current_quote_page*per_page_quotes)).map((last_quotes) => {
                                            let share_quote_id = last_quotes.id;
                                            last_quotes = last_quotes.fields;
                                            topics_key = topics_key + 1;
                                            let last_quotes_quote = last_quotes.text;
                                            let last_quotes_author_id = last_quotes !== undefined && last_quotes.author !== undefined ? last_quotes.author[0] : '';
                                            let author_details = allPersons[0][last_quotes_author_id] !== undefined ? allPersons[0][last_quotes_author_id] : [];
                                            let author_name = author_details.name !== undefined ? author_details.name : '';
                                            let author_role = author_details.role !== undefined ? author_details.role : '';
                                            let author_image = author_details.photo !== undefined && author_details.photo[0] !== undefined && author_details.photo[0].thumbnails !== undefined ? author_details.photo[0].thumbnails.large.url : author_details.photo !== undefined && author_details.photo[0] !== undefined ? author_details.photo[0].url : '';
                                            let author_alt = author_details.photo !== undefined && author_details.photo[0] !== undefined && author_details.photo[0].thumbnails !== undefined ? author_details.photo[0].thumbnails.large.filename : author_details.photo !== undefined && author_details.photo[0] !== undefined ? author_details.photo[0].filename : '';
                                            let author_slug = author_name.toLowerCase().split(' ').join('-');
                                            let author_image_photo_extension =  author_details.photo[0] !== undefined ? author_details.photo[0].type.split('/').reverse()[0] : '';
                                            return <div className="card_block" key={topics_key} data-id={topics_key}>
                                                        <div className="card_block_img flex-shrink-0">
                                                            {
                                                                author_image_photo_extension == 'mp4'
                                                                ?
                                                                    <video width="130px" height="100%" className="" src={author_image} autoPlay muted loop controls={false}></video>

                                                                :
                                                                    <img src={author_image} alt={author_alt} />
                                                            }
                                                        </div>
                                                        <div className="card_block_right">
                                                            <div className="card_block_author">
                                                                <div>
                                                                    <Link to={`/discover/people/${author_slug}`}><h4>{author_name}</h4></Link>
                                                                    <div className="fs-sm">{author_role}</div>
                                                                </div>
                                                                <span  onClick={() => {
                                                                    setTimeout(() => {
                                                                        set_open_people_popup(true);
                                                                        set_people_popup_data({username:author_name,user_profile_link:'discover/people/'+author_slug,slug:author_slug,quote_id:share_quote_id,profile_page:true});
                                                                    }, set_open_people_popup_delay);

                                                                }} ><img src={Dots} alt="dots" /></span>
                                                            </div>
                                                            <div className="card_block_content">
                                                                {last_quotes_quote}
                                                            </div>
                                                        </div>
                                                    </div>
                                    })
                                }
                                {
                                    total_quotes_pages_length > 1 && current_quote_page < total_quotes_pages_length
                                    ?


                                            <div className="btn_wrap text-center">
                                                <Button className="btn more_btn" onClick={() =>{get_author_quotes()}}><FlashIcon /> More
                                                    {
                                                        loading === true
                                                        ?
                                                            <Spinner as="span" animation="border" className="ms-2" size="sm" role="status" aria-hidden="true">
                                                                <span className="visually-hidden">Loading...</span>
                                                            </Spinner>
                                                        : null
                                                    }
                                                </Button>
                                            </div>

                                    : null
                                }
                            </div>
                        </div>
                        <div className="card_wraper d-md-none">
                            <div className="container">
                            {
                                    final_quotes.slice(3,(current_quote_page*per_page_quotes)).map((last_quotes) => {
                                            topics_key = topics_key + 1;
                                            last_quotes = last_quotes.fields;
                                            let share_quote_id = last_quotes.id;
                                            let last_quotes_quote = last_quotes.text;
                                            let last_quotes_author_id = last_quotes !== undefined && last_quotes.author !== undefined ? last_quotes.author[0] : '';
                                            let author_details = allPersons[0][last_quotes_author_id] !== undefined ? allPersons[0][last_quotes_author_id] : [];
                                            let author_name = author_details.name !== undefined ? author_details.name : '';
                                            let author_role = author_details.role !== undefined ? author_details.role : '';
                                            let author_image = author_details.photo !== undefined && author_details.photo[0] !== undefined && author_details.photo[0].thumbnails !== undefined ? author_details.photo[0].thumbnails.large.url : author_details.photo !== undefined && author_details.photo[0] !== undefined ? author_details.photo[0].url : '';
                                            let author_alt = author_details.photo !== undefined && author_details.photo[0] !== undefined && author_details.photo[0].thumbnails !== undefined ? author_details.photo[0].thumbnails.large.filename : author_details.photo !== undefined && author_details.photo[0] !== undefined ? author_details.photo[0].filename : '';
                                            let author_slug = author_name.toLowerCase().split(' ').join('-');
                                            let author_image_photo_extension =  author_details.photo[0] !== undefined ? author_details.photo[0].type.split('/').reverse()[0] : '';
                                            return  <div className="card_block" key={topics_key} data-id={topics_key_mobile}>
                                                        <div className="card_block_top">
                                                            <div className="card_block_img flex-shrink-0">
                                                                {
                                                                    author_image_photo_extension == 'mp4'
                                                                    ?
                                                                        <video width="55px" height="100%" className="" src={author_image} autoPlay muted loop controls={false}></video>
                                                                    :
                                                                        <img src={author_image} alt={author_alt} />
                                                                }
                                                            </div>
                                                            <div className="card_block_author">
                                                                <div>
                                                                    <Link to={`/discover/people/${author_slug}`}><h4>{author_name}</h4></Link>
                                                                    <div className="fs-sm">{author_role}</div>
                                                                </div>
                                                                <span  onClick={() => {
                                                                    setTimeout(() => {

                                                                        set_open_people_popup(true);
                                                                        set_people_popup_data({username:author_name,user_profile_link:'discover/people/'+author_slug,slug:author_slug,quote_id:share_quote_id,profile_page:true});
                                                                    },set_open_people_popup_delay);
                                                                }} ><img src={Dots} alt="dots" /></span>
                                                            </div>
                                                        </div>
                                                        <div className="card_block_content">
                                                            {last_quotes_quote}
                                                        </div>
                                                    </div>
                                    })
                                }
                                {
                                    total_quotes_pages_length > 1 && current_quote_page < total_quotes_pages_length
                                    ?

                                            <div className="btn_wrap text-center">
                                                <Button className="btn more_btn" onClick={() =>{get_author_quotes()}}><FlashIcon /> More
                                                    {
                                                        loading === true
                                                        ?
                                                            <Spinner as="span" animation="border" className="ms-2" size="sm" role="status" aria-hidden="true">
                                                                <span className="visually-hidden">Loading...</span>
                                                            </Spinner>
                                                        : null
                                                    }
                                                </Button>
                                            </div>
                                        : null

                                }

                            </div>
                        </div>
                    </>
                : null
            }
            <PeopleShareModal open_people_popup={open_people_popup} set_open_people_popup={set_open_people_popup} people_popup_data={people_popup_data} />
            <TopicShareModal open_share_popup={show_share_modal} set_show_share_modal={set_show_share_modal} share_links={
                {
                    facebook : 'https://www.facebook.com/sharer/sharer.php?u=' + window.location.href + '&title=Your Feed, fbShareWindow,height=450, width=550, top=' + (window.innerHeight / 2 - 275) + ', left=' + (window.innerWidth / 2 - 225) + ', toolbar=0, location=0, menubar=0, directories=0, scrollbars=0',
                    twitter : `https://twitter.com/intent/tweet?text=${window.location.href}`,
                    whatsapp : `https://web.whatsapp.com/send?text=${window.location.href}`,
                    email : `https://mail.google.com/mail/?view=cm&fs=1&tf=1&to=&su=&body=${window.location.href}&ui=2&tf=1&pli=1`,
                }
            } />

        </div>
        </>
    )
}

export default Topics;