import React from "react";

const SVG = ({
  style = {},
  fill = "#FEDF30",
  stroke="none",
  width = "14",
  height = "22",
  className = "",
  viewBox = "0 0 14 22"
}) => (
  <svg
    width={width}
    style={style}
    height={height}
    viewBox={viewBox}
    xmlns="http://www.w3.org/2000/svg"
    className={`svg-icon ${className || ""}`}
    xmlnsXlink="http://www.w3.org/1999/xlink"
  >
<path fill={fill} stroke={stroke} d="M12.7462 9.00141H7.64762C7.60313 9.00142 7.55915 8.99197 7.51859 8.97369C7.47802 8.95541 7.44181 8.92873 7.41234 8.8954C7.38286 8.86207 7.36081 8.82286 7.34763 8.78037C7.33446 8.73788 7.33046 8.69307 7.3359 8.64891L8.35543 0.359538C8.35973 0.322278 8.35105 0.284676 8.33086 0.253065C8.31068 0.221454 8.28021 0.197764 8.24461 0.185986C8.209 0.174208 8.17042 0.175061 8.13536 0.188401C8.10031 0.201741 8.07092 0.226753 8.05215 0.259226L1.0209 12.5986C0.997758 12.6392 0.985668 12.6851 0.985842 12.7318C0.986015 12.7785 0.998444 12.8243 1.02189 12.8647C1.04533 12.9051 1.07897 12.9386 1.11943 12.9619C1.15989 12.9852 1.20577 12.9975 1.25246 12.9975H6.35199C6.39648 12.9975 6.44047 13.007 6.48103 13.0252C6.52159 13.0435 6.55781 13.0702 6.58728 13.1035C6.61675 13.1368 6.63881 13.1761 6.65198 13.2185C6.66516 13.261 6.66916 13.3059 6.66371 13.35L5.64418 21.6408C5.63989 21.678 5.64856 21.7157 5.66875 21.7473C5.68894 21.7789 5.7194 21.8026 5.75501 21.8143C5.79062 21.8261 5.8292 21.8253 5.86425 21.8119C5.89931 21.7986 5.92869 21.7736 5.94746 21.7411L12.9787 9.40173C13.0022 9.36106 13.0146 9.3149 13.0146 9.26792C13.0145 9.22094 13.0021 9.1748 12.9785 9.13417C12.9549 9.09354 12.921 9.05987 12.8802 9.03656C12.8394 9.01325 12.7932 9.00112 12.7462 9.00141Z" />
  </svg>
);

export default SVG;
