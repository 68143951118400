import React,{useState, useEffect, useRef} from "react";
import { Link } from "react-router-dom";
import { Modal } from "react-bootstrap";
import herovideo from "../assets/img/hero-video.png";
import infoicon from "../assets/img/infoicon.png";
import paymentcards from "../assets/img/payment-cards.png";
import playbtn from "../assets/img/play-btn.png";
// import CloseImg from "../assets/img/close.png";
// import FBIcon from "../assets/img/fb-ic.png";
// import MsgIcon from "../assets/img/fb_msg-ic.png";
// import TwitterIcon from "../assets/img/twitter-ic.png";
// import WhatsappIcon from "../assets/img/whatsapp-ic.png";
// import EmailIcon from "../assets/img/email-icon-sm.png";
// import LinkIcon from "../assets/img/link-ic.png";
import Airtable from "airtable";
import { Player, PosterImage  } from 'video-react';
import Ratio from 'react-bootstrap/Ratio';



const MentalModal = (props) =>
{
    const vidRef = useRef(null);
    const videoPlayBtn = useRef(null)
    const [play_iframe,set_play_iframe] = useState(undefined);

    const handleIframeVideo = () => {
        set_play_iframe(true);
        videoPlayBtn.current.style.display = "none";
    }

    const handlePlayVideo = () => {
        vidRef.current.play();
        vidRef.current.controls = true;
        videoPlayBtn.current.style.display = "none";
    }

    const [mental_list,set_mental_list] = useState([]);
    const [mental_video_object,set_mental_video_object] = useState(undefined);
    let list_key = 0;

    async function get_mental_list()
    {
        var base = new Airtable({apiKey: props.apiKey }).base(props.baseKey);
        await base('Mental models').select({
            view: "Grid view",
        }).all().then(records => {
            if(records.length > 0)
            {
                set_mental_list({lists:records});
            }

        }).catch(err => {
        });
    }

    async function get_mental_video()
    {
        var base = new Airtable({apiKey: props.apiKey }).base(props.baseKey);
        await base('Mental models popup video fail').select({
            view: "Grid view",
        }).all().then(records => {
            if(records.length > 0)
            {
                set_mental_video_object(records[0].fields)
            }

        }).catch(err => {
        });
    }

    useEffect(() => {

            let mental_list = get_mental_list();
            get_mental_video();
    }, []);


    useEffect(() => {
    }, [mental_list])

    useEffect(() => {
        if(mental_video_object !== undefined)
        {
        }
    }, [mental_video_object])
    

    return  (
        <>
            <Modal className="mental_modal " show={props.open_mental_popup} onHide={() => {props.set_show_mental_modal(false);}} centered >
                <Modal.Header closeButton className="p-0">
                    {
                        mental_video_object !== undefined
                        ?
                            <div className="modal_video position-relative ">

                               
                                <Ratio aspectRatio="16x9">
                                    {
                                        mental_video_object !== undefined && mental_video_object.Add_Iframe !== undefined && mental_video_object.Add_Iframe === true 
                                        ?
                                            <>
                                                {
                                                    play_iframe === undefined && mental_video_object.Poster !== undefined && mental_video_object.Poster[0] !== undefined
                                                    ?
                                                        <img src={mental_video_object.Poster[0].url}></img>
                                                    :
                                                        null
                                                }
                                                {
                                                    play_iframe !== undefined && play_iframe === true
                                                    ?
                                                    <iframe src={mental_video_object.Iframe}
                                                        allow='autoplay; encrypted-media'
                                                        title='video'
                                                    />
                                                    : null
                                                }
                                                {
                                                    play_iframe === undefined && mental_video_object.Poster !== undefined && mental_video_object.Poster[0] !== undefined
                                                    ?
                                                        <button ref={videoPlayBtn} onClick={handleIframeVideo} className="play-btn position-absolute">
                                                            <img src={playbtn} alt="play-btn"/>
                                                        </button>
                                                    : null
                                                }   
                                            </> 
                                            
                                        : 
                                            <>
                                                <video ref={vidRef} controls={false} poster={ mental_video_object.Poster !== undefined && mental_video_object.Poster[0] !== undefined ? mental_video_object.Poster[0].url : null}>
                                                    <source src={mental_video_object.Attachments[0].url} type="video/mp4" />
                                                </video>
                                                {/* <Player poster={herovideo} src={mental_video_object.Add_Iframe !== undefined && mental_video_object.Add_Iframe === true ? mental_video_object.Iframe : mental_video_object.Attachments[0].url} />                     */}
                                            </>                                  
                                    }
                                </Ratio>
                               
                                
                                {
                                    mental_video_object !== undefined && (mental_video_object.Add_Iframe === undefined || mental_video_object.Add_Iframe === false)
                                    ?
                                        <button ref={videoPlayBtn} onClick={handlePlayVideo} className="play-btn position-absolute">
                                            <img src={playbtn} alt="play-btn"/>
                                        </button>
                                    :
                                        null
                                }

                            </div>
                        : null
                    }

                </Modal.Header>
                <Modal.Body className="">
                    <div className="mental_body_head_box text-center">
                       <p className="first-box"> Mental models coming soon</p>
                       <h2 className=" text-white">
                            How menatal models can benefit you?
                        </h2>
                        <p className="last-text">
                            The world's greatest genius thinkers, problem-solvers, scientists, and billionaires alike have one thing in common. <span className="text-primary">They all rely on mental models.</span>
                        </p>
                    </div>
                    <div className="mental_body_listing">
                            <ul className="list-unstyled">
                             {
                                 mental_list.lists !== undefined && mental_list.lists.length > 0
                                ?
                                mental_list.lists.map((list_values) => {
                                        list_key = list_key + 1;
                                        let listText = list_values.fields.Name !== undefined ? list_values.fields.Name : 0;
                                        return<li key={list_key}>{listText}</li>
                             }) :null
                            }
                            </ul>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <div className="mental_footer_box">
                        <div className="d-flex align-items-center info-box">
                            <img src={infoicon} alt="Info Icon" />
                            <p className="mb-0">We are still working on mental models section of the site. Meanwhile, you can sign up for early access or become yourfeed.ai <b>"founding member".</b> </p>
                        </div>
                        <div className="mental_footer_btns_wrap">
                            <Link to="/your-future-account" className="btn form_btn">
                                Get early access
                            </Link>
                            <Link to="/pricing" className="btn form_btn">
                                Become a founding member
                            </Link>
                        </div>
                        <div className="payment-cards text-center">
                            <img src={paymentcards} alt="Payment Cards" />
                            <p>because your support is so valuable</p>
                        </div>
                    </div>

                </Modal.Footer>
            </Modal>
        </>
    );
}

export default MentalModal;