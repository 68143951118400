import React from "react";
import Slider from "react-slick";
import { NavLink } from "react-router-dom";
import BtnIcon from "../assets/img/angel-right.png";

const CTASlider = (props) => {

    let sliderImagesKey = 0;
    let slider_single_key = 0;

    var settings = {
        infinite: true,
        slidesToShow: 9,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 100,
        cssEase: 'linear',
        speed: 12000,
        arrows: false,
        responsive: [
            {
                breakpoint: 1800,
                settings: {
                    slidesToShow: 8,
                }
            },
            {
                breakpoint: 1600,
                settings: {
                    slidesToShow: 7,
                }
            },
            {
                breakpoint: 1200,
                settings: {
                    slidesToShow: 6,
                }
            },
            {
                breakpoint: 992,
                settings: {
                    slidesToShow: 5,
                }
            },
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 4,
                }
            },
            {
                breakpoint: 425,
                settings: {
                    slidesToShow: 2,
                }
            }
        ]

    };
    var settingsTwo = {
        rtl: true,
        infinite: true,
        slidesToShow: 9,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 100,
        cssEase: 'linear',
        speed: 12000,
        arrows: false,
        responsive: [
            {
                breakpoint: 1800,
                settings: {
                    slidesToShow: 8,
                }
            },
            {
                breakpoint: 1600,
                settings: {
                    slidesToShow: 7,
                }
            },
            {
                breakpoint: 1200,
                settings: {
                    slidesToShow: 6,
                }
            },
            {
                breakpoint: 992,
                settings: {
                    slidesToShow: 5,
                }
            },
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 4,
                }
            },
            {
                breakpoint: 425,
                settings: {
                    slidesToShow: 2,
                }
            }
        ]
    }
    return(
        <>
        {
            props.sliderImages !== undefined && props.sliderImages.length > 0
            ?
            <div className="cta_wrapper">
                <div className="cta_wrap banner_wrap">
                    <div className="bg_slider_wrap">
                            {
                             props.sliderImages.map((banner_slider_images) => {
                                sliderImagesKey = sliderImagesKey + 1;
                                let new_banner_images = [];
                                let new_banner_single_images = [];
                                let is_bg_slider_rtl = sliderImagesKey % 2 == 0 ? 'bg_slider_rtl' : '';
                                let slider_settings = sliderImagesKey % 2 == 0 ? settingsTwo : settings;
                                let minimum_images = 10;

                                if(banner_slider_images.length < minimum_images)
                                {
                                    let total_loops = Math.ceil(minimum_images / banner_slider_images.length)
                                    for(let k = 1;k<=total_loops;k++)
                                    {
                                        new_banner_images = [...new_banner_images,banner_slider_images];
                                    }
                                    if(new_banner_images.length > 0)
                                    {
                                        new_banner_images.forEach((new_banner_val) => {
                                            if(new_banner_val.length > 0)
                                            {
                                                new_banner_val.forEach((inner_single_img) => {
                                                    new_banner_single_images.push(inner_single_img);
                                                })
                                            }
                                        })
                                    }
                                }
                                return <Slider {...slider_settings} className={`slider${sliderImagesKey} bg_slider_row bg_slider ${is_bg_slider_rtl}`} key={sliderImagesKey}>

                                           {
                                               new_banner_single_images.map((final_single_img) => {

                                                    slider_single_key = slider_single_key + 1;
                                                    let slide_img = final_single_img.thumbnails !== undefined && final_single_img.thumbnails.large !== undefined ? final_single_img.thumbnails.large.url : final_single_img.url;
                                                    let slide_alt = final_single_img.thumbnails !== undefined && final_single_img.thumbnails.large !== undefined ? final_single_img.thumbnails.large.filename : final_single_img.filename;

                                                    return  final_single_img.url !== undefined ?
                                                            <div className="slide" key={slider_single_key}>
                                                                <img src={slide_img} alt={slide_alt} />
                                                            </div> : "";
                                               })
                                           }



                                        </Slider>
                                })
                            }


                    </div>
                    <div className="container">
                        <div className="cta_content">
                            {
                                props.cta_content !== undefined && props.cta_content.TitleTwo !== undefined && props.cta_content.TitleTwo != ''
                                ?
                                    <div className="cta_tagline"><span>{props.cta_content.TitleTwo}</span></div>
                                : null
                            }
                            <div className="text-center">
                                {
                                    props.cta_content !== undefined && props.cta_content.Title !== undefined && props.cta_content.Title != ''
                                    ?
                                        <h2 className="cta_title mx-auto">{props.cta_content.Title}</h2>
                                    : null
                                }

                                {
                                    props.cta_content !== undefined && props.cta_content.Description !== undefined && props.cta_content.Description != ''
                                    ?
                                        <p className="fs-lg cta_text text-white" dangerouslySetInnerHTML={{__html: props.cta_content.Description}}></p>
                                    : null
                                }
                                {
                                    props.cta_content !== undefined && props.cta_content.ButtonText !== undefined && props.cta_content.ButtonText != ''
                                    ?
                                        <NavLink className="btn btn-danger btn-img" exact to={props.cta_content.ButtonURL}><img src={BtnIcon} alt="angle-right" />{props.cta_content.ButtonText}</NavLink>
                                    : null
                                }
                            </div>
                        </div>
                    </div>

                </div>
            </div>
            : null
        }
        </>
    )
}

export default CTASlider;