import React from "react";

const SVG = ({
  style = {},
  fill = "none",
  stroke="#fff",
  width = "16",
  height = "16",
  className = "",
  viewBox = "0 0 16 16"
}) => (
  <svg
    width={width}
    style={style}
    height={height}
    viewBox={viewBox}
    xmlns="http://www.w3.org/2000/svg"
    className={`svg-icon ${className || ""}`}
    xmlnsXlink="http://www.w3.org/1999/xlink"
  >
    <path fill={fill} stroke={stroke} d="M5.44922 8.9502L9.24922 11.0502" strokeWidth="2" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
    <path fill={fill} stroke={stroke} d="M10.5508 4.2998L6.80078 6.3498" strokeWidth="2" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
    <path fill={fill} stroke={stroke} d="M12.1508 5.2498C13.1725 5.2498 14.0008 4.42153 14.0008 3.3998C14.0008 2.37808 13.1725 1.5498 12.1508 1.5498C11.1291 1.5498 10.3008 2.37808 10.3008 3.3998C10.3008 4.42153 11.1291 5.2498 12.1508 5.2498Z" strokeWidth="2" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
    <path fill={fill} stroke={stroke} d="M3.85 9.85039C4.87173 9.85039 5.7 9.02212 5.7 8.00039C5.7 6.97866 4.87173 6.15039 3.85 6.15039C2.82827 6.15039 2 6.97866 2 8.00039C2 9.02212 2.82827 9.85039 3.85 9.85039Z" strokeWidth="2" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
    <path fill={fill} stroke={stroke} d="M12.1508 14.45C13.1725 14.45 14.0008 13.6217 14.0008 12.6C14.0008 11.5783 13.1725 10.75 12.1508 10.75C11.1291 10.75 10.3008 11.5783 10.3008 12.6C10.3008 13.6217 11.1291 14.45 12.1508 14.45Z" strokeWidth="2" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
  </svg>
);

export default SVG;
