import React,{useEffect,useState,useRef} from "react";
import { Link } from "react-router-dom";
import { Tab, Nav, ListGroup, Button, Toast } from "react-bootstrap";
import HomeIcon from "./assets/img/home-icon.png";
import Dots from "./assets/img/dots.png";
// import FeedOne from "./assets/img/feed-1.png";
// import FeedTwo from "./assets/img/feed-2.png";
// import FeedThree from "./assets/img/feed-3.png";
// import FeedFour from "./assets/img/feed-4.png";
// import FeedFive from "./assets/img/feed-5.png";
// import FeedSix from "./assets/img/feed-6.png";
// import FeedEight from "./assets/img/feed-8.png";
// import FeedNine from "./assets/img/feed-9.png";
import GPayIcon from "./assets/img/g-pay-ic.svg";
import VbyVisaIcon from "./assets/img/verifie-bt-visa-ic.svg";
import VisaIcon from "./assets/img/visa-ic.svg";
import MasterCardIcon from "./assets/img/mastercard-ic.svg";
import ApplePayIcon from "./assets/img/apple-pay-ic.svg";
import Dollersign from "./assets/img/doller-sign.png"
import LinkIcon from "./assets/img/link.png";
import ShareIcon from "./assets/img/share-icon-3.png";
import BlobShape from "./assets/img/blob-shape.svg";
import PageLoader from "./components/PageLoader";
import Airtable from "airtable";
import PeopleShareModal from './components/people-share-modal';
import ShareModal from './components/ShareModal';
import {Helmet} from "react-helmet";
import {
    SharingIcon,
    FundIcon,
    LinkingIcon,
    RightAngleIcon,
    FaceBookIcon,
    TweetIcon,
    RedditIcon,
    LockIcon
} from "./assets/icons";

const Quotes = (props) => {
    const [showA, setShowA] = useState(false);
    const toggleShowA = () => setShowA(!showA);
    const [copy_link_text, set_copy_link_text] = useState('Copy Link');


    useEffect(() => {
        let seo_default_Data = props.get_seo_data_of_page('Default');
        let seo_page_Data = props.get_seo_data_of_page('Quote');
        set_default_page_seo_data(seo_default_Data);
        set_page_seo_title(seo_page_Data);
        set_price('');
    }, []);

    // console.log('quote_props',props);

    const [page_loaded,set_page_loaded] = useState(false);
    const [quote_id,set_quote_id] = useState(false);
    const [quote_data,set_quote_data] = useState([]);
    const [author_data,set_author_data] = useState([]);
    const [author_photo_extension,set_author_photo_extension] = useState('');
    const [author_photo_url,set_author_photo_url] = useState('');
    const [error,set_error] = useState('');
    const [topics,set_topics] = useState([]);
    const [all_quotes,set_all_quotes] = useState([]);
    const [related_topics_quotes,set_related_topics_quotes] = useState([]);
    const [allPersons,setAllPersons] = useState([]);
    const [top_two_related_quotes,set_top_two_related_quotes] = useState([]);
    const [last_all_related_quotes,set_last_all_related_quotes] = useState([]);
    const quotes_per_page = 10;
    const [current_page,set_current_page] = useState(0);
    const [total_pages,set_total_pages] = useState(0);
    const [loading,set_loading] = useState(false);
    const [open_people_popup,set_open_people_popup] = useState(false);
    const [people_popup_data,set_people_popup_data] = useState({username:'',user_profile_link:'',slug:'',profile_page:true});
    const [show_share_modal,set_show_share_modal] = useState(false);
    const [copy_tag_link_text,set_copy_tag_link_text] = useState('Copy Link');
    const set_open_people_popup_delay = 100;
    const [page_seo_data,set_page_seo_title] = useState({title:'',desc:''});
    const [default_page_seo_data,set_default_page_seo_data] = useState({title:'YourFeed',desc:'YourFeed'});
    const [jdi_class,set_jdi_class] = useState('just_do_it_block');
    const [fund_class,set_fund_class] = useState('fund_topic_block text-center');
    const [price,set_price] = useState('');
    const [amount1_checked,set_amount1_checked] = useState("form-check-input");
    const [amount2_checked,set_amount2_checked] = useState("form-check-input");
    const [amount3_checked,set_amount3_checked] = useState("form-check-input");

    let quote_key = 0;

    useEffect(() => {
        if(price > 0)
        {
            payBtn.current.style.display = 'block';
            priceInput.current.value = price;
        }
        else
        {
            payBtn.current.style.display = 'none';
            priceInput.current.value = '';
        }
    },[price])

    // STEP 1 : On props we are getting the quote id and set the quote id.
    useEffect(() => {
        let quote_id = props.match.params.id;
        if(quote_id == '' || quote_id == undefined && quote_id == null || quote_id == '')
        {
            set_error('Quote Id is not found.');
        }
        else
        {
            set_error('');
            set_quote_id(quote_id);
        }
    },[props]);


    // STEP2 : And sending the request for quote data.
    useEffect(() => {
        // console.log('quote_id',quote_id);
        if(quote_id != '')
        {
            get_quote_data();
        }
    }, [quote_id]);

    // STEP3 : And sending the request for quote author data.
    useEffect(() => {
        // console.log('author_data',author_data);
        if( author_data.photo !== undefined && author_data.photo.length > 0 )
        {
            let author_image = author_data !== undefined && author_data.photo !== undefined && author_data.photo[0] !== undefined && author_data.photo[0].thumbnails !== undefined ? author_data.photo[0].thumbnails.large.url : author_data !== undefined && author_data.photo !== undefined && author_data.photo[0] !== undefined ? author_data.photo[0].url : '';
            set_author_photo_url(author_image);

            let author_image_extension_single = author_data !== undefined && author_data.photo !== undefined && author_data.photo[0] !== undefined && author_data.photo[0].thumbnails !== undefined ? author_data.photo[0].type.split('/').reverse()[0] : author_data !== undefined && author_data.photo !== undefined && author_data.photo[0] !== undefined ? author_data.photo[0].type.split('/').reverse()[0] : '';
            set_author_photo_extension(author_image_extension_single);
        }
    }, [author_data]);

    // STEP4 : And sending the request for quote author data.
    useEffect(() => {
        // console.log('quote_data',quote_data);
        if( quote_data.author != undefined && quote_data.author.length>0 )
        {
            get_author_data(quote_data.author[0]);
        }
    }, [quote_data]);

    // This function is used for get quote data
    async function get_quote_data()
    {
        var base = new Airtable({apiKey: props.apiKey }).base(props.baseKey);
        await base('quotes').find(quote_id, function(err, record) {
            if (err) {
                set_error(err.message);
                set_page_loaded(true);
                window.scroll({top: 0, left: 0, behavior: 'smooth' })
            }
            else
            {
                set_page_loaded(true);
                window.scroll({top: 0, left: 0, behavior: 'smooth' })
                set_error('');
                set_quote_data(record.fields);
            }
        });
    }

    // This function is used for get quote author's data
    async function get_author_data(id='')
    {
        if(id !== undefined && quote_data != '')
        {
            var base = new Airtable({apiKey: props.apiKey }).base(props.baseKey);
            await base('persons').find(id, function(err, record) {
                if (err) {
                    console.log('error',err);
                    set_error(err.message);
                }
                else
                {
                    set_error('');
                    // console.log('author_Data',record);
                    set_author_data(record.fields);
                }
            });
        }
    }

    function copy(text) {
        var input = document.createElement('input');
        input.setAttribute('value', text);
        document.body.appendChild(input);
        input.select();
        var result = document.execCommand('copy');
        document.body.removeChild(input);
        return result;
    }

    function changePrice(price=0)
    {
        set_price(price);
    }

    const inputChange = (event) => {
        // 👇 Get input value from "event"
        const re = /^[0-9\b]+$/;

        // if value is not blank, then test the regex



        if (event.target.value !== '' && re.test(event.target.value) === true ) {
            set_price( !isNaN(event.target.value) && event.target.value > 0 ? event.target.value : '');
            priceInput.current.value = !isNaN(event.target.value) && event.target.value > 0 ? event.target.value : ''
        }
        else if(event.target.value == '')
        {
            set_price('');
            priceInput.current.value = price > 0 ? price : '';
        }
        else
        {
            priceInput.current.value = price > 0 ? price : '';
        }

    };

    const payBtn = useRef(null);
    const priceInput = useRef(null);

    useEffect(() => {
    },[author_photo_extension]);

    return (
        <>
        <Helmet>
            <title>{page_seo_data.title != '' ? page_seo_data.title : default_page_seo_data.title}</title>
            <meta name="description" content={page_seo_data.desc != '' ? page_seo_data.desc : default_page_seo_data.desc} />
        </Helmet>
        <PageLoader page_loaded={page_loaded} />
        <div className={error == '' ? 'quotes_page' : 'quotes_page page_error'}>
            {
                error == ''
                ?
                    <>
                        {/* {related_topics_quotes[0] !== undefined ? related_topics_quotes[0].length : 0} */}
                        <div className="hero_wrap">
                            <div className="container">
                                <div className="breadcrumbs d-sm-block d-none">
                                    <ul className="list-unstyled">
                                        <li><Link to="/"><img src={HomeIcon} alt="home" /></Link></li>
                                        <li><Link to="/discover">discover</Link></li>
                                        <li>People</li>
                                        { author_data.name !== undefined && author_data.name !== '' ? <li><Link to={`/discover/people/${author_data.name.toLowerCase().split(' ').join('-')}`}>{author_data.name}</Link></li> : null }
                                        { quote_data.text !== undefined && quote_data.text !== '' ? <li className="text-truncate w-14">{quote_data.text}</li> : null }
                                    </ul>
                                </div>
                                <div className="d-none d-md-block" data-topics={topics.join(',')}>
                                    <div className="card_block">
                                        <div className="card_block_img flex-shrink-0">
                                            {
                                                author_photo_extension == 'mp4'
                                                ?
                                                    <>
                                                        {/* <div className="blob">
                                                            <canvas className="not_rendered"></canvas>
                                                        </div> */}
                                                        <video width="130px" height="100%" className=""  src={author_photo_url} autoPlay muted loop controls={false}></video>
                                                    </>

                                                :
                                                    <img src={author_photo_url} alt={author_data.name} />
                                            }
                                            {/* <img src={FeedEight} alt="charlie-munger" /> */}
                                        </div>
                                        <div className="card_block_right">
                                            <div className="card_block_author">
                                                <div>
                                                    {
                                                        author_data.name !== undefined
                                                        ?
                                                            <Link to={`/discover/people/${author_data.name.toLowerCase().split(' ').join('-')}`}> { author_data.name !== undefined && author_data.name !== '' ? <h4>{author_data.name}</h4> : null } </Link>
                                                        : null
                                                    }
                                                    { author_data.role !== undefined && author_data.role !== '' ?<div className="fs-sm">{author_data.role}</div> : null }
                                                </div>
                                                {
                                                    author_data.name !== undefined
                                                    ?
                                                    <span  onClick={() => {
                                                        setTimeout(function(){
                                                            // console.log('author_data',author_data);
                                                            set_open_people_popup(true);
                                                            set_people_popup_data({username:author_data.name,user_profile_link:'/discover/people/profile/'+quote_id,slug:author_data.name.toLowerCase().split(' ').join('-'),profile_page:false,quote_id:quote_id});
                                                        },set_open_people_popup_delay)

                                                    }}><img src={Dots} alt="dots" /></span>
                                                    : null
                                                }

                                            </div>
                                            {
                                                quote_data.text !== undefined && quote_data.text !== ''
                                                ?
                                                    <div className="card_block_content">
                                                        {quote_data.text}
                                                    </div>
                                                : null
                                            }
                                        </div>
                                    </div>
                                </div>
                                <div className="d-md-none" data-topics={topics.join(',')}>
                                    <div className="card_block">
                                        <div className="card_block_top">
                                            <div className="card_block_img flex-shrink-0">
                                                {
                                                    author_photo_extension == 'mp4'
                                                    ?
                                                        <>
                                                            {/* <div className="blob">
                                                                <canvas className="not_rendered"></canvas>
                                                            </div> */}
                                                            <video width="55px" height="100%" className="" src={author_photo_url} autoPlay muted loop controls={false}></video>
                                                        </>

                                                    :
                                                        <img src={author_photo_url} alt={author_data.name} />
                                                }
                                            </div>
                                            <div className="card_block_author">
                                                <div>
                                                    {
                                                        author_data.name !== undefined
                                                        ?
                                                            <Link to={`/discover/people/${author_data.name.toLowerCase().split(' ').join('-')}`}> { author_data.name !== undefined && author_data.name !== '' ? <h4>{author_data.name}</h4> : null } </Link>
                                                        : null
                                                    }
                                                    { author_data.role !== undefined && author_data.role !== '' ?<div className="fs-sm">{author_data.role}</div> : null }
                                                </div>
                                                <span onClick={() => {
                                                        setTimeout(function(){
                                                            // console.log('author_data',author_data);
                                                            set_open_people_popup(true);
                                                            set_people_popup_data({username:author_data.name,user_profile_link:'/discover/people/profile/'+quote_id,slug:author_data.name.toLowerCase().split(' ').join('-'),profile_page:false,quote_id:quote_id});
                                                        },set_open_people_popup_delay);

                                                    }}><img src={Dots} alt="dots" /></span>
                                            </div>
                                        </div>
                                        {
                                            quote_data.text !== undefined && quote_data.text !== ''
                                            ?
                                                <div className="card_block_content">
                                                    {quote_data.text}
                                                </div>
                                            : null
                                        }

                                    </div>
                                </div>
                                <div className="hero_text_wrap d-flex justify-content-end">
                                    <Button onClick={toggleShowA} className="quotes_share"><img src={ShareIcon} alt="share" /> Share</Button>
                                    <Toast show={showA} onClose={toggleShowA}>
                                        <Toast.Header>Click the links. Share the stuff.</Toast.Header>
                                        <Toast.Body>
                                                <ul className="list-unstyled social_share">
                                                    <li className="twitter">

                                                        <a target="_blank" rel='noopener noreferrer' href={"https://twitter.com/intent/tweet?text="+window.location.href}>
                                                            <div className="share_icon"><TweetIcon /></div> Twitter
                                                        </a>
                                                    </li>
                                                    <li className="fb">
                                                        <a to="void(0);" onClick={() => {
                                                            window.open('https://www.facebook.com/sharer/sharer.php?u=' + window.location.href + '&title=Your Feed', 'fbShareWindow', 'height=450, width=550, top=' + (window.innerHeight / 2 - 275) + ', left=' + (window.innerWidth / 2 - 225) + ', toolbar=0, location=0, menubar=0, directories=0, scrollbars=0');
                                                        }}>
                                                            <div className="share_icon"><FaceBookIcon /></div> Facebook
                                                        </a>
                                                    </li>
                                                    <li className="reddit">
                                                        <a target="_blank" rel='noopener noreferrer' href={'https://www.reddit.com/submit?url='+window.location.href+'/&title='+quote_data.text}>
                                                            <div className="share_icon"><RedditIcon /></div> Reddit
                                                        </a>
                                                    </li>
                                                    <li className="link d-md-none">
                                                        <a href="void(0);" onClick={(e) => {
                                                                e.preventDefault();
                                                                let website_link_text = document.getElementById('website_link').innerText;
                                                                copy(website_link_text);
                                                                set_copy_link_text('Link copied');
                                                            }}>
                                                            <div className="share_icon"><img src={LinkIcon} alt="LinkIcon" /></div> {copy_link_text} <span className="d-none" id="website_link">{window.location.href}</span>
                                                        </a>
                                                    </li>
                                                    <li className="link d-md-block d-none">
                                                        <a to="void(0);">
                                                            <div className="share_icon">
                                                                <img src={LinkIcon} alt="LinkIcon" />
                                                            </div>
                                                            <button type="button" className="btn copy_btn" onClick={(e) => {
                                                                e.preventDefault();
                                                                let website_link_text = document.getElementById('website_link').innerHTML;
                                                                website_link_text = window.location.href;
                                                                copy(website_link_text);
                                                                set_copy_link_text('Link copied');
                                                            }}>{copy_link_text} </button>
                                                        </a>
                                                    </li>
                                                </ul>
                                            <Link to="/pricing" className="fund_btn"><FundIcon fill="#FEDF30" />Fund Yourfeed.ai</Link>
                                        </Toast.Body>
                                    </Toast>
                                </div>
                            </div>
                        </div>



                        <div className="copy_wrap">
                            <div className="container">
                                <div className="copy_wrap_tab_block">
                                    <Tab.Container id="topics-tabs" defaultActiveKey="share-topic">
                                        <Nav className="topics_tab_links justify-content-center">
                                            <Nav.Item>
                                                <Nav.Link eventKey="share-topic"><SharingIcon /> Share</Nav.Link>
                                            </Nav.Item>
                                            <Nav.Item>
                                                <Nav.Link eventKey="fund-topic"><FundIcon /> Fund Yourfeed.ai</Nav.Link>
                                            </Nav.Item>
                                        </Nav>
                                        <Tab.Content>
                                                <Tab.Pane eventKey="share-topic">
                                                    <div className="link_wrap">
                                                        <p><LinkingIcon /> <span id="copy_tag_link">{window.location.origin}</span></p>
                                                        <button className="btn copy_btn" onClick={() => {
                                                            copy(window.location.origin);
                                                            set_copy_tag_link_text('Copied');
                                                        }}>{copy_tag_link_text}</button>
                                                    </div>
                                                    <div className="copy_text">
                                                        <img src={BlobShape} alt="BlobShape" className="blob_shape" />
                                                        Share yourfeed.ai on <strong>Twitter</strong>, <strong>Snapchat</strong>, <strong>Email</strong> etc.
                                                    </div>
                                                </Tab.Pane>
                                                <Tab.Pane eventKey="fund-topic">
                                                    <div className="fund_jdi_tab position-relative">
                                                        <div className={fund_class}>
                                                            <h2>Will you give today?</h2>
                                                            <p>We know most people will ignore this message, but if everyone gave a little, we could build cool software as a community.</p>
                                                            <div className="protip_txt"><strong>Protip:</strong> You also get a <span className="text-secondary">"founding member badge".</span></div>
                                                            <div className="fund_cta">
                                                                <button type="button" className="btn btn-info"
                                                                onClick={() => {
                                                                    set_jdi_class('just_do_it_block active');
                                                                    set_fund_class('fund_topic_block text-center inactive');
                                                                }}><FundIcon /> Quick donate</button>
                                                                <Link to="/pricing" className="btn btn-dark"><RightAngleIcon /> Tell me more</Link>
                                                            </div>
                                                            <ListGroup as="ul" horizontal className="fund_payment_type">
                                                                <ListGroup.Item as="li" className="bg-transparent"><img src={GPayIcon} alt="GPayIcon" /></ListGroup.Item>
                                                                <ListGroup.Item as="li" className="bg-transparent"><img src={VbyVisaIcon} alt="VbyVisaIcon" /></ListGroup.Item>
                                                                <ListGroup.Item as="li" className="bg-transparent"><img src={VisaIcon} alt="VisaIcon" /></ListGroup.Item>
                                                                <ListGroup.Item as="li" className="bg-transparent"><img src={MasterCardIcon} alt="MasterCardIcon" /></ListGroup.Item>
                                                                <ListGroup.Item as="li" className="bg-transparent"><img src={ApplePayIcon} alt="ApplePayIcon" /></ListGroup.Item>
                                                            </ListGroup>

                                                        </div>
                                                        <div className={jdi_class}>
                                                            <h2 className="text-center text-white">Just do it ;-)</h2>
                                                            <p className="text-center text-white">because your help is so valuable</p>
                                                            <form action="" method="post">
                                                                <div className="custom_radio">
                                                                    <div className="ps-0 mb-0 form-check">
                                                                        <input name="amount" type="radio" id="amount" className={amount1_checked} onClick={() => {changePrice(10);set_amount1_checked("form-check-input checked");set_amount2_checked("form-check-input");set_amount3_checked("form-check-input");}} />
                                                                        <label title="" htmlFor="amount" className="form-check-label"><sub>$</sub>10</label>
                                                                    </div>
                                                                </div>
                                                                <div className="custom_radio">
                                                                    <div className="ps-0 mb-0 form-check">
                                                                        <input name="amount" type="radio" id="amount2" className={amount2_checked} onClick={() => {changePrice(15);set_amount1_checked("form-check-input");set_amount2_checked("form-check-input checked");set_amount3_checked("form-check-input");}} />
                                                                        <label title="" htmlFor="amount2" className="form-check-label"><sub>$</sub>15</label>
                                                                    </div>
                                                                </div>
                                                                <div className="custom_radio">
                                                                    <div className="ps-0 mb-0 form-check">
                                                                        <input name="amount" type="radio" id="amount3" className={amount3_checked} onClick={() => {changePrice(20);set_amount1_checked("form-check-input");set_amount2_checked("form-check-input");set_amount3_checked("form-check-input checked");}} />
                                                                        <label title="" htmlFor="amount3" className="form-check-label"><sub>$</sub>20</label>
                                                                    </div>
                                                                </div>
                                                                <div className="custom_input_group">
                                                                    <span className="d-block">Set your own amount</span>
                                                                    <div className="custom_input">
                                                                        <div className="custom_input_icon"><img src={Dollersign} alt="Doller sign" /></div>
                                                                        <input ref={priceInput} onChange={inputChange} placeholder="maybe $44?" defaultValue={price} type="text" className="form-control"></input>
                                                                    </div>
                                                                </div>
                                                                <button ref={payBtn} type="submit" className="btn form_btn"><LockIcon />Secure payment</button>
                                                            </form>
                                                            <ListGroup as="ul" horizontal className="fund_payment_type pt-0">
                                                                <ListGroup.Item as="li" className="bg-transparent"><img src={GPayIcon} alt="GPayIcon" /></ListGroup.Item>
                                                                <ListGroup.Item as="li" className="bg-transparent"><img src={VbyVisaIcon} alt="VbyVisaIcon" /></ListGroup.Item>
                                                                <ListGroup.Item as="li" className="bg-transparent"><img src={VisaIcon} alt="VisaIcon" /></ListGroup.Item>
                                                                <ListGroup.Item as="li" className="bg-transparent"><img src={MasterCardIcon} alt="MasterCardIcon" /></ListGroup.Item>
                                                                <ListGroup.Item as="li" className="bg-transparent"><img src={ApplePayIcon} alt="ApplePayIcon" /></ListGroup.Item>
                                                            </ListGroup>
                                                            <div className="share_close mt-4">
                                                                <button onClick={() => {
                                                                    set_jdi_class('just_do_it_block');
                                                                    set_fund_class('fund_topic_block text-center active');
                                                                }} className="btn">Back</button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </Tab.Pane>
                                            </Tab.Content>
                                    </Tab.Container>
                                </div>
                            </div>
                        </div>

                    </>
                :
                error
            }
        </div>
        <PeopleShareModal open_people_popup={open_people_popup} set_open_people_popup={set_open_people_popup} people_popup_data={people_popup_data} />
        <ShareModal open_share_popup={show_share_modal} set_show_share_modal={set_show_share_modal} share_links={
            {
                facebook : 'https://www.facebook.com/sharer/sharer.php?u=' + window.location.href + '&title=Your Feed, fbShareWindow,height=450, width=550, top=' + (window.innerHeight / 2 - 275) + ', left=' + (window.innerWidth / 2 - 225) + ', toolbar=0, location=0, menubar=0, directories=0, scrollbars=0',
                twitter : `https://twitter.com/intent/tweet?text=${window.location.href}`,
                whatsapp : `https://web.whatsapp.com/send?text=${window.location.href}`,
                email : `https://mail.google.com/mail/?view=cm&fs=1&tf=1&to=&su=&body=${window.location.href}&ui=2&tf=1&pli=1`,
            }
        } />
        </>
    )
}

export default Quotes;