import React, {useState,useEffect,useRef} from "react";
import { Button } from "react-bootstrap";
// import PersonOne from "./assets/img/person-1.png";
// import PersonTwo from "./assets/img/person-2.png";
// import PersonThree from "./assets/img/person-3.png";
// import PersonFour from "./assets/img/person-4.png";
// import PersonFive from "./assets/img/person-5.png";
// import PersonSix from "./assets/img/person-6.png";
// import Fire from "./assets/img/fire.png";
// import Bitcoin from "./assets/img/bitcoin.png";
import { Link } from "react-router-dom";
import Airtable from "airtable";
import PageLoader from "./components/PageLoader";
import {Helmet} from "react-helmet";
import imgPlaceholder from "./assets/img/placeholder.png";
import InviteModal from './components/InviteModal';

const Discover = (props) => {

    useEffect(() => {
        let seo_default_Data = props.get_seo_data_of_page('Default');
        let seo_page_Data = props.get_seo_data_of_page('Discover');
        set_default_page_seo_data(seo_default_Data);
        set_page_seo_title(seo_page_Data);
    }, []);

    const [open_invite_popup,set_open_invite_popup] = useState(false);
    const set_open_invite_popup_delay = 100;
    const [personTags,setPersonTags] = useState([]);
    const [popularPersons,setPopularPersons] = useState([]);
    const [allPersons,setAllPersons] = useState([]);
    const [allTags,setAllTags] = useState([]);
    const [page_loaded,set_page_loaded] = useState(false);
    const [page_seo_data,set_page_seo_title] = useState({title:'',desc:''});
    const [default_page_seo_data,set_default_page_seo_data] = useState({title:'YourFeed',desc:'YourFeed'});
    let persons_key = 0;
    let tags_key = 0;
    const tags_container = useRef(null);
    const topic_block_wrap = useRef(null);
    const topic_wrap = useRef(null);
    const [window_width,set_window_width] = useState(0);

    useEffect(() => {
        if(
            personTags !== undefined && personTags.length > 0
            // && popularPersons !== undefined && popularPersons.length > 0
            // && allPersons !== undefined && allPersons.length > 0
            && allTags !== undefined && allTags.length > 0
        )
        {
            set_page_loaded(true);
        }
    }, [personTags, popularPersons, allPersons, allTags])

    useEffect(() => {
        if(allPersons[0] !== undefined)
        {
        }
    }, [allPersons])

    useEffect(() => {
    }, [personTags])

    useEffect(() => {
    }, [allTags])

    async function get_person_tags()
    {
        var base = new Airtable({apiKey: props.apiKey }).base(props.baseKey);
        let all_slider_images = [];
        await base('personTags').select({
            // Selecting the first 3 records in Grid view:
            view: "Grid view"
        }).all().then(records => {
            setPersonTags(records);

        }).catch(err => {
            // console.error(err);
        });
    }

    async function get_tags()
    {
        var base = new Airtable({apiKey: props.apiKey}).base(props.baseKey);
        let all_slider_images = [];
        await base('quoteTags').select({
            // Selecting the first 3 records in Grid view:
            view: "Grid view",
            filterByFormula:`({golaunch} = '1')`
        }).all().then(records => {
            if(records.length > 0)
            {
                let all_chunk_tags = chunks(records,4);
                setAllTags(all_chunk_tags);
            }

        }).catch(err => {
            // console.error(err);
        });
    }

    async function get_popular_persons()
    {
        var base = new Airtable({apiKey: props.apiKey}).base(props.baseKey);
        let all_slider_images = [];
        await base('persons').select({
            // Selecting the first 3 records in Grid view:
            view: "Grid view"
        }).all().then(records => {
            if(records.length > 0)
            {

                records.forEach((val,key) => {
                    let all_persons_with_key = allPersons;
                    let all_persons_fields = val.fields;
                    all_persons_with_key[val['id']] = all_persons_fields;
                    let isPopular = all_persons_fields.isPopular !== undefined && all_persons_fields.isPopular === true ? all_persons_fields.isPopular : false;
                    if(isPopular === true)
                    {
                        let new_popular_persopns = popularPersons;
                        new_popular_persopns.push(all_persons_fields);
                        setPopularPersons([new_popular_persopns])
                    }
                    setAllPersons([all_persons_with_key]);
                });

                // setAllPersons(records);
            }
        }).catch(err => {
            // console.error(err);
        });
    }

    useEffect(() => {
        get_popular_persons();
        get_person_tags();
        get_tags();
        set_window_width(window.innerWidth);
    }, [])

    useEffect(() => {
        if(allTags !== undefined && allTags.length > 0)
        {
            const container_width = tags_container.current.clientWidth;
            const topic_width = topic_block_wrap.current.clientWidth;
            const margin = (window_width - container_width) / 2;
            const padding = (container_width - topic_width) / 2;

            const total_width = topic_width + padding + margin;
            if(total_width !== undefined)
            {
                topic_wrap.current.style.width = total_width+'px';
            }
        }
    },[tags_container,allTags,topic_block_wrap,window_width]);

    useEffect(() => {
    }, [popularPersons])

    function chunks(arr, size = 2) {
        return arr.map((x, i) => i % size == 0 && arr.slice(i, i + size)).filter(x => x)
    }

    function resizeHandler()
    {
        set_window_width(window.innerWidth);
    }

    useEffect(() => {
        window.addEventListener('resize',resizeHandler);
        return () => {
            window.removeEventListener('resize',resizeHandler);
        }
    },[]);

    return (
        <>
        <Helmet>
            <title>{page_seo_data.title != '' ? page_seo_data.title : default_page_seo_data.title}</title>
            <meta name="description" content={page_seo_data.desc != '' ? page_seo_data.desc : default_page_seo_data.desc} />
        </Helmet>
        <PageLoader page_loaded={page_loaded} />
        {/* personTags : {personTags !== undefined ? personTags.length : 0} */}
        {/* allPersons : {allPersons[0] !== undefined ? allPersons[0].length : 0} */}
        {/* allTags : {allTags.length} */}
        <div className="discover_page">

            {

                popularPersons[0] !== undefined && popularPersons[0].length > 0  && allTags !== undefined && allTags.length > 0 && personTags !== undefined && personTags.length > 0 && allPersons !== undefined && allPersons.length>0
                ?
                    <div className="person_wrap">
                        <div className="container">
                            <div className="title_wrap d-flex justify-content-between align-items-center">
                                <h3>Popular</h3>
                                <Button variant="warning" className="invite_btn"  onClick={() => {
                                    setTimeout(function(){
                                        set_open_invite_popup(true);
                                        set_open_invite_popup({});
                                    },set_open_invite_popup_delay);

                                }}>Invite</Button>
                                <Link to="/" className="d-none">View all</Link>
                            </div>
                            <div className="person_block_wrap">
                                {
                                    popularPersons[0].map((popularPerson_val) => {
                                        persons_key = persons_key + 1;
                                        let popular_person_name = popularPerson_val.name;
                                        let popular_person_photo = popularPerson_val !== undefined && popularPerson_val.photo !== undefined && popularPerson_val.photo[0] !== undefined && popularPerson_val.photo[0].thumbnails !== undefined ? popularPerson_val.photo[0].thumbnails.large.url : popularPerson_val.photo[0]['url'];
                                        let popular_person_photo_type = popularPerson_val.photo[0]['type'];
                                        let popular_person_photo_extension = popular_person_photo_type.split('/').reverse()[0];

                                        let popular_person_role = popularPerson_val.role;
                                        let popular_person_slug = popular_person_name.toLowerCase().split(' ').join('-');
                                        

                                        return <Link key={`popular_${persons_key}`} to={`/discover/people/${popular_person_slug}`} className="person_block">
                                                    <div className="person_block_img">
                                                        {
                                                            popular_person_photo_extension == 'mp4'
                                                            ?
                                                                <>
                                                                    <video width="100%" height="100%" className="" src={popular_person_photo} autoPlay muted loop controls={false}></video>
                                                                    {/* <div className="blob">
                                                                        <canvas className="not_rendered"></canvas>
                                                                    </div> */}
                                                                </>

                                                            :
                                                                <img src={popular_person_photo} alt="Warren Buffett" />
                                                        }

                                                    </div>
                                                    <div className="person_block_content">
                                                        <h6>{popular_person_name}</h6>
                                                        <p>{popular_person_role}</p>
                                                    </div>
                                                </Link>
                                    })
                                }

                            </div>
                        </div>
                    </div>

                : null
            }

            {
                allTags !== undefined && allTags.length > 0 ?
                    <div className="topic_wrap" >
                        <div className="container" ref={tags_container}>
                            <div className="title_wrap d-flex justify-content-between align-items-center" ref={topic_block_wrap}>
                                <h4>Popular topics</h4>
                                <Link to="/" className="d-none">View all</Link>
                            </div>
                            <div className="topic_block_wrap" ref={topic_wrap}>
                                {
                                    allTags.map((allTags_val,allTags_key) => {
                                        persons_key = persons_key + 1;
                                        if(allTags_val.length >0)
                                        {
                                            return <div key={persons_key} className="topic_block_row">
                                                        {
                                                            allTags_val.map((allTags_val_inner) => {
                                                                persons_key = persons_key + 1;
                                                                let tag_name = allTags_val_inner.fields.name;
                                                                let tag_slug = allTags_val_inner.fields.name.toLowerCase().split(' ').join('-');
                                                                let tag_icon  = '';
                                                                if(allTags_val_inner.fields.Icon !== undefined)
                                                                {
                                                                    tag_icon = allTags_val_inner.fields.Icon[0].url
                                                                }
                                                                return <Link key={`tags_${persons_key}`} to={`/discover/topics/${tag_slug}`} className="topic_block">
                                                                            <p><span>#</span>{tag_name}
                                                                            {tag_icon != '' ? <img src={tag_icon} alt="tag_icon" /> : ''}</p>
                                                                        </Link>
                                                            })
                                                        }

                                                    </div>
                                        }
                                    })
                                }

                        </div>
                        </div>
                    </div>
                : null
            }


            {
                personTags !== undefined && personTags.length > 0 && allPersons !== undefined && allPersons.length>0
                ?

                    personTags.map((personTags_val) => {
                        let person_tag_name = personTags_val !== undefined ? personTags_val.fields.name : '';
                        let person_tag_persons = personTags_val.fields.persons;
                        persons_key = persons_key + 1;
                        if(person_tag_persons !== undefined && person_tag_persons.length > 0)
                        {
                            return <div className="person_wrap" key={persons_key}>
                                        <div className="container">
                                            <div className="title_wrap d-flex justify-content-between align-items-center">
                                                <h4>{person_tag_name}</h4>
                                                <Link to="/" className="d-none">View all</Link>
                                            </div>
                                            <div className="person_block_wrap">
                                                {
                                                    person_tag_persons.map((tag_person_id,tag_person_key) => {
                                                        persons_key = persons_key + 1;

                                                        let person_details = allPersons[0][tag_person_id];
                                                        let person_tag_name = person_details !== undefined ? person_details.name : '';
                                                        let person_tag_role = person_details !== undefined ? person_details.role : '';
                                                        let person_tag_image = person_details !== undefined && person_details.photo !== undefined && person_details.photo[0] !== undefined && person_details.photo[0].thumbnails !== undefined ? person_details.photo[0].thumbnails.large.url : imgPlaceholder;
                                                        let popular_person_photo_type = person_details !== undefined && person_details.photo !== undefined ? person_details.photo[0]['type'] : '';
                                                        let person_tag_image_extension = popular_person_photo_type.split('/').reverse()[0];
                                                        // let person_tag_image_extension = person_tag_image.split('.').reverse()[0];

                                                        let person_tag_slug = person_tag_name.toLowerCase().split(' ').join('-');

                                                        return <Link key={`tag_person_key_`+persons_key} to={`/discover/people/${person_tag_slug}`} className="person_block">
                                                                    <div className="person_block_img">
                                                                    {
                                                                        person_tag_image_extension == 'mp4'
                                                                        ?
                                                                            <>
                                                                                <video width="100%" height="100%" className="" src={person_tag_image} autoPlay muted loop controls={false}></video>
                                                                            </>
                                                                        :
                                                                            <img src={person_tag_image} alt={person_tag_name} />
                                                                    }
                                                                    </div>
                                                                    <div className="person_block_content">
                                                                        <h6>{person_tag_name}</h6>
                                                                        <p>{person_tag_role}</p>
                                                                    </div>
                                                                </Link>
                                                    })
                                                }



                                            </div>
                                        </div>
                                    </div>
                        }
                    })


                : null
            }
            <InviteModal open_invite_popup={open_invite_popup} set_open_invite_popup={set_open_invite_popup} invite_popup_data={open_invite_popup} />
        </div>
        </>
    )
}

export default Discover;