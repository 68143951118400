import React, { } from "react";
// import Airtable from "airtable";
import Slider from "react-slick";
import { Link } from "react-router-dom";
import BtnIcon from "../assets/img/angel-right.png";

const BannerSlider = (props) => {

    let sliderImagesKey = 0;
    let slider_single_key = 0;

    var settings = {
        infinite: true,
        slidesToShow: 9,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 100,
        cssEase: 'linear',
        speed: 12000,
        arrows: false,
        responsive: [
            {
                breakpoint: 1800,
                settings: {
                    slidesToShow: 8,
                }
            },
            {
                breakpoint: 1600,
                settings: {
                    slidesToShow: 7,
                }
            },
            {
                breakpoint: 1200,
                settings: {
                    slidesToShow: 6,
                }
            },
            {
                breakpoint: 992,
                settings: {
                    slidesToShow: 5,
                }
            },
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 4,
                }
            },
            {
                breakpoint: 425,
                settings: {
                    slidesToShow: 2,
                }
            }
        ]

    };
    var settingsTwo = {
        rtl: true,
        infinite: true,
        slidesToShow: 9,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 100,
        cssEase: 'linear',
        speed: 12000,
        arrows: false,
        responsive: [
            {
                breakpoint: 1800,
                settings: {
                    slidesToShow: 8,
                }
            },
            {
                breakpoint: 1600,
                settings: {
                    slidesToShow: 7,
                }
            },
            {
                breakpoint: 1200,
                settings: {
                    slidesToShow: 6,
                }
            },
            {
                breakpoint: 992,
                settings: {
                    slidesToShow: 5,
                }
            },
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 4,
                }
            },
            {
                breakpoint: 425,
                settings: {
                    slidesToShow: 2,
                }
            }
        ]
    }
    return (
        <>
            {
                props.sliderImages !== undefined && props.sliderImages.length > 0 ?

                    <div className="banner_wrap">
                        <div className="bg_slider_wrap">
                            {

                                props.sliderImages.map((banner_slider_images) => {
                                    sliderImagesKey = sliderImagesKey + 1;
                                    let new_banner_images = [];
                                    let new_banner_single_images = [];
                                    let is_bg_slider_rtl = sliderImagesKey % 2 == 0 ? 'bg_slider_rtl' : '';
                                    let slider_settings = sliderImagesKey % 2 == 0 ? settingsTwo : settings;
                                    let minimum_images = 10;
                                    if (banner_slider_images.length < minimum_images) {
                                        let total_loops = Math.ceil(minimum_images / banner_slider_images.length)
                                        for (let k = 1; k <= total_loops; k++) {
                                            new_banner_images = [...new_banner_images, banner_slider_images];
                                        }
                                        if (new_banner_images.length > 0) {
                                            new_banner_images.forEach((new_banner_val) => {
                                                if (new_banner_val.length > 0) {
                                                    new_banner_val.forEach((inner_single_img) => {
                                                        new_banner_single_images.push(inner_single_img);
                                                    })
                                                }
                                            })
                                        }
                                    }

                                    return <Slider {...slider_settings} className={`slider${sliderImagesKey} bg_slider_row bg_slider ${is_bg_slider_rtl}`} key={sliderImagesKey}>

                                        {
                                            new_banner_single_images.map((final_single_img) => {

                                                slider_single_key = slider_single_key + 1;
                                                return  final_single_img.url !== undefined ?
                                                    <div className="slide" key={slider_single_key}>
                                                        <img src={final_single_img.url} alt={final_single_img.alt} />
                                                    </div>
                                                : "";
                                            })
                                        }



                                    </Slider>
                                })
                            }
                        </div>
                        <div className="container">
                            <div className="banner_content">
                                {
                                    props.banner_content !== undefined && props.banner_content.Title !== undefined && props.banner_content.Title != ''
                                        ?
                                        <h1 className="hero_title">{props.banner_content.Title}</h1>
                                        : null
                                }

                                {
                                    props.banner_content !== undefined && props.banner_content.Description !== undefined && props.banner_content.Description != ''
                                        ?
                                        <p dangerouslySetInnerHTML={{ __html: props.banner_content.Description }} className="fs-lg hero_text text-white"></p>
                                        : null
                                }

                                {
                                    props.banner_content !== undefined && props.banner_content.ButtonText !== undefined && props.banner_content.ButtonText != ''
                                        ?
                                        <Link to={props.banner_content.ButtonURL} className="btn btn-danger btn-img"><img src={BtnIcon} alt="angle-right" /><span>{props.banner_content.ButtonText}</span></Link>
                                        : null
                                }
                            </div>
                        </div>
                    </div>
                    : null
            }
        </>
    )
}

export default BannerSlider;





